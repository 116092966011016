import { useEffect, useState } from 'react';
const Heading = ({
  textContent = 'TACX',
  fontColor = '#594255',
  textPosition = 'text-center',
  hideText = false,
  headingTypography = {
    type: 'editor',
    family: 'poppins, font-poppins',
    nameFontSize: 'text-xl',
    typographyWeight: 'font-[600]',
    typographyStyle: 'normal',
    lineHeight: '100',
  },
  headingPadding = [0, 0, 0, 0],
  headingMargin = [0, 0, 0, 0],
  globalStyling = {
    fontColor: '#000000',
    linkColor: '#000000',
    fontSize: 'text-xl',
    status: false,
    ignoreFieldArray: [] as string[],
    globalChangesList: [] as string[],
  },
}) => {
  let handleGlobalStyling = () => {
    let data = {
      fontColor: globalStyling.fontColor,
      textFontSize: globalStyling.fontSize,
    };
    if (globalStyling.status) {
      if (globalStyling?.ignoreFieldArray?.length === 0) {
        if (globalStyling?.globalChangesList.includes('textColor')) {
          data.fontColor = globalStyling.fontColor;
        }
      } else {
        if (globalStyling?.ignoreFieldArray?.includes('fontColor')) {
          data.fontColor = fontColor;
        } else {
          data.fontColor = globalStyling.fontColor;
        }
      }
      if (globalStyling?.ignoreFieldArray?.length === 0) {
        if (globalStyling?.globalChangesList.includes('fontSize')) {
          data.textFontSize = globalStyling.fontSize;
        }
      } else {
        if (
          globalStyling?.ignoreFieldArray?.includes(
            'headingTypography.nameFontSize',
          )
        ) {
          data.textFontSize = headingTypography.nameFontSize;
        } else {
          data.textFontSize = globalStyling.fontSize;
        }
      }
    } else {
      if (globalStyling?.ignoreFieldArray?.includes('fontColor')) {
        data.fontColor = fontColor;
      }
      if (
        globalStyling?.ignoreFieldArray?.includes(
          'headingTypography.nameFontSize',
        )
      ) {
        data.textFontSize = headingTypography.nameFontSize;
      }
    }
    return data;
  };
  const [globalStylingData, setGlobalStylingData] = useState(() =>
    handleGlobalStyling(),
  );
  useEffect(() => {
    let data = handleGlobalStyling();
    setGlobalStylingData(data);
  }, [globalStyling]);
  return (
    <div className={`flex flex-col `}>
      <div
        style={{
          padding: `${headingPadding?.[0]}px ${headingPadding?.[1]}px ${headingPadding?.[2]}px ${headingPadding?.[3]}px`,
          margin: `${headingMargin?.[0]}px ${headingMargin?.[1]}px ${headingMargin?.[2]}px ${headingMargin?.[3]}px`,
        }}
      >
        {hideText && (
          <div
            className={`${textPosition} p-2 ${headingTypography.typographyWeight} break-words`}
            style={{
              color: globalStylingData.fontColor,
              fontFamily: headingTypography.family,
              fontStyle: headingTypography.typographyStyle,
              lineHeight: headingTypography.lineHeight,
              fontSize: globalStylingData.textFontSize,
            }}
          >
            {textContent}
          </div>
        )}
      </div>
    </div>
  );
};
export default Heading;
