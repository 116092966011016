import { useEffect, useState } from 'react';
import logo from '../../../assets/images.jpeg';
const Company = ({
  companyName = 'Company name',
  CompanyLogo = logo,
  role = 'Developer',
  layout = 'flex-col',
  textColor = '#000000',
  companyPadding = [0, 0, 0, 0],
  companyMargin = [0, 0, 0, 0],
  globalStyling = {
    fontColor: '#000000',
    linkColor: '#000000',
    fontSize: 'text-xl',
    status: false,
    ignoreFieldArray: [] as string[],
    globalChangesList: [] as string[],
  },
}) => {
  const URL = import.meta.env.VITE_API_URL;
  let handleGlobalStyling = () => {
    let data = {
      fontColor: globalStyling.fontColor,
    };
    if (globalStyling.status) {
      if (globalStyling?.ignoreFieldArray?.length === 0) {
        if (globalStyling?.globalChangesList.includes('textColor')) {
          data.fontColor = globalStyling.fontColor;
        }
      } else {
        if (globalStyling?.ignoreFieldArray?.includes('textColor')) {
          data.fontColor = textColor;
        } else {
          data.fontColor = globalStyling.fontColor;
        }
      }
    } else {
      if (globalStyling?.ignoreFieldArray?.includes('textColor')) {
        data.fontColor = textColor;
      }
    }
    return data;
  };
  const [globalStylingData, setGlobalStylingData] = useState(() =>
    handleGlobalStyling(),
  );
  const layoutStyle =
    layout === 'side-by-side'
      ? 'flex justify-center items-center gap-10'
      : 'flex-col justify-center items-center gap-10';
  useEffect(() => {
    let data = handleGlobalStyling();
    setGlobalStylingData(data);
  }, [globalStyling]);
  return (
    <div className={`text-center py-5`}>
      <div
        className={`${layoutStyle}`}
        style={{
          padding: `${companyPadding?.[0]}px ${companyPadding?.[1]}px ${companyPadding?.[2]}px ${companyPadding?.[3]}px`,
          margin: `${companyMargin?.[0]}px ${companyMargin?.[1]}px ${companyMargin?.[2]}px ${companyMargin?.[3]}px`,
        }}
      >
        <div>
          <img
            src={`${URL}/public/cards/images/${CompanyLogo}`}
            alt={companyName}
            className="w-[100px] h-[100px] rounded-full m-auto shadow-xl"
          />
        </div>
        <div>
          <p className="text-lg" style={{ color: globalStylingData.fontColor }}>
            {companyName}
          </p>
          <p className="text-sm" style={{ color: globalStylingData.fontColor }}>
            {role}
          </p>
        </div>
      </div>
    </div>
  );
};
export default Company;