import { Icon } from '@iconify/react/dist/iconify.js';
import { SocialIcon } from 'react-social-icons';
import { useEffect, useState } from 'react';
const Divider = ({
  id= '0',
  borderColor = 'black',
  dividerBorderStyle = 'border-solid',
  dividerWidth = '100',
  dividerPosition = 'justify-start',
  globalStyling = {
    status: false,
    pmColor: '#fff',
    seColor: '#000000',
    ignoreFieldArray: [] as string[],
    globalChangesList: [] as string[],
  },
  icon = [
    {
      name: 'icon',
      iconType: 'local',
      iconName: 'calendar',
      fgColor: '#2563eb',
      bgColor: 'black',
      show: 'none',
    },
  ],
}) => {
  const api_url = import.meta.env.VITE_API_URL;
  let handleGlobalStyling = () => {
    let data = {
      icon: icon,
    };
    if (globalStyling.status) {
      if (globalStyling?.ignoreFieldArray?.length === 0) {
        if (globalStyling?.globalChangesList.includes('pmColor')) {
          data.icon.filter((item) => {
            item.bgColor = globalStyling.pmColor;
          });
        }
      } else {
        data.icon = icon.filter((item, index) => {
          if (
            globalStyling?.ignoreFieldArray?.includes(
              `icon-${id}-${index}-bgColor`,
            )
          ) {
            item.bgColor = item.bgColor;
            return item;
          } else {
            item.bgColor = globalStyling.pmColor;
            return item;
          }
        });
      }
      if (globalStyling?.ignoreFieldArray?.length === 0) {
        if (globalStyling?.globalChangesList.includes('seColor')) {
          data.icon.filter((item) => {
            item.fgColor = globalStyling.seColor;
          });
        }
      } else {
        data.icon = icon.filter((item, index) => {
          if (
            globalStyling?.ignoreFieldArray?.includes(
              `icon-${id}-${index}-fgColor`,
            )
          ) {
            item.fgColor = item.fgColor;
            return item
          }else{
            item.fgColor = globalStyling.seColor;
            return item
          }
        });
      }
    } else {
      data.icon = icon.filter((item, index) => {
        if (
          globalStyling?.ignoreFieldArray?.includes(
            `icon-${id}-${index}-bgColor`,
          )
        ) {
          item.bgColor = item.bgColor;
          return item;
        } else {
          return item;
        }
      });
      data.icon = icon.filter((item, index) => {
        if (
          globalStyling?.ignoreFieldArray?.includes(
            `icon-${id}-${index}-fgColor`,
          )
        ) {
          item.fgColor = item.fgColor;
          return item;
        } else {
          return item;
        }
      });
    }
    return data;
  };
  const [globalStylingData, setGlobalStylingData] = useState(() =>
    handleGlobalStyling(),
  );
  useEffect(() => {
    let data = handleGlobalStyling();
    setGlobalStylingData(data);
  }, [globalStyling]);
  return (
    <div className="my-4 flex flex-col">
      <div className={`flex ${dividerPosition}`}>
        <div
          className={`flex items-center gap-1 px-4 w-full`}
          style={{
            width: `${dividerWidth}%`,
          }}
        >
          <hr
            className={`flex-grow border-t border-[1px] ${dividerBorderStyle}`}
            style={{ borderColor: borderColor }}
          />
          {globalStylingData.icon[0].show == 'icon' &&
            globalStylingData.icon.length > 0 &&
            globalStylingData.icon.map((link) => (
              <>
                {link.iconType === 'local' && (
                  <Icon
                    icon={link.iconName}
                    className={`w-[25px] h-[25px] rounded`}
                    color={link.fgColor}
                    style={{ backgroundColor: link.bgColor }}
                  />
                )}
                {link.iconType === 'browse' && (
                  <SocialIcon
                    url=""
                    bgColor={link.bgColor}
                    fgColor={link.fgColor}
                    style={{ width: '40px', height: '40px' }}
                    as="div"
                  />
                )}
                {link.iconType === 'image' && (
                  <img
                    src={`${api_url}/public/cards/images/${link.iconName}`}
                    alt={link.name}
                    className="w-10 h-10 rounded-full object-cover"
                  />
                )}
                <hr
                  className={`flex-grow border-t border-[1px] ${dividerBorderStyle}`}
                  style={{ borderColor: borderColor }}
                />
              </>
            ))}
        </div>
      </div>
    </div>
  );
};
export default Divider;
