// Admin Api Service

import axios from 'axios';
const URL = import.meta.env.VITE_API_URL;

const jsonconfig = {
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
};

// for admin login api
export const AdminLoginApi = async (data: any) => {
  try {
    return await axios.post(`${URL}/admin/admin-login`, data, jsonconfig);
  } catch (error) {
    // Throw error so the calling function can handle it
    throw error;
  }
};

// Admin Profile Api
export const AdminProfileData = async () => {
  try {
    return await axios.get(`${URL}/admin/profile`, jsonconfig);
  } catch (error) {
    console.error(error);
  }
};

export const AdminEditProfileData = async (data: any) => {
  try {
    return await axios.put(
      `${URL}/admin/edit-user-information`,
      data,
      jsonconfig,
    );
  } catch (error) {
    console.error(error);
  }
};

// Getall user api

export const GetAllUsers = async () => {
  try {
    return await axios.get(`${URL}/admin/getallusers`, jsonconfig);
  } catch (error) {
    console.error('Error getting all users api:', error);
  }
};

// Getall Subscriptions user api
export const GetAllUsersHaveSubscriptions = async () => {
  try {
    return await axios.get(
      `${URL}/admin/getallusers/subscriptions`,
      jsonconfig,
    );
  } catch (error) {
    console.error('Error getting all users api:', error);
  }
};

// Get  user api
export const GetUserDetails = async (user_id: string) => {
  try {
    return await axios.get(
      `${URL}/admin/user/view/detail/${user_id}`,
      jsonconfig,
    );
  } catch (error) {
    console.error('Error getting user details api:', error);
  }
};

//Get Subscription user detailsq

export const GetSubscriptionUserDetails = async (user_id: string) => {
  try {
    return await axios.get(
      `${URL}/admin/user/view/subscription/${user_id}`,
      jsonconfig,
    );
  } catch (error) {
    console.error('Error getting user subscription details api:', error);
  }
};

export const GetSiteVisit = async (userId: string) => {
  try {
    return await axios.post(`${URL}/admin/site-visit/${userId}`, jsonconfig);
  } catch (error) {
    console.error('Error getting user subscription details api:', error);
  }
};

export const GetViewEngagement = async () => {
  try {
    return await axios.get(`${URL}/admin/view-count`, jsonconfig);
  } catch (error) {
    console.error('Error getting user subscription details api:', error);
  }
};

export const GetTeamMate = async (id: string) => {
  try {
    return await axios.get(`${URL}/admin/users/team/${id}`, jsonconfig);
  } catch (error) {
    console.error('Error getting user subscription details api:', error);
  }
};

// Function to upload a image
export const uploadImage = async (file: any) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const response = await axios.post(`${URL}/admin/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error('Error uploading file:', error);
  }
};

// get the admin support details

export const getTheUserSupport = async () => {
  try {
    return await axios.get(`${URL}/admin/support/details`, jsonconfig);
  } catch (error) {
    console.error('Error getting admin support details api:', error);
  }
};
export const sendMessageToUser = async (messageData: any) => {
  try {
    return await axios.post(
      `${URL}/admin/send/message/user`,
      messageData,
      jsonconfig,
    );
  } catch (error) {
    console.error('Error getting admin support details api:', error);
  }
};

export const getEmailTemplate = async () => {
  try {
    return await axios.get(`${URL}/email/get-emailTemplate`, jsonconfig);
  } catch (error) {
    console.error('Error getting admin email template api:', error);
  }
};

export const postEmailTemlate = async (data: {
  title: string;
  subject: string;
  body: string;
}) => {
  try {
    return await axios.post(
      `${URL}/email/post-emailTemplate`,
      data,
      jsonconfig,
    );
  } catch (error) {
    console.error('Error getting admin post email template api:', error);
  }
};

export const updateEmailTemplate = async (data: any) => {
  try {
    return await axios.post(`${URL}/email/update-emailTemplate`, { data });
  } catch (error) {
    console.error('Error getting update notification', error);
  }
};

// Notification setting api
export const saveAdminNotificationSettings = async (settings: any) => {
  try {
    return await axios.post(
      `${URL}/notification/admin/save-notification-settings`,
      { settings },
      jsonconfig,
    );
  } catch (error) {
    console.error('Error saving admin notification settings:', error);
  }
};
export const getAdminNotificationSetting = async () => {
  try {
    return await axios.get(
      `${URL}/notification/admin/get-notification-settings`,
      jsonconfig,
    );
  } catch (error) {
    console.error('Error retriving admin notification settings:', error);
  }
};

// Change password api
export const changeAdminPassword = async (data: any) => {
  try {
    return await axios.post(`${URL}/admin/change-password`, data, jsonconfig);
  } catch (error) {
    console.error('Error changing admin password:', error);
  }
};