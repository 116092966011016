// export const isTouchDevice = () => {
//     return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
//   };

export const isTouchDevice = () => {
  // Check if the device supports touch and if it's not a desktop with a touch screen
  const hasTouchScreen =
    'ontouchstart' in window || navigator.maxTouchPoints > 0;
  const isLaptopWithTouchScreen = window.matchMedia('(pointer: fine)').matches;

  // Return true only for devices that are purely touch-based (mobile, tablets)
  return hasTouchScreen && !isLaptopWithTouchScreen;
};
