import { useEffect, useState } from 'react';
import moment from 'moment';
import Loader from '../../../common/loader/Index';
import DefaultLayout from '../../../layout/Defaultlayout';
import { getAdminActivity } from '../../../api/Api';
import { CiCalendarDate } from 'react-icons/ci';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select, { StylesConfig } from 'react-select';
import {
  subDays,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
} from 'date-fns';

const customStyles: StylesConfig<any, false> = {
  control: (provided: any) => ({
    ...provided,
    borderRadius: '12px',
    padding: '0 10px',
    borderColor: '#D8D8D8',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#DFC749',
    },
  }),
  option: (provided: any, state: { isFocused: any; isDisabled: boolean }) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#DFC749' : '#fff',
    color: state.isDisabled ? '#A9A9A9' : state.isFocused ? '#fff' : '#000',
    '&:hover': {
      backgroundColor: state.isDisabled ? '#fff' : '#DFC749',
      color: state.isDisabled ? '#A9A9A9' : '#fff',
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: '#000',
  }),
  menu: (provided: any) => ({
    ...provided,
    borderRadius: '10px',
    overflow: 'hidden',
  }),
  menuList: (provided) => ({
    ...provided,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555', // Scrollbar thumb hover color
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f0f0f0',
    },
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
};

const option = [
  { value: 'all', label: 'All' },
  { value: 'today', label: 'Today' },
  { value: 'yesterday', label: 'Yesterday' },
  { value: 'this week', label: 'This week' },
  { value: 'last week', label: 'Last week' },
  { value: 'this month', label: 'This month' },
  { value: 'last month', label: 'Last month' },
  { value: 'custom', label: 'Custom' },
];

interface ActivitiesProps {
  id: number;
  user_id: number;
  action_type: string;
  description: string;
  created_at: string;
  first_name: string;
  last_name: string;
}

function AdminActivity() {
  const [activities, setActivities] = useState<ActivitiesProps[]>([]);
  const [filteredActivities, setFilteredActivities] = useState<
    ActivitiesProps[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string>('all');
  const [startDates, setStartDates] = useState(new Date());
  const [endDates, setEndDates] = useState(new Date());
  useEffect(() => {
    GetActivities();
  }, []);
  // Filter activities based on date range
  useEffect(() => {
    filterActivities();
  }, [activities, startDates, endDates]);

  const GetActivities = async () => {
    try {
      setLoading(true);
      const response = await getAdminActivity();
      if (response?.data.success) {
        setActivities(response.data.activities);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching activities:', error);
      setLoading(false);
    }
  };

  const filterActivities = () => {
    if (selectedOption === 'all') {
      setFilteredActivities(activities);
    } else if (startDates && endDates) {
      const filtered = activities.filter((activity) => {
        const createdAt = new Date(activity.created_at);
        const startDate = new Date(startDates);
        const endDate = new Date(endDates);
        // Normalize dates to start of the day
        const normalizeDate = (date: Date) => {
          return new Date(date.getFullYear(), date.getMonth(), date.getDate());
        };
        const createdAtNormalized = normalizeDate(createdAt);
        const startDateNormalized = normalizeDate(startDate);
        const endDateNormalized = normalizeDate(endDate);

        return (
          createdAtNormalized >= startDateNormalized &&
          createdAtNormalized <= endDateNormalized
        );
      });
      setFilteredActivities(filtered);
    }
  };

  const formatDate = (dateString: string) => {
    const date = moment(dateString);
    const today = moment().startOf('day');
    const yesterday = moment().subtract(1, 'days').startOf('day');

    if (date.isSame(today, 'day')) {
      return `Today, ${date.format('h:mm A')}`;
    } else if (date.isSame(yesterday, 'day')) {
      return `Yesterday, ${date.format('h:mm A')}`;
    } else {
      return date.format('MMM DD, h:mm A');
    }
  };

  const handleDropdownChange = (selected: any) => {
    setSelectedOption(selected.value);
    let startDate = new Date();
    let endDate = new Date();

    switch (selected.value) {
      case 'today':
        startDate = new Date();
        endDate = new Date();
        break;
      case 'yesterday':
        startDate = subDays(new Date(), 1);
        endDate = subDays(new Date(), 1);
        break;
      case 'this week':
        startDate = startOfWeek(new Date(), { weekStartsOn: 1 });
        endDate = endOfWeek(new Date(), { weekStartsOn: 1 });
        break;
      case 'last week':
        startDate = subDays(startOfWeek(new Date(), { weekStartsOn: 1 }), 7);
        endDate = subDays(endOfWeek(new Date(), { weekStartsOn: 1 }), 7);
        break;
      case 'this month':
        startDate = startOfMonth(new Date());
        endDate = endOfMonth(new Date());
        break;
      case 'last month':
        startDate = startOfMonth(subDays(new Date(), 30));
        endDate = endOfMonth(subDays(new Date(), 30));
        break;
      default:
        break;
    }
    setStartDates(startDate);
    setEndDates(endDate);
  };
  const onChange = (dates: any) => {
    const [start, end] = dates;
    setStartDates(start);
    setEndDates(end);
  };

  const customFilter = (
    option: any,
    inputValue: string
  ): boolean => {
    return option?.label?.toLowerCase().startsWith(inputValue?.toLowerCase());
  };

  return (
    <>
      <DefaultLayout title="User Activities">
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="bg-white w-full rounded-lg">
              <div className="border-b border-[#e4e8eb] p-4 flex">
                <div>
                  <p className="text-black font-bold text-xl">Activity</p>
                </div>
                <div className="flex  sm:flex-row justify-end gap-2 w-full">
                  {selectedOption === 'custom' && (
                    <>
                      <div className="mr-5 flex items-center">
                        <div className="border-l border-y border-[#D8D8D8] px-3 py-[8.5px] rounded-l-[11px]">
                          <CiCalendarDate
                            style={{
                              color: '#000',
                              width: '20px',
                              height: '20px',
                            }}
                          />
                        </div>
                        <DatePicker
                          selected={startDates}
                          onChange={onChange}
                          startDate={startDates}
                          endDate={endDates}
                          selectsRange
                          dateFormat="dd/MM/yyyy"
                          className="border border-[#D8D8D8] w-[115%] sm:w-[111%] text-black px-3 py-[6.5px] rounded-r-[11px]"
                          maxDate={new Date()}
                        />
                      </div>
                    </>
                  )}
                  <div className="relative w-[53%] sm:w-[40%] md:w-[30%] lg:w-[20%]">
                    <Select
                      options={option}
                      styles={customStyles}
                      onChange={handleDropdownChange}
                      value={option.find(
                        (option) => option.value === selectedOption,
                      )}
                      placeholder="Select"
                      onMenuOpen={() => setIsOpen(true)}
                      onMenuClose={() => setIsOpen(false)}
                      filterOption={customFilter}

                    />
                    <span
                      className={`absolute top-0 right-3 h-full flex items-center pointer-events-none transition-transform duration-200 ease-in-out ${
                        isOpen ? 'rotate-180' : 'rotate-0'
                      } `}
                    >
                      <svg
                        width="25"
                        height="25"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M7 10L12 15L17 10H7Z" fill="#000" />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
              <div className="p-4 max-h-[32rem] overflow-y-auto scrollbar-custom scroll-smooth">
                {filteredActivities?.length > 0 ? (
                  <ol className="activity-feed p-3.5 list-none">
                    {filteredActivities?.map((activity) => (
                      <li
                        key={activity.id}
                        className="activity-feed-item relative pb-5 pl-7.5 border-l-2 border-solid border-[#e4e8eb] text-purpletacx font-medium"
                      >
                        <span className="relative -top-2">
                          {activity.first_name ? activity.first_name : ''}{''}
                          {activity.last_name ? activity.last_name : ''}{''}
                          {activity.description}
                        </span>
                        <time className="block relative -top-[8px] text-[#8c96a3] text-sm ">
                          {/* {moment(activity.created_at).format('MMM DD')} */}
                          {formatDate(activity.created_at)}
                        </time>
                      </li>
                    ))}
                  </ol>
                ) : (
                  <p className="p-4">No activities to show.</p>
                )}
              </div>
            </div>
          </>
        )}
      </DefaultLayout>
    </>
  );
}

export default AdminActivity;
