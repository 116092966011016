import React from 'react';
import MyIcon from '../cardeditor/icons/Myicon';
import { useNavigate } from 'react-router-dom';
interface DynamicModalProps {
  onClose: () => void;
  mainMessage: string;
  message: string;
  iconName: string;
  isOpen: boolean;
  buttonName: string;
}
const MessageModel: React.FC<DynamicModalProps> = ({
  onClose,
  mainMessage,
  message,
  iconName,
  isOpen,
  buttonName = 'okay',
}) => {
  const navigate = useNavigate();
  const handleClose = () => {
    onClose();
    if (mainMessage === 'Profile Saved') {
      navigate('/my-profile');
    }
  };
  return isOpen ? (
    <div className="fixed grid content-center top-0 bottom-0 left-0 z-[999999] w-screen h:screen bg-[#59425580] bg-opacity-70 font-poppins fade-in-animate">
      <div className="bg-purpletacx flex m-auto justify-center rounded-lg relative w-[90%] md:w-1/2 xl:w-1/3 slideInFromTop-animate">
        <div className="p-5 sm:p-10 py-15 w-full flex flex-col items-center">
          <MyIcon iconName={iconName} />
          <div className="mt-8 text-center text-white">
            <p className="font-extrabold text-2xl">{mainMessage}</p>
            <p className="text-md">{message}</p>
          </div>
          <div
            className="mt-4 px-25 sm:px-35 py-3 rounded-full bg-yellowtacx text-black font-bold cursor-pointer"
            onClick={handleClose}
          >
            {buttonName}
          </div>
        </div>
      </div>
    </div>
  ) : null;
};
export default MessageModel;
