import React, { ReactNode } from 'react';
interface CardDataStatsProps {
  title: string;
  total: any;
  rate: string;
  levelUp?: boolean;
  levelDown?: boolean;
  children: ReactNode;
}
const CardDataStats: React.FC<CardDataStatsProps> = ({
  title,
  total,
  children,
}) => {
  return (
    <div className="rounded-lg border border-stroke bg-white py-6 px-4 shadow-default">
      <div className="flex justify-between">
        <div>
          <h4 className="text-title-md font-bold text-black">{total}</h4>
          <span className="text-sm font-medium">{title}</span>
        </div>
        <div className="flex h-11.5 w-11.5 items-center justify-center rounded-lg bg-purpletacx">
          {children}
        </div>
      </div>
    </div>
  );
};
export default CardDataStats;