import React, { useEffect, useState } from 'react';
import DefaultLayout from '../../layout/Defaultlayout';
import Loader from '../../common/loader/Index';
import { quoteFormsData } from '../../api/Api';
import Pagination from '../../components/pagination/Pagination';
import { FaSearch } from 'react-icons/fa';

const QuoteMessage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<
    Array<{ id: number; data: string; created_at: string }>
  >([]);
  const [fields, setFields] = useState<Array<string>>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 5;

  useEffect(() => {
    fetchDataMessage();
  }, []);

  const fetchDataMessage = async () => {
    try {
      const fetchApi = await quoteFormsData();
      console.log(fetchApi,"fetchApi")
      const dataArray = fetchApi?.data;
      // Collect all unique fields across all data entries
      const allFields = new Set<string>();
      dataArray.forEach((item: { data: string; created_at: string }) => {
        const parsedItem = JSON.parse(item.data);
        Object.keys(parsedItem).forEach((field) => allFields.add(field));
      });
      allFields.add('created_at'); // Ensure the 'created_at' field is included

      setFields(Array.from(allFields));
      setData(dataArray);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const parseData = (dataString: string) => {
    try {
      return JSON.parse(dataString);
    } catch (error) {
      console.error('Error parsing JSON data:', error);
      return {};
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = data.filter((item) => {
    const parsedData = parseData(item.data);
    return (
      parsedData.Name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      parsedData.Email?.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const totalItems = filteredData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, totalItems);
  const currentItems = filteredData.slice(startIndex, endIndex);

  return (
    <DefaultLayout title="Visitors Details">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="bg-white shadow-lg rounded-lg mt-5">
            <div className="flex flex-row justify-between items-center mb-2 mt-2 bg-[#F7F7F7] p-4 rounded-lg gap-4">
              <p className="font-medium text-md text-black">
                Showing {currentItems.length} of {totalItems} visitors
              </p>
              <div className="flex items-center relative">
                <input
                  type="text"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  className="w-full rounded-xl border border-stroke bg-white px-3 py-2 pr-10 text-gray-800 text-black focus:outline-none"
                />
                <FaSearch className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500" />
              </div>
            </div>
            <div className="overflow-x-auto animate-fade-in scrollbar-custom shadow-md rounded-lg">
              <div>
                <table className="min-w-full rounded-lg">
                  <thead>
                    <tr>
                      {fields.length > 0 && (
                        <th className="py-3 px-6 text-left border-b border-gray whitespace-nowrap tracking-wider">
                          No.
                        </th>
                      )}
                      {fields.map((field, index) => (
                        <th
                          key={index}
                          className="py-3 px-6 text-left border-b border-gray whitespace-nowrap tracking-wider"
                        >
                          {field === 'created_at' ? 'Date & Time' : field}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  {/* Scrollable Body */}
                  <tbody className="text-gray-600 text-md divide-y divide-gray-200">
                    {currentItems.length > 0 ? (
                      currentItems.map((item, index) => {
                        const parsedData = parseData(item.data);
                        return (
                          <tr
                            key={item.id}
                            className="hover:bg-gray-100 transition duration-300 ease-in-out text-center"
                          >
                            <td className="py-4 px-6 min-h-10 min-w-[50px] bg-white text-black text-left border-b border-gray whitespace-nowrap hover:bg-gray-200 transition duration-300">
                              {startIndex + index + 1}
                            </td>
                            {fields.map((field, fieldIndex) => (
                              <td
                                key={fieldIndex}
                                className={`py-4 px-6 bg-white text-left text-black border-b border-gray whitespace-nowrap hover:bg-gray-200 transition duration-300`}
                              >
                                {field === 'created_at'
                                  ? new Date(item.created_at).toLocaleString('en-GB',{
                                    year: 'numeric',
                                    month: 'short',
                                    day: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    hour12: true,
                                  })
                                  : parsedData[field] || '-'}
                              </td>
                            ))}
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td
                          colSpan={fields.length + 1}
                          className="py-4 px-6 text-center text-black bg-white border-t border-gray-200"
                        >
                          No visitors found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {totalPages > 1 && (
                <div className="mt-4 p-4 bg-white shadow-lg rounded-lg flex justify-center items-center">
                  <Pagination
                    totalPages={totalPages}
                    setCurrentPage={setCurrentPage}
                    startIndex={startIndex}
                    endIndex={endIndex}
                    totalItems={totalItems}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </DefaultLayout>
  );
};

export default QuoteMessage;
