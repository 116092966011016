import { QRCodeCanvas } from 'qrcode.react';
import SharingCard from '../../components/cardsharing/Sharingcard';
import EmailShare from '../../components/cardsharing/Emailshare';
import SmsShareCard from '../cardsharing/SMSshare';
import { useRef, useState } from 'react';
import { MdTextsms } from 'react-icons/md';
import { TfiEmail } from 'react-icons/tfi';
import { FiShare2 } from 'react-icons/fi';
import { FiDownload } from 'react-icons/fi';
import { MdOutlineFileDownloadDone } from 'react-icons/md';

interface QRModalProps {
  isOpen: boolean;
  QrVal: string;
  setQrVal: (value: string) => void;
  closeQrModal: () => void;
}
const QRModal: React.FC<QRModalProps> = ({
  isOpen,
  QrVal,
  // setQrVal,
  closeQrModal,
}) => {
  const [activeComponent, setActiveComponent] = useState<string | null>(null);
  const [downloaded, setDownloaded] = useState<boolean>(false);
  const qrRef = useRef<HTMLDivElement | null>(null);
  if (!isOpen) return null;
  const shareTitle = 'Check out this amazing card!';
  const handleComponentToggle = (componentName: string) => {
    setActiveComponent((prev) =>
      prev === componentName ? null : componentName,
    );
  };
  const closeModel = () => {
    closeQrModal();
    setActiveComponent(null);
  };

  // Handle QR Code download
  const handleDownload = () => {
    const canvas = qrRef.current?.querySelector('canvas');
    if (canvas) {
      const pngUrl = canvas.toDataURL('image/png');
      const downloadLink = document.createElement('a');
      downloadLink.href = pngUrl;
      downloadLink.download = 'qrcode.png';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      // Set download status to true, then revert after 3 seconds
      setDownloaded(true);
      setTimeout(() => {
        setDownloaded(false);
      }, 3000);
    }
  };

  return (
    <div className="fixed grid content-center top-0 bottom-0 left-0 z-[999999] w-screen h:screen bg-[#59425580] bg-opacity-70">
      <div className="bg-white flex flex-col p-5 m-auto rounded-lg w-[80%] sm:w-[300px] slideInFromTop-animate relative">
        <div className="absolute right-2 top-2">
          <button onClick={closeModel}>
            <svg
              width="15"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.8307 15.8346L3.16406 3.16797M15.8307 3.16797L3.16406 15.8346"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </button>
        </div>
        <div className="flex justify-center" ref={qrRef}>
          <QRCodeCanvas
            value={QrVal}
            size={150}
            bgColor="#000000"
            fgColor="#ffffff"
            level="H"
          />
        </div>
        <div className="w-full flex justify-center mt-3">
          <button
            className="bg-purpletacx text-white rounded-lg px-2 py-2 text-sm flex gap-2 items-center"
            onClick={handleDownload}
          >
            <p>Download QR Code </p>
            <span>
              {downloaded ? (
                <MdOutlineFileDownloadDone size={20} />
              ) : (
                <FiDownload size={20} />
              )}
            </span>
          </button>
        </div>
        <div className="flex text-purpletacx items-center justify-between mt-8">
          <button onClick={() => handleComponentToggle('sms')}>
            <MdTextsms size={24} />
          </button>
          <button onClick={() => handleComponentToggle('email')}>
            <TfiEmail size={24} />
          </button>
          <button onClick={() => handleComponentToggle('social')}>
            <FiShare2 size={24} />
          </button>
        </div>
        <div>
          {activeComponent === 'sms' && <SmsShareCard url={QrVal} />}
          {activeComponent === 'email' && <EmailShare url={QrVal} />}
          {activeComponent === 'social' && (
            <SharingCard url={QrVal} title={shareTitle} />
          )}
        </div>
      </div>
    </div>
  );
};
export default QRModal;
