import { useEffect, useState } from 'react';
import DropdownUser from './Dropdownuser';
import { ProfileData } from '../../api/Api';
import { useSelector } from 'react-redux';
import { selectUser } from '../../store/slice/Userslice';
import { AdminProfileData } from '../../api/Adminapi';
import DropdownNotification from './Dropdownnotification';
import UserSupport from '../../components/header/UserSupport'; // Custom user support ico
import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
interface UserData {
  user: any;
  email: string;
  first_name: string;
  last_name: string;
  teamSize: string;
  industry: string;
  company_name: string | null;
  phone_number: string | null;
  profile_picture: any;
  user_role?: string;
}

const Header = (props: {
  sidebarOpen: string | boolean | undefined;
  setSidebarOpen: (arg0: boolean) => void;
  title: string;
}) => {
  const [userData, setUserData] = useState<UserData | null>(null);
  const selectUserDetails = useSelector(selectUser);

  useEffect(() => {
    profileApi();
  }, []);

  const profileApi = async () => {
    try {
      if (selectUserDetails?.user?.user_role === 'Admin') {
        const userDataResponse = await AdminProfileData();
        setUserData(userDataResponse?.data);
      } else {
        const res = await ProfileData();
        if (res?.data.success) {
          setUserData(res?.data.user);
        }
      }
    } catch (error) {
      console.error('Failed to fetch profile data:', error);
    }
  };

  return (
    <>
      <header className="sticky top-0 z-999 flex w-full bg-[#F1F5F9]">
        <div className="flex flex-grow items-center justify-between px-4 py-4 md:px-6 2xl:px-10 border-b border-[#59425533]">
          <div className="flex items-center gap-2 sm:gap-4 lg:hidden">
            <button
              aria-controls="sidebar"
              onClick={(e) => {
                e.stopPropagation();
                props.setSidebarOpen(true);
              }}
              className="z-99999 block rounded-lg border border-stroke bg-white p-1.5 shadow-sm lg:hidden"
            >
              <span className="relative block h-5.5 w-5.5 cursor-pointer">
                <span className="du-block absolute right-0 h-full w-full">
                  <span
                    className={`relative left-0 top-0 my-1 block h-0.5 w-0 rounded-sm bg-black delay-[0] duration-200 ease-in-out dark:bg-white ${
                      !props.sidebarOpen && '!w-full delay-300'
                    }`}
                  ></span>
                  <span
                    className={`relative left-0 top-0 my-1 block h-0.5 w-0 rounded-sm bg-black delay-150 duration-200 ease-in-out dark:bg-white ${
                      !props.sidebarOpen && 'delay-400 !w-full'
                    }`}
                  ></span>
                  <span
                    className={`relative left-0 top-0 my-1 block h-0.5 w-0 rounded-sm bg-black delay-200 duration-200 ease-in-out dark:bg-white ${
                      !props.sidebarOpen && '!w-full delay-500'
                    }`}
                  ></span>
                </span>
              </span>
            </button>
          </div>
          <div className="hidden sm:block">
            <h1 className="text-black font-extrabold text-lg">{props.title}</h1>
          </div>

          <div className="flex items-center gap-5">
            {selectUserDetails?.user.user_role != 'Admin' ? (
              <ul className="flex items-center gap-2 2xsm:gap-4">
                <div data-tooltip-id="support" className="flex justify-center items-center w-8 h-8">
                  {' '}
                  <UserSupport className="w-full h-full" user={userData} />{' '}
                </div>
              </ul>
            ) : null}
            <span data-tooltip-id="notification">
              <DropdownNotification />
            </span>
            <DropdownUser user={userData} />
          </div>
        </div>
      </header>
      <ReactTooltip
        id="notification"
        // place="bottom"
        content="Notifications"
      />
      <ReactTooltip
        id="support"
        // place="bottom"
        content="Support"
      />
    </>
  );
};

export default Header;
