import { useEffect, useState } from 'react';
const TextEditor = ({
  textContent = '<p>HHELLO</p><ul><li><strong><em>HEEKJDJS</em></strong></li><li><strong><em>SDSSDS</em></strong></li></ul>', // Updated textContent
  fontColor = '#594255',
  hideText = false,
  textUsTypography = {
    type: 'editor',
    family: 'poppins, font-poppins',
    nameFontSize: 'text-xl',
    typographyWeight: 'font-semibold', // Adjusted to Tailwind weight
    typographyStyle: 'normal',
    lineHeight: '1.75',
  },
  textUsPadding = [20, 20, 20, 20], // Increased padding for aesthetics
  textUsMargin = [10, 10, 10, 10], // Added margin for balance
  globalStyling = {
    fontColor: '#000000',
    linkColor: '#000000',
    fontSize: 'text-xl',
    status: false,
    ignoreFieldArray: [] as string[],
    globalChangesList: [] as string[],
  },
}) => {
  const handleGlobalStyling = () => {
    let data = {
      fontColor: globalStyling.fontColor,
      textFontSize: globalStyling.fontSize,
    };
    if (globalStyling.status) {
      if (globalStyling.ignoreFieldArray.length === 0) {
        if (globalStyling.globalChangesList.includes('textColor')) {
          data.fontColor = globalStyling.fontColor;
        }
      } else {
        if (globalStyling.ignoreFieldArray.includes('fontColor')) {
          data.fontColor = fontColor;
        } else {
          data.fontColor = globalStyling.fontColor;
        }
      }
      if (globalStyling.ignoreFieldArray.length === 0) {
        if (globalStyling.globalChangesList.includes('fontSize')) {
          data.textFontSize = globalStyling.fontSize;
        }
      } else {
        if (
          globalStyling.ignoreFieldArray.includes(
            'textUsTypography.nameFontSize',
          )
        ) {
          data.textFontSize = textUsTypography.nameFontSize;
        } else {
          data.textFontSize = globalStyling.fontSize;
        }
      }
    } else {
      if (globalStyling.ignoreFieldArray.includes('fontColor')) {
        data.fontColor = fontColor;
      }
      if (
        globalStyling.ignoreFieldArray.includes('textUsTypography.nameFontSize')
      ) {
        data.textFontSize = textUsTypography.nameFontSize;
      }
    }
    return data;
  };
  const [globalStylingData, setGlobalStylingData] = useState(() =>
    handleGlobalStyling(),
  );
  useEffect(() => {
    let data = handleGlobalStyling();
    setGlobalStylingData(data);
  }, [globalStyling]);
  // Function to apply styles to <li> elements
  const renderHtmlWithBulletPoints = (html: any) => {
    return html
      .replace(/<ul>/g, '<ul class="list-disc pl-5">')
      .replace(/<ol>/g, '<ol class="list-decimal pl-5">');
  };
  return (
    <div
      className="my-4 flex flex-col max-w-4xl mx-auto"
      style={{
        padding: `${textUsPadding?.[0]}px ${textUsPadding?.[1]}px ${textUsPadding?.[2]}px ${textUsPadding?.[3]}px`,
        margin: `${textUsMargin?.[0]}px ${textUsMargin?.[1]}px ${textUsMargin?.[2]}px ${textUsMargin?.[3]}px`,
      }}
    >
      {hideText && (
        <div
          className={`${textUsTypography.typographyWeight} break-words`}
          style={{
            color: globalStylingData.fontColor,
            fontFamily: textUsTypography.family,
            fontStyle: textUsTypography.typographyStyle,
            lineHeight: textUsTypography.lineHeight,
            fontSize: globalStylingData.textFontSize,
          }}
          dangerouslySetInnerHTML={{
            __html: renderHtmlWithBulletPoints(textContent),
          }} // Render HTML content
        />
      )}
    </div>
  );
};
export default TextEditor;
