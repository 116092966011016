import { useEffect, useState } from 'react';
import { SocialIcon } from 'react-social-icons';
import { Icon } from '@iconify/react';
const iconText = ({
  id='',
  links = [
    {
      url: 'https://www.google.com',
      name: 'Google',
      iconType: 'local',
      iconName: 'calendar',
      fgColor: '#2563eb',
      bgColor: 'black',
    },
  ],
  linkColor = '#000000',
  linkShape = 'rounded',
  linkAlignment = 'justify-center',
  globalStyling = {
    fontColor: '#000000',
    linkColor: '#000000',
    fontSize: 'text-xl',
    status: false,
    pmColor:'#fff',
    seColor:'#000000',
    ignoreFieldArray: [] as string[],
    globalChangesList: [] as string[],
  },
}) => {
  const api_url = import.meta.env.VITE_API_URL;

  let handleGlobalStyling = () => {
    let data = {
      linkColor: globalStyling.linkColor,
      links: links,
    };
    
    if (globalStyling.status) {
      if (globalStyling?.ignoreFieldArray?.length === 0) {
        if (globalStyling?.globalChangesList.includes('linkColor')) {
          data.linkColor = globalStyling.linkColor;
          // linkColor = globalStyling.linkColor;
        }
      } else {
        if (globalStyling?.ignoreFieldArray?.includes('linkColor')) {
          data.linkColor = linkColor;
          // linkColor = linkColor;
        } else {
          data.linkColor = globalStyling.linkColor;
        }
      }
      if (globalStyling?.ignoreFieldArray?.length === 0) {
        if (globalStyling?.globalChangesList.includes('pmColor')) {
          data.links.filter((item) => {
            item.bgColor = globalStyling.pmColor;
          });
          // linkColor = globalStyling.linkColor;
        }
      } else {
        data.links = links.filter((item, index) => {
          if (
            globalStyling?.ignoreFieldArray?.includes(
              `links-${id}-${index}-bgColor`,
            )
          ) {
            item.bgColor = item.bgColor;
            return item;
          } else {
            item.bgColor = globalStyling.pmColor;
            return item;
          }
        });
      }
      if (globalStyling?.ignoreFieldArray?.length === 0) {
        if (globalStyling?.globalChangesList.includes('seColor')) {
          data.links.filter((item) => {
            item.fgColor = globalStyling.seColor;
          });
        }
      } else {
        data.links = links.filter((item, index) => {
          if (
            globalStyling?.ignoreFieldArray?.includes(
              `links-${id}-${index}-fgColor`,
            )
          ) {
            item.fgColor = item.fgColor;
            return item
          }else{
            item.fgColor = globalStyling.seColor;
            return item
          }
        });
      }
    } else {
      if (globalStyling?.ignoreFieldArray?.includes('linkColor')) {
        data.linkColor = linkColor;
      }
      data.links = links.filter((item, index) => {
        if (
          globalStyling?.ignoreFieldArray?.includes(
            `links-${id}-${index}-bgColor`,
          )
        ) {
          item.bgColor = item.bgColor;
          return item;
        } else {
          return item;
        }
      });
      data.links = links.filter((item, index) => {
        if (
          globalStyling?.ignoreFieldArray?.includes(
            `links-${id}-${index}-fgColor`,
          )
        ) {
          item.fgColor = item.fgColor;
          return item;
        } else {
          return item;
        }
      });
    }
    return data;
  };
  const [globalStylingData, setGlobalStylingData] = useState(() =>
    handleGlobalStyling(),
  );
  useEffect(() => {
    let data = handleGlobalStyling();
    setGlobalStylingData(data);
  }, [globalStyling]);

  return (
<div
  className={`flex ${linkAlignment} py-4 flex-wrap flex-col bg-[${linkColor}] shadow-md rounded-lg p-4 m-1`}
  style={{backgroundColor:linkColor}}
>
  {globalStylingData.links.map((link, index) => (
    <div key={index} className={`flex items-center mb-4`}>
      {/* <a href={link.url} target="_blank" className="inline-block"> */}
      <div
        className={`flex justify-center items-center w-10 h-10 ${linkShape} bg-gray-100 mr-2`}
      >
        {link.iconType === 'local' && (
          <Icon
            icon={link.iconName}
            className={`w-[26px] h-[26px] ${linkShape}`}
            color={link.fgColor}
            style={{ backgroundColor: link.bgColor }}
          />
        )}
        {link.iconType === 'browse' && (
          <SocialIcon
            url={link.iconName}
            bgColor={link.bgColor}
            fgColor={link.fgColor}
            style={{ width: '40px', height: '40px' }}
            as="div"
          />
        )}
        {link.iconType === 'image' && (
          <img
            src={`${api_url}/public/cards/images/${link.iconName}`}
            alt={link.name}
            className={`w-10 h-10 rounded-full object-cover`}
          />
        )}
      </div>
      <p
        className="text-left"
        style={{ color: globalStylingData.linkColor }}
      >
        {link.url}
      </p>
      {/* </a> */}
    </div>
  ))}
</div>


  );
};

export default iconText;
