import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import '../../../css/Style.css';
const Videos = ({
  videoUrls = ['https://youtu.be/SBGdvxi2JmU?si=2x_x9H88VWB8iiYf'],
  videosPadding = [0, 0, 0, 0],
  videosMargin = [0, 0, 0, 0],
}) => {
  const getEmbedUrl = (url: any) => {
    const youtubeRegex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const vimeoRegex =
      /(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/(?:\w+\/)?|album\/(?:\d+\/)?|video\/|)(\d+)(?:$|\/|\?)/;
    const youtubeMatch = url.match(youtubeRegex);
    if (youtubeMatch && youtubeMatch[1]) {
      return `https://www.youtube.com/embed/${youtubeMatch[1]}`;
    }
    const vimeoMatch = url.match(vimeoRegex);
    if (vimeoMatch && vimeoMatch[1]) {
      return `https://player.vimeo.com/video/${vimeoMatch[1]}`;
    }
    return url; // Fallback to the original URL for non-YouTube and non-Vimeo videos
  };
  return (
    <div className="my-4 flex flex-col">
      <div
        style={{
          padding: `${videosPadding?.[0]}px ${videosPadding?.[1]}px ${videosPadding?.[2]}px ${videosPadding?.[3]}px`,
          margin: `${videosMargin?.[0]}px ${videosMargin?.[1]}px ${videosMargin?.[2]}px ${videosMargin?.[3]}px`,
        }}
      >
        {videoUrls.length > 1 ? (
          <>
            <Carousel
              className="custom-carousel"
              showArrows={false}
              showThumbs={false}
              showStatus={false}
              infiniteLoop={true}
              autoPlay={true}
              interval={3000}
            >
              {videoUrls.map((videoUrl, index) => (
                <div key={index} className="mb-4 w-full max-w-lg">
                  {getEmbedUrl(videoUrl) ? (
                    <iframe
                      width="100%"
                      height="180px"
                      src={
                        videoUrl
                          ? getEmbedUrl(videoUrl)
                          : getEmbedUrl(
                              'https://youtu.be/MLpWrANjFbI?si=LQZEZONCjEEZNiaP',
                            )
                      }
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title={`Embedded video ${index + 1}`}
                    ></iframe>
                  ) : (
                    <video width="100%" controls>
                      <source src={videoUrl} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  )}
                </div>
              ))}
            </Carousel>
          </>
        ) : (
          <>
            {videoUrls.map((videoUrl, index) => (
              <div key={index} className="w-full max-w-lg">
                {getEmbedUrl(videoUrl) ? (
                  <iframe
                    width="100%"
                    height="180px"
                    src={getEmbedUrl(videoUrl)}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title={`Embedded video ${index + 1}`}
                  ></iframe>
                ) : (
                  <video width="100%" controls>
                    <source src={videoUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )}
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};
export default Videos;
