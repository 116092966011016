import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import './css/Style.css';
import './css/Satoshi.css';
// import 'jsvectormap/dist/css/jsvectormap.css';
import 'flatpickr/dist/flatpickr.min.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthProvider } from './Authcontext';
import { Provider } from 'react-redux';
import store from '../src/store/Store';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { isTouchDevice } from './utils/Istouchdevice';

const googleClientId = import.meta.env.VITE_GOOGLE_CLIENT_ID;
const backend = isTouchDevice() ? TouchBackend : HTML5Backend;


ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={googleClientId}>
      <DndProvider backend={backend}>
        <AuthProvider>
          <Provider store={store}>
            {' '}
            {/* Wrap with Provider */}
            <Router>
              <App />
            </Router>
          </Provider>
        </AuthProvider>
      </DndProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>,
);
