// PreviewModal.tsx
import React from 'react';
import themeData from './Themedata';
// Define the structure of options and globalStyling
interface GlobalStyling {
  textColor?: any;
  fontColor?: any;
  linkColor?: any;
  fontSize?: string;
  status?: boolean;
  groundColor?: string;
  ignoreFieldArray?: any;
  globalChangesList?: any;
  buttonPadding: number;
  buttonMargin: number;
  buttonRadius: number;
  pmColor: string;
  seColor: string;
}
interface CustomizationOptions {
  backgroundImage?: { default: string };
  fname?: { default: string };
  lname?: { default: string };
  companyLogo?: { default: string };
  profileImage?: { default: string };
  jobTitles?: { default: string[] };
  companyName?: { default: string };
  hideCompanyLogo?: { default: boolean };
  nameFontSize?: any;
  companyNameFontSize?: any;
  jobTitleFontSize?: any;
  fontColor?: any;
  linkColor?: any;
  companyNameTextColor?: { default: string };
  globalStyling?: GlobalStyling;
  [key: string]: any; // Allow for additional properties
}
// Define the structure of the item
interface Item {
  componentName: string;
  id: string;
  customizationOptions: CustomizationOptions;
  boxCount: number;
  boxData: any;
}
// Define the structure of globalCardStyle
interface GlobalCardStyle {
  textColor?: string;
  linkColor?: any;
  fontSize?: any;
  groundColor?: string;
  fontColor?: any;
  backgroundColor: string;
  font: string;
}
// Helper function to get component props based on the component type
const getComponentProps = (
  item: Item,
  font: string = 'poppins, font-poppins',
) => {
  const options = item.customizationOptions;
  switch (item.componentName) {
    case 'Profile':
      return {
        backgroundImage: options.backgroundImage?.default,
        fname: options.fname?.default,
        lname: options.lname?.default,
        companyLogo: options.companyLogo?.default,
        profileImage: options.profileImage?.default,
        jobTitles: options.jobTitles?.default,
        companyName: options.companyName?.default,
        hideCompanyLogo: options.hideCompanyLogo?.default,
        fontColor: options?.fontColor?.default || '#ffffff',
        linkColor: options?.linkColor?.default || '#ffffff',
        companyNameTextColor:
          options?.companyNameTextColor?.default || '#ca8a04',
        globalStyling: {
          fontColor: options?.globalStyling?.fontColor,
          linkColor: options?.globalStyling?.linkColor,
          fontSize: options?.globalStyling?.fontSize,
          status: options?.globalStyling?.status,
          ignoreFieldArray: options?.globalStyling?.ignoreFieldArray,
          globalChangesList: options?.globalStyling?.globalChangesList,
        },
        typography: {
          type: options?.typography?.type?.default,
          family: options?.typography?.family?.default,
          nameFontSize: options?.typography?.nameFontSize?.default,
          typographyWeight: options?.typography?.typographyWeight?.default,
          typographyStyle: options?.typography?.typographyStyle?.default,
          lineHeight: options.typography.lineHeight.default,
        },
        companyTypography: {
          type: options?.companyTypography?.type?.default,
          family: options?.companyTypography?.family?.default,
          nameFontSize: options?.companyTypography?.nameFontSize?.default,
          companyTypographyWeight:
            options?.companyTypography?.companyTypographyWeight?.default,
          companyTypographyStyle:
            options?.companyTypography?.companyTypographyStyle?.default,
          lineHeight: options.companyTypography.lineHeight.default,
        },
        jobTypography: {
          type: options?.jobTypography?.type?.default,
          family: options?.jobTypography?.family?.default,
          nameFontSize: options?.jobTypography?.nameFontSize?.default,
          jobTypographyWeight:
            options?.jobTypography?.jobTypographyWeight?.default,
          jobTypographyStyle:
            options?.jobTypography?.jobTypographyStyle?.default,
          lineHeight: options.jobTypography.lineHeight.default,
        },
      };
    case 'Text Editor':
      return {
        textContent: options.textContent.default,
        hideText: options.hideText.default,
        fontColor: options.fontColor.default,
        textUsTypography: {
          type: options?.textUsTypography?.type?.default,
          family: options?.textUsTypography?.family?.default,
          nameFontSize: options?.textUsTypography?.nameFontSize?.default,
          typographyWeight:
            options?.textUsTypography?.typographyWeight?.default,
          typographyStyle: options?.textUsTypography?.typographyStyle?.default,
          lineHeight: options.textUsTypography.lineHeight.default,
        },
        textUsPadding: options.textUsPadding.default || [10, 10, 10, 10],
        textUsMargin: options.textUsMargin.default || [0, 0, 0, 0],
        globalStyling: {
          fontColor: options?.globalStyling?.fontColor,
          linkColor: options?.globalStyling?.linkColor,
          fontSize: options?.globalStyling?.fontSize,
          status: options?.globalStyling?.status,
          ignoreFieldArray: options?.globalStyling?.ignoreFieldArray,
          globalChangesList: options?.globalStyling?.globalChangesList,
        },
      };
    case 'Company':
      return {
        companyName: options.companyName?.default,
        CompanyLogo: options.CompanyLogo.default,
        role: options.role.default,
        layout: options.layout.default,
        textColor: options?.textColor?.default || '#000000',
        companyPadding: options.companyPadding.default || [0, 0, 0, 0],
        companyMargin: options.companyMargin.default || [0, 0, 0, 0],
        globalStyling: {
          fontColor: options?.globalStyling?.fontColor,
          linkColor: options?.globalStyling?.linkColor,
          fontSize: options?.globalStyling?.fontSize,
          status: options?.globalStyling?.status,
          ignoreFieldArray: options?.globalStyling?.ignoreFieldArray,
          globalChangesList: options?.globalStyling?.globalChangesList,
        },
      };
    case 'Social':
      return {
        id: item.id,
        links: options.links.default,
        linkColor: options?.linkColor?.default || '#ffffff',
        linkShape: options?.linkShape.default,
        linkAlignment: options?.linkAlignment.default,
        linkSpacing: options?.linkSpacing.default,
        borderType: options?.borderType.default,
        borderWidth: options?.borderWidth.default,
        borderColor: options?.borderColor.default,
        borderRadius: options?.borderRadius.default,
        globalStyling: {
          fontColor: options?.globalStyling?.fontColor,
          linkColor: options?.globalStyling?.linkColor,
          fontSize: options?.globalStyling?.fontSize,
          pmColor: options?.globalStyling?.pmColor,
          seColor: options?.globalStyling?.seColor,
          status: options?.globalStyling?.status,
          ignoreFieldArray: options?.globalStyling?.ignoreFieldArray,
          globalChangesList: options?.globalStyling?.globalChangesList,
        },
      };
      case 'Icon Text':
        return {
          id: item.id,
          links: options.IconTextLinks.default,
          linkColor: options?.IconTextLinkColor?.default || '#ffffff',
          linkShape: options?.IconTextLinkShape.default,
          linkAlignment: options?.IconTextLinkAlignment.default,
          globalStyling: {
            fontColor: options?.globalStyling?.fontColor,
            linkColor: options?.globalStyling?.linkColor,
            fontSize: options?.globalStyling?.fontSize,
            pmColor: options?.globalStyling?.pmColor,
            seColor: options?.globalStyling?.seColor,
            status: options?.globalStyling?.status,
            ignoreFieldArray: options?.globalStyling?.ignoreFieldArray,
            globalChangesList: options?.globalStyling?.globalChangesList,
          },
        };
    case 'Carousel':
      return {
        images: options.imagesCarousel.default,
        autoplay: options.autoplay.default,
        interval: options.interval.default,
        imageResoluation: options?.imageResoluation.default,
        navigation: options?.navigation.default,
        borderType: options?.borderType.default,
        borderWidth: options?.borderWidth.default || [0, 0, 0, 0],
        borderRadius: options?.borderRadius.default || [0, 0, 0, 0],
        carouselPadding: options.carouselPadding.default || [0, 0, 0, 0],
        carouselMargin: options.carouselMargin.default || [0, 0, 0, 0],
      };
    case 'Button':
      return {
        bgcolor: options.backgroundColor.default,
        name: options.name.default,
        url: options.url.default,
        fontColor: options.fontColor.default,
        alignItem: options.alignItem.default,
        full_width: options.full_width.default,
        buttonPadding: options.buttonPadding.default || [10, 10, 10, 10],
        buttonMargin: options.buttonMargin.default || [0, 0, 0, 0],
        buttonRadius: options.buttonRadius.default || [0, 0, 0, 0],
        buttonTypography: {
          type: options?.buttonTypography?.type?.default,
          family: options?.buttonTypography?.family?.default,
          nameFontSize: options?.buttonTypography?.nameFontSize?.default,
          typographyWeight:
            options?.buttonTypography?.typographyWeight?.default,
          typographyStyle: options?.buttonTypography?.typographyStyle?.default,
        },
        globalStyling: {
          fontColor: options?.globalStyling?.fontColor,
          linkColor: options?.globalStyling?.linkColor,
          fontSize: options?.globalStyling?.fontSize,
          groundColor: options?.globalStyling?.groundColor,
          status: options?.globalStyling?.status,
          ignoreFieldArray: options?.globalStyling?.ignoreFieldArray,
          globalChangesList: options?.globalStyling?.globalChangesList,
          buttonPadding: options?.globalStyling?.buttonPadding,
          buttonMargin: options?.globalStyling?.buttonMargin,
          buttonRadius: options?.globalStyling?.buttonRadius,
        },
      };
    case 'About Us':
      return {
        textContent: options.textContent.default,
        hideText: options.hideText.default,
        fontColor: options.fontColor.default,
        aboutUsTypography: {
          type: options?.aboutUsTypography?.type?.default,
          family: options?.aboutUsTypography?.family?.default,
          nameFontSize: options?.aboutUsTypography?.nameFontSize?.default,
          typographyWeight:
            options?.aboutUsTypography?.typographyWeight?.default,
          typographyStyle: options?.aboutUsTypography?.typographyStyle?.default,
          lineHeight: options.aboutUsTypography.lineHeight.default,
        },
        aboutUsPadding: options.aboutUsPadding.default || [10, 10, 10, 10],
        aboutUsMargin: options.aboutUsMargin.default || [0, 0, 0, 0],
        globalStyling: {
          fontColor: options?.globalStyling?.fontColor,
          linkColor: options?.globalStyling?.linkColor,
          fontSize: options?.globalStyling?.fontSize,
          status: options?.globalStyling?.status,
          ignoreFieldArray: options?.globalStyling?.ignoreFieldArray,
          globalChangesList: options?.globalStyling?.globalChangesList,
        },
      };
    case 'Heading':
      return {
        textContent: options.textContent.default,
        hideText: options.hideText.default,
        fontColor: options.fontColor.default,
        textPosition: options.textPosition.default,
        headingTypography: {
          type: options?.headingTypography?.type?.default,
          family: options?.headingTypography?.family?.default,
          nameFontSize: options?.headingTypography?.nameFontSize?.default,
          typographyWeight:
            options?.headingTypography?.typographyWeight?.default,
          typographyStyle: options?.headingTypography?.typographyStyle?.default,
          lineHeight: options.headingTypography.lineHeight.default,
        },
        headingPadding: options.headingPadding.default || [10, 10, 10, 10],
        headingMargin: options.headingMargin.default || [0, 0, 0, 0],
        globalStyling: {
          fontColor: options?.globalStyling?.fontColor,
          linkColor: options?.globalStyling?.linkColor,
          fontSize: options?.globalStyling?.fontSize,
          status: options?.globalStyling?.status,
          ignoreFieldArray: options?.globalStyling?.ignoreFieldArray,
          globalChangesList: options?.globalStyling?.globalChangesList,
        },
      };
    case 'Testimonial':
      return {
        Testimonial: options.Testimonial.default,
        fontColor: options.fontColor.default,
        namefontColor: options.namefontColor.default,
        testimonialTypography: {
          type: options?.testimonialTypography?.type?.default,
          family: options?.testimonialTypography?.family?.default,
          nameFontSize: options?.testimonialTypography?.nameFontSize?.default,
          typographyWeight:
            options?.testimonialTypography?.typographyWeight?.default,
          typographyStyle:
            options?.testimonialTypography?.typographyStyle?.default,
        },
        testimonialPadding: options.testimonialPadding.default || [0, 0, 0, 0],
        testimonialMargin: options.testimonialMargin.default || [0, 0, 0, 0],
        globalStyling: {
          fontColor: options?.globalStyling?.fontColor,
          linkColor: options?.globalStyling?.linkColor,
          fontSize: options?.globalStyling?.fontSize,
          status: options?.globalStyling?.status,
          ignoreFieldArray: options?.globalStyling?.ignoreFieldArray,
          globalChangesList: options?.globalStyling?.globalChangesList,
        },
      };
    case 'Videos':
      return {
        videoUrls: options.videoUrls.default,
        videosPadding: options.videosPadding.default || [0, 0, 0, 0],
        videosMargin: options.videosMargin.default || [0, 0, 0, 0],
      };
    case 'Divider':
      return {
        id: item.id,
        dividerBorderStyle: options.dividerBorderStyle.default,
        dividerWidth: options.dividerWidth.default,
        dividerPosition: options.dividerPosition.default,
        icon: options.icon.default,
        borderColor: options.borderColor.default,
        globalStyling: {
          pmColor: options?.globalStyling?.pmColor,
          seColor: options?.globalStyling?.seColor,
          status: options?.globalStyling?.status,
          ignoreFieldArray: options?.globalStyling?.ignoreFieldArray,
          globalChangesList: options?.globalStyling?.globalChangesList,
        },
      };
    case 'Image':
      return {
        image: options.image.default,
        imageResoluation: options.imageResoluation.default,
        caption: options.caption.default,
        hideCaption: options.hideCaption.default,
        link: options?.link?.default,
        hideLink: options?.hideLink?.default,
        imagePosition: options?.imagePosition?.default,
        imageTypography: {
          type: options?.imageTypography?.type?.default,
          family: options?.imageTypography?.family?.default,
          nameFontSize: options?.imageTypography?.nameFontSize?.default,
          typographyWeight: options?.imageTypography?.typographyWeight?.default,
          typographyStyle: options?.imageTypography?.typographyStyle?.default,
          lineHeight: options.imageTypography.lineHeight.default,
        },
        imagePadding: options.imagePadding.default || [10, 10, 10, 10],
        imageMargin: options.imageMargin.default || [0, 0, 0, 0],
        imageBoxWidth: options.imageBoxWidth.default || 0,
        globalStyling: {
          fontColor: options?.globalStyling?.fontColor,
          linkColor: options?.globalStyling?.linkColor,
          fontSize: options?.globalStyling?.fontSize,
          status: options?.globalStyling?.status,
          ignoreFieldArray: options?.globalStyling?.ignoreFieldArray,
          globalChangesList: options?.globalStyling?.globalChangesList,
        },
      };
    case 'Grid':
      return {
        id: item.id,
        boxCount: item.boxCount,
        boxData: item.boxData,
        gridColumns: options.gridColumns.default || 'grid-cols-2',
        gridBorderStyle: options.gridBorderStyle.default || 'border-dashed',
        boxWidth: options.boxWidth.default || 0,
        boxHeight: options.boxHeight.default || 0,
        boxSpacing: options.boxSpacing.default,
        globalStyling: {
          fontColor: options?.globalStyling?.fontColor,
          linkColor: options?.globalStyling?.linkColor,
          fontSize: options?.globalStyling?.fontSize,
          status: options?.globalStyling?.status,
          ignoreFieldArray: options?.globalStyling?.ignoreFieldArray,
          globalChangesList: options?.globalStyling?.globalChangesList,
          font: font,
        },
      };
    case 'Flip Box':
      return {
        id: item.id,
        icon: options?.icon?.default,
        frontTitle: options?.frontTitle?.default || 'This is the heading',
        frontDescription:
          options?.frontDescription?.default || 'This is descriptoion',
        frontBackgroundColor:
          options?.frontBackgroundColor?.default || '#DED9DD',
        backBackgroundColor: options?.backBackgroundColor?.default || '#dfc749',
        backTitle: options?.backTitle?.default || 'This is the heading',
        backDescription:
          options?.backDescription?.default || 'This is description',
        buttonText: options?.buttonText?.default || 'Click Here',
        buttonUrl: options?.buttonUrl?.default || 'http://example.com/1',
        flipInterval: options?.flipInterval?.default || 3000,
        flipBoxHeight: options?.flipBoxHeight?.default || '200',
        borderRadius: options?.borderRadius?.default || '0',
        frontPadding: options?.frontPadding?.default || [0, 0, 0, 0],
        frontAlignment: options?.frontAlignment?.default || 'justify-center',
        frontPosition: options?.frontPosition?.default || 'center',
        frontIconSpacing: options?.frontIconSpacing?.default || '0',
        frontIconSize: options?.frontIconSize?.default || '60',
        frontIconRotate: options?.frontIconRotate?.default || '0',
        frontTitleSpacing: options?.frontTitleSpacing?.default || '0',
        frontTitleTextColor: options?.frontTitleTextColor?.default || '#594255',
        frontTextTypography: {
          type: options?.frontTextTypography?.type?.default,
          family: options?.frontTextTypography?.family?.default,
          nameFontSize: options?.frontTextTypography?.nameFontSize?.default,
          typographyWeight:
            options?.frontTextTypography?.typographyWeight?.default,
          typographyStyle:
            options?.frontTextTypography?.typographyStyle?.default,
          lineHeight: options.frontTextTypography.lineHeight.default,
        },
        frontDescriptionTextColor:
          options?.frontDescriptionTextColor?.default || '#594255',
        frontDescriptionTypography: {
          type: options?.frontDescriptionTypography?.type?.default,
          family: options?.frontDescriptionTypography?.family?.default,
          nameFontSize:
            options?.frontDescriptionTypography?.nameFontSize?.default,
          typographyWeight:
            options?.frontDescriptionTypography?.typographyWeight?.default,
          typographyStyle:
            options?.frontDescriptionTypography?.typographyStyle?.default,
          lineHeight: options.frontDescriptionTypography.lineHeight.default,
        },
        backPadding: options?.backPadding?.default || [0, 0, 0, 0],
        backAlignment: options?.backAlignment?.default || 'justify-center',
        backPosition: options?.backPosition?.default || 'center',
        backTextHr: options?.backTextHr || '',
        backTextHeading: options?.backTextHeading?.label || 'Title',
        backTitleSpacing: options?.backTitleSpacing?.default || '0',
        backTitleTextColor: options?.backTitleTextColor?.default || '#594255',
        backTextTypography: {
          type: options?.backTextTypography?.type?.default,
          family: options?.backTextTypography?.family?.default,
          nameFontSize: options?.backTextTypography?.nameFontSize?.default,
          typographyWeight:
            options?.backTextTypography?.typographyWeight?.default,
          typographyStyle:
            options?.backTextTypography?.typographyStyle?.default,
          lineHeight: options.backTextTypography.lineHeight.default,
        },
        backDescriptionHr: options?.backTextHr || '',
        backDescriptionHeading:
          options?.backTextHeading?.label || 'Description',
        backDescriptionTextColor:
          options?.backDescriptionTextColor?.default || '#594255',
        backDescriptionSpacing: options?.backDescriptionSpacing?.default || '0',
        backDescriptionTypography: {
          type: options?.backDescriptionTypography?.type?.default,
          family: options?.backDescriptionTypography?.family?.default,
          nameFontSize:
            options?.backDescriptionTypography?.nameFontSize?.default,
          typographyWeight:
            options?.backDescriptionTypography?.typographyWeight?.default,
          typographyStyle:
            options?.backDescriptionTypography?.typographyStyle?.default,
          lineHeight: options.backDescriptionTypography.lineHeight.default,
        },
        backButtonSize: options?.backButtonSize?.default || 'small',
        backButtonTypography: {
          type: options?.backButtonTypography?.type?.default,
          family: options?.backButtonTypography?.family?.default,
          nameFontSize: options?.backButtonTypography?.nameFontSize?.default,
          typographyWeight:
            options?.backButtonTypography?.typographyWeight?.default,
          typographyStyle:
            options?.backButtonTypography?.typographyStyle?.default,
          lineHeight: options.backDescriptionTypography.lineHeight.default,
        },
        backButtonfontColor: options?.backButtonfontColor?.default || '#594255',
        backButtonBackgroundColor:
          options?.backButtonBackgroundColor?.default || '#ffff',
        backButtonBorderColor:
          options?.backButtonBorderColor?.default || '#594255',
        backButtonBorderWidth: options?.backButtonBorderWidth?.default || '1',
        backButtonBorderRadius: options?.backButtonBorderRadius?.default || '0',
        globalStyling: {
          pmColor: options?.globalStyling?.pmColor,
          seColor: options?.globalStyling?.seColor,
          status: options?.globalStyling?.status,
          groundColor: options?.globalStyling?.groundColor,
          fontColor: options?.globalStyling?.fontColor,
          textColor: options?.globalStyling?.textColor,
          ignoreFieldArray: options?.globalStyling?.ignoreFieldArray,
          globalChangesList: options?.globalStyling?.globalChangesList,
        },
      };
    case 'Form':
      return {
        id: item.id,
        fields: options?.fields?.default,
        labelShow: options?.labelShow?.default,
        buttonSize: options?.buttonSize?.default || 'px-4 py-6',
        buttonWidth: options?.buttonWidth?.default || '100',
        buttonLabel: options?.buttonLabel?.default || 'Submit',
        icon: options?.icon?.default,
        iconPosition: options?.iconPosition?.default || '',
        iconSpacing: options?.iconSpacing?.default || '',
        rowGap: options?.rowGap?.default || '0',
        labelSpacing: options?.labelSpacing?.default || '0',
        labelTextColor: options?.labelTextColor?.default || '#594255',
        labelTextTypography: {
          type: options?.labelTextTypography?.type?.default,
          family: options?.labelTextTypography?.family?.default,
          nameFontSize: options?.labelTextTypography?.nameFontSize?.default,
          typographyWeight:
            options?.labelTextTypography?.typographyWeight?.default,
          typographyStyle:
            options?.labelTextTypography?.typographyStyle?.default,
          lineHeight: options.labelTextTypography.lineHeight.default,
        },
        fieldTextColor: options?.fieldTextColor?.default || '#594255',
        fieldTextTypography: {
          type: options?.fieldTextTypography?.type?.default,
          family: options?.fieldTextTypography?.family?.default,
          nameFontSize: options?.fieldTextTypography?.nameFontSize?.default,
          typographyWeight:
            options?.fieldTextTypography?.typographyWeight?.default,
          typographyStyle:
            options?.fieldTextTypography?.typographyStyle?.default,
          lineHeight: options.fieldTextTypography.lineHeight.default,
        },
        buttonPosition: options?.buttonPosition?.default || 'justify-center',
        buttonAlignmenrt:
          options?.buttonAlignmenrt?.default || 'justify-center',
        buttonBackgroundColor:
          options?.buttonBackgroundColor?.default || '#dfc749',
        buttonFontColor: options?.buttonFontColor?.default || '#ffff',
        borderType: options?.borderType?.default || 'none',
        borderColor: options?.borderColor?.default || '#fffff',
        borderWidth: options?.borderWidth?.default || [0, 0, 0, 0],
        borderRadius: options?.borderRadius?.default || [0, 0, 0, 0],
        globalStyling: {
          pmColor: options?.globalStyling?.pmColor,
          seColor: options?.globalStyling?.seColor,
          textColor: options?.globalStyling?.textColor,
          fontColor: options?.globalStyling?.fontColor,
          fontSize: options?.globalStyling?.fontSize,
          groundColor: options?.globalStyling?.groundColor,
          status: options?.globalStyling?.status,
          ignoreFieldArray: options?.globalStyling?.ignoreFieldArray,
          globalChangesList: options?.globalStyling?.globalChangesList,
        },
      };
    default:
      return {};
  }
};
// Define the props for CanvasItem component
interface CanvasItemProps {
  item: Item;
  globalCardStyle: GlobalCardStyle;
}
const CanvasItem: React.FC<CanvasItemProps> = ({ item, globalCardStyle }) => {
  const Component = themeData.theme1s.find(
    (component) => component.componentName === item.componentName,
  )?.component;
  if (!Component) {
    console.error(
      `Component for type '${item.componentName}' not found in themeData.`,
    );
    return null;
  }
  const componentProps: any = getComponentProps(
    item,
    globalCardStyle?.font || 'poppins, font-poppins',
  );
  return (
    <div className="mb-2.5">
      <Component {...componentProps} />
    </div>
  );
};
interface PreviewModalProps {
  isOpen: boolean;
  items: Item[];
  globalCardStyle: GlobalCardStyle;
}
const PreviewCard: React.FC<PreviewModalProps> = ({
  isOpen,
  items,
  globalCardStyle,
}) => {
  if (!isOpen) return null;
  return (
    <div className="pointer-events-none">
      <div className="relative rounded-lg w-[500px] max-w-[100%] max-h-[90%] slideInFromTop-animate">
        <div
          className={`${globalCardStyle?.font || 'poppins, font-poppins'}`}
          style={{
            backgroundColor: globalCardStyle?.backgroundColor || '#fff',
          }}
        >
          {items.length > 0 ? (
            items.map((item, index) => (
              <CanvasItem
                key={index}
                item={item}
                globalCardStyle={globalCardStyle}
              />
            ))
          ) : (
            <p className="bg-purpletacx text-white font-poppins text-center p-10">
              Nothing to preview drag and drop block or section to preview
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
export default PreviewCard;
