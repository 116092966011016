import { useEffect, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import MyIcon from '../icons/Myicon';

const Testimonial = ({
  Testimonial = [
    {
      comment: 'Welcome to our website! We are glad to have you here.',
      name: 'Name',
    },
  ],
  fontColor = '#ffff',
  namefontColor = '#594255',
  testimonialTypography = {
    type: 'editor',
    family: 'poppins, font-poppins',
    nameFontSize: 'text-xl',
    typographyWeight: 'font-[600]',
    typographyStyle: 'normal',
  },
  testimonialPadding = [0, 0, 0, 0],
  testimonialMargin = [0, 0, 0, 0],
  globalStyling = {
    fontColor: '#000000',
    linkColor: '#000000',
    fontSize: 'text-xl',
    status: false,
    ignoreFieldArray: [] as string[],
    globalChangesList: [] as string[],
  },
}) => {
  let handleGlobalStyling = () => {
    let data = {
      namefontColor: globalStyling.fontColor,
      fontColor: globalStyling.fontColor,
      textFontSize: globalStyling.fontSize,
    };
    if (globalStyling.status) {
      if (globalStyling?.ignoreFieldArray?.length === 0) {
        if (globalStyling?.globalChangesList.includes('textColor')) {
          data.namefontColor = globalStyling.fontColor;
        }
      } else {
        if (globalStyling?.ignoreFieldArray?.includes('namefontColor')) {
          data.namefontColor = namefontColor;
        } else {
          data.namefontColor = globalStyling.fontColor;
        }
      }
      if (globalStyling?.ignoreFieldArray?.length === 0) {
        if (globalStyling?.globalChangesList.includes('textColor')) {
          data.fontColor = globalStyling.fontColor;
        }
      } else {
        if (globalStyling?.ignoreFieldArray?.includes('fontColor')) {
          data.fontColor = fontColor;
        } else {
          data.fontColor = globalStyling.fontColor;
        }
      }
      if (globalStyling?.ignoreFieldArray?.length === 0) {
        if (globalStyling?.globalChangesList.includes('fontSize')) {
          data.textFontSize = globalStyling.fontSize;
        }
      } else {
        if (
          globalStyling?.ignoreFieldArray?.includes(
            ' testimonialTypography.nameFontSize',
          )
        ) {
          data.textFontSize = testimonialTypography.nameFontSize;
        } else {
          data.textFontSize = globalStyling.fontSize;
        }
      }
    } else {
      if (globalStyling?.ignoreFieldArray?.includes('namefontColor')) {
        data.namefontColor = namefontColor;
      }
      if (globalStyling?.ignoreFieldArray?.includes('fontColor')) {
        data.fontColor = fontColor;
      }
      if (
        globalStyling?.ignoreFieldArray?.includes(
          'testimonialTypography.nameFontSize',
        )
      ) {
        data.textFontSize = testimonialTypography.nameFontSize;
      }
    }
    return data;
  };
  const [globalStylingData, setGlobalStylingData] = useState<any>(() =>
    handleGlobalStyling(),
  );
  useEffect(() => {
    let data = handleGlobalStyling();
    setGlobalStylingData(data);
  }, [globalStyling]);
  return (
    <div className={`flex flex-col p-3`}>
      <div
        style={{
          padding: `${testimonialPadding?.[0]}px ${testimonialPadding?.[1]}px ${testimonialPadding?.[2]}px ${testimonialPadding?.[3]}px`,
          margin: `${testimonialMargin?.[0]}px ${testimonialMargin?.[1]}px ${testimonialMargin?.[2]}px ${testimonialMargin?.[3]}px`,
        }}
      >
        <Carousel
          showArrows={false}
          showThumbs={false}
          showStatus={false}
          infiniteLoop={true}
          showIndicators={Testimonial.length > 1}
          autoPlay={true}
          interval={3000}
        >
          {Testimonial.map((testimonial, index) => {
            return (
              <div key={index}>
                <div className="bg-[#594255] rounded-2xl p-3 ">
                  <div
                    className={`p-2 ${testimonialTypography.typographyWeight} relative break-words overflow-y-auto scrollbar-custom h-32 text-center`}
                    style={{
                      color: globalStylingData?.fontColor,
                      fontFamily: testimonialTypography.family,
                      fontStyle: testimonialTypography.typographyStyle,
                      fontSize: globalStylingData.textFontSize,
                    }}
                  >
                    <span>
                      <MyIcon iconName="qoute" />
                    </span>
                    {testimonial.comment}
                  </div>
                  <svg
                    className="w-7 h-7 text-gray-800 dark:text-white absolute right-[45%] "
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="#594255"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fillRule="evenodd"
                      d="M18.425 10.271C19.499 8.967 18.57 7 16.88 7H7.12c-1.69 0-2.618 1.967-1.544 3.271l4.881 5.927a2 2 0 0 0 3.088 0l4.88-5.927Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <p
                  className={`mt-4 text-center font-semibold test-base break-words`}
                  style={{
                    color: globalStylingData?.namefontColor,
                  }}
                >
                  {testimonial.name}
                </p>
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};
export default Testimonial;
