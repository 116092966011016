import React, { useState, useEffect } from 'react';
import { FaSearch } from 'react-icons/fa';
import Select from 'react-select';
import MyIcon from '../../../components/cardeditor/icons/Myicon';
import Pagination from '../../../components/pagination/Pagination';

/* Dropdown filter  */
const filterOptions = [
  { value: 'all', label: 'All' },
  { value: 'active', label: 'Active' },
  { value: 'inactive', label: 'Inactive' },
  { value: 'pending', label: 'Pending' },
  { value: 'expired', label: 'Expired' },
];

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    borderRadius: '12px',
    padding: '0',
    borderColor: '#ffff',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#ffff',
    },
    fontSize: '14px',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#DFC749' : '#fff',
    color: state.isDisabled ? '#ccc' : state.isSelected ? '#fff' : '#000',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'transperant',
      // color: '#000',
    },
    fontSize: '14px',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: '#000',
  }),
  menu: (provided: any) => ({
    ...provided,
    borderRadius: '10px',
    overflow: 'hidden',
    fontSize: '14px',
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
};

interface User {
  first_name: string;
  last_name: string;
  email: string;
  phone_number?: string;
  company_name: string;
  expiration_date?: string;
  status: 'active' | 'inactive'; // Added status field
}

interface TeamMateUserViewProps {
  isOpen: boolean;
  teamMateDetails: User[] | null; // Changed to an array of User objects
  onClose: () => void;
}

const TeamMateUserView: React.FC<TeamMateUserViewProps> = ({
  isOpen,
  teamMateDetails,
  onClose,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState<
    'active' | 'inactive' | 'all' | 'pending' | 'expired'
  >('all'); // Added status filter state
  const [isDropdownOpen, setDropdownIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4; // Adjust this number as needed

  useEffect(() => {
    if (!isOpen) {
      // Reset search and status filter when modal closes
      setSearchQuery('');
      setStatusFilter('all');
    }
  }, [isOpen]);

  if (!isOpen) return null;

  // Filter team members based on the search query and status
  const filteredTeamMembers = teamMateDetails
    ? teamMateDetails.filter((user) => {
        const searchLower = searchQuery.toLowerCase();
        const fullName = `${user.first_name} ${user.last_name}`.toLowerCase();
        const matchesSearch =
          fullName.includes(searchLower) ||
          user.email.toLowerCase().includes(searchLower) ||
          user?.company_name.toLowerCase().includes(searchLower) ||
          user.status.toLowerCase().includes(searchLower);
        const matchesStatus =
          statusFilter === 'all' || user.status === statusFilter;
        return matchesSearch && matchesStatus;
      })
    : [];

  /* Pagination start */
  const totalItems = filteredTeamMembers.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const paginatedUsers = filteredTeamMembers.slice(startIndex, endIndex);

  const getStatusClassNames = (status: string) => {
    switch (status) {
      case 'expired':
        return 'text-red-500';
      case 'pending':
        return 'text-blue-500';
      case 'active':
        return 'text-green-500 ml-2';
      case 'inactive':
        return 'text-red-500';
      default:
        return 'text-blue-500';
    }
  };

  return (
    <div className="fixed grid content-center top-0 bottom-0 left-0 z-[999999] w-screen h:screen bg-[#59425580] bg-opacity-70 font-poppins fade-in-animate">
      {/* Responsive width based on device size with shadow and increased padding */}
      <div className="bg-white flex flex-col m-auto justify-center rounded-lg p-6 w-full max-w-xs sm:max-w-md md:max-w-xl lg:max-w-2xl xl:max-w-4xl slideInFromTop-animate">
        {/* Flex container for the header and close button */}
        <div className="flex items-center justify-between rounded-t">
          <h2 className="text-lg font-bold text-center text-purpletacx">
            Team Mate Details
          </h2>
          <button onClick={onClose} className="text-[#594255]">
            <MyIcon iconName="bigcross" color="#000" />
          </button>
        </div>

        <div className="mt-4 flex flex-col justify-end sm:flex-row sm:items-center w-full sm:space-x-4">
          {/* Search Bar */}
          <div className="relative w-full xl:w-[40%] sm:w-full mb-4 sm:mb-0">
            <input
              type="text"
              placeholder="Search here..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="p-2 border border-stroke rounded-xl pr-20 w-full bg-white text-gray-800"
            />
            <FaSearch className="absolute right-5 top-1/2 transform -translate-y-1/2 text-gray-500" />
          </div>

          {/* Dropdown for filter options */}
          <div className="relative w-full sm:w-1/3 xl:w-[20%]">
            <Select
              options={filterOptions}
              styles={customStyles}
              placeholder="Actions"
              className="border border-stroke rounded-xl"
              onMenuOpen={() => setDropdownIsOpen(true)}
              onMenuClose={() => setDropdownIsOpen(false)}
              value={filterOptions.find(
                (option) => option.value === statusFilter,
              )}
              onChange={(selectedOption) => {
                if (selectedOption) {
                  setStatusFilter(
                    selectedOption.value as
                      | 'active'
                      | 'inactive'
                      | 'all'
                      | 'pending'
                      | 'expired',
                  );
                }
              }}
              
            />
            <span
              className={`absolute top-0 right-3 h-full flex items-center pointer-events-none transition-transform duration-200 ease-in-out ${
                isDropdownOpen ? 'rotate-180' : 'rotate-0'
              } `}
            >
              <svg
                width="25"
                height="25"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M7 10L12 15L17 10H7Z" fill="#000" />
              </svg>
            </span>
          </div>
        </div>

        <div className="overflow-x-auto animate-fade-in scrollbar-custom mt-4 rounded-lg">
          <table className="min-w-full bg-white rounded-lg">
            <thead>
              <tr className=" bg-[#F7F7F7] text-black text-sm border border-gray rounded-t-lg">
                <th className="py-3 px-3 sm:px-6 text-left font-medium border border-gray">
                  No
                </th>
                <th className="py-3 px-3 sm:px-6 text-left font-medium border border-gray">
                  Full Name
                </th>
                <th className="py-3 px-3 sm:px-6 text-left font-medium border border-gray">
                  Email
                </th>
                <th className="py-3 px-3 sm:px-6 text-left font-medium whitespace-nowrap border border-gray">
                  Company Name
                </th>
                <th className="py-3 px-3 sm:px-6 text-left font-medium border border-gray">
                  Status
                </th>{' '}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {filteredTeamMembers.length > 0 ? (
                paginatedUsers.map((user, index) => (
                  <tr
                    key={index}
                    className="hover:bg-gray-50 transition duration-200 text-sm"
                  >
                    <td className="py-4 px-3 sm:px-6 border border-gray font-normal">
                      {index + 1}
                    </td>
                    <td className="py-4 px-3 sm:px-6 border border-gray font-normal whitespace-nowrap ">{`${user.first_name} ${user.last_name}`}</td>
                    <td className="py-4 px-3 sm:px-6 border border-gray font-normal whitespace-nowrap ">
                      {user.email}
                    </td>
                    <td className="py-4 px-3 sm:px-6 border border-gray font-normal">
                      {user.company_name || 'N/A'}
                    </td>
                    <td
                      className={`py-4 px-3 sm:px-6 border border-gray font-normal text-center ${getStatusClassNames(user.status)}`}
                    >
                      {user.status.charAt(0).toUpperCase() +
                        user.status.slice(1)}{' '}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={5}
                    className={`py-4 px-3 sm:px-6 border border-gray font-normal text-center `}
                  >
                    No team mate found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {teamMateDetails && teamMateDetails.length > 4 && (
            <div className="mt-4">
              <Pagination
                totalPages={totalPages}
                setCurrentPage={setCurrentPage}
                startIndex={startIndex}
                endIndex={endIndex}
                totalItems={totalItems}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TeamMateUserView;
// ) : (
// <div className="flex justify-center items-center h-32">
//   {' '}
//   {/* Center the message */}
//   <p className="text-xl text-gray-700 text-center p-4">
//     {filteredTeamMembers.length === 0
//       ? statusFilter === 'inactive'
//         ? 'No inactive team members found.'
//         : searchQuery
//           ? 'No team members match the search criteria.'
//           : 'No team members available.'
//       : null}
//   </p>
// </div>
// )}
