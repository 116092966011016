import React, { useState } from 'react';
import { cardShareViaSms } from '../../api/Api';
import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

interface SmsShareCardProps {
  url: string;
}

const SmsShareCard: React.FC<SmsShareCardProps> = ({ url }) => {
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(undefined);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);

  // const handleInputChange = (e: any) => {
  //   const value = e.target.value;
  //   setError('');
  //   setPhoneNumber(value);
  // };

  // Function to handle phone number input change
  const handlePhoneNumberChange = (value?: string) => {
    setError('');
    if (value) {
      setPhoneNumber(value);
    } else {
      setPhoneNumber(undefined);
    }
  };

  const handleShareCardSMS = async () => {
    // if (!phoneNumber.trim()) {
    //   setError('Phone number is required');
    //   return;
    // } else if (!/^\d{10}$/.test(phoneNumber)) {
    //   setError('Phone number must be 10 digits');
    //   return;
    // }
    setError('');
    if (phoneNumber == undefined || phoneNumber == '') {
      setError('Phone number is required');
      return;
    } else if (!isValidPhoneNumber(phoneNumber)) {
      setError('Please enter valid phone number');
      return;
    }
    try {
      setLoading(true);
      const dataTosend = {
        to: phoneNumber,
        url,
      };
      const response = await cardShareViaSms(dataTosend);
      if (response?.data.success) {
        setError('');
        setSuccessMessage('SMS sent successfully!');
        setPhoneNumber('');
        setTimeout(() => {
          setSuccessMessage('');
        }, 3000);
      } else {
        setError('Failed to send SMS.');
        setSuccessMessage('');
        setPhoneNumber('');
      }
      setLoading(false);
    } catch (error) {
      setError('An error occurred while sending the SMS.');
      setPhoneNumber('');
    }
  };

  return (
    <div className="">
      <div className="flex flex-col gap-3 bg-white justify-center rounded-b-lg  w-full  items-center mt-3 transition-all duration-300 ease-in-out ">
        {/* <input
          type="text"
          name="phoneNumber"
          placeholder="Phone number"
          className="text-sm w-full border py-[9px] px-2 rounded-lg"
          onChange={handleInputChange}
          value={phoneNumber}
          onKeyPress={(e) => {
            if (!/^\d*$/.test(e.key)) {
              e.preventDefault();
            }
          }}
          maxLength={10}
        /> */}
        <PhoneInput
          className="text-sm w-full border py-[9px] px-2 rounded-lg"
          placeholder="Enter phone number"
          value={phoneNumber || ''}
          onChange={handlePhoneNumberChange}
        />

        <button
          // className="bg-yellowtacx text-purpletacx justify-center flex rounded-lg px-2 py-2 w-full"
          className={`bg-yellowtacx text-purpletacx flex justify-center rounded-lg px-2 py-2 w-full transition-all duration-200 ${
            loading ? 'opacity-80 cursor-not-allowed' : ''
          }`}
          onClick={handleShareCardSMS}
          disabled={loading}
        >
          {loading ? (
            <svg
              className="h-6 w-6 border-4 border-t-transparent border-purpletacx-500 animate-spin rounded-full"
              viewBox="0 0 24 24"
            ></svg>
          ) : (
            // <RiSendPlaneFill size={25} />
            <p>Send</p>
          )}
        </button>
      </div>
      {error && (
        <p className="bg-white test w-full rounded-b-lg text-red-600 text-sm">
          {error}
        </p>
      )}
      {successMessage && (
        <p className=" bg-white test w-full rounded-b-lg text-green-600 text-sm">
          {successMessage}
        </p>
      )}
    </div>
  );
};

export default SmsShareCard;
