import React from 'react';
import { QRCodeCanvas } from 'qrcode.react';
interface DynamicModalProps {
  onClose: () => void;
  url: string;
  isOpen: boolean;
}
const QrCodeModel: React.FC<DynamicModalProps> = ({
  onClose,
  url,
  isOpen,
}) => {
  return isOpen ? (
    <div className="fixed grid content-center top-0 bottom-0 left-0 z-50 w-screen h:screen bg-[#59425580] bg-opacity-70 font-poppins fade-in-animate">
      <div className="bg-[#594255] flex m-auto justify-center rounded-lg relative w-[90%] md:w-1/2 xl:w-1/3 slideInFromTop-animate">
        <div className="p-5 sm:p-10 py-15 w-full flex flex-col items-center">
          <QRCodeCanvas
            value={url}
            size={256}
            bgColor="#594255"
            fgColor="#ffffff"
            level="H"
          />
          <div
            className="mt-4 px-25 sm:px-35 py-3 rounded-full bg-[#DFC749] text-black font-bold cursor-pointer"
            onClick={onClose}
          >
            Close
          </div>
        </div>
      </div>
    </div>
  ) : null;
};
export default QrCodeModel;