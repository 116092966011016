import { useAuth } from '../../../../../Authcontext';
import { useDrag, useDrop } from 'react-dnd';
import MyIcon from '../../../icons/Myicon';
import { useRef, useState, useEffect } from 'react';
import update from 'immutability-helper';
import themeData from '../../../Themedata';

interface CanvasProps {
  boxData: any;
  id: string | number;
  gridColumns: string;
  boxWidth:string | number;
  boxHeight:string | number;
  boxSpacing:string | number;
}
const style = {
  // padding: '0.5rem 1rem',
  marginBottom: '.5rem',
  cursor: 'move',
};
const Canvas: React.FunctionComponent<CanvasProps> = (props) => {
  const { id, gridColumns, boxData, boxWidth,boxHeight ,boxSpacing} = props;
  const [data, setData] = useState<any>(boxData);
  const {
    canvasItems,
    setCanvasItems,
    setRedoStack,
    selectedItem,
    setSelectedItem,
  } = useAuth();

  useEffect(() => {
    canvasItems?.map((item: any, index: number) => {
      if (item.id === id) {
        setData(item.boxData);
      }
    });
  }, [canvasItems]);
  const handleDrop = (newItem: any) => {
    setRedoStack([]); // Clear redo stack
    setCanvasItems((prevItems: any) => {
      return prevItems.map((item: any) => {
        if (item.id === id) {
          let updatedBoxData = item.boxData.filter(
            (boxItem: any) => boxItem !== undefined,
          );

          if (updatedBoxData[0]?.type === 'demo') {
            updatedBoxData.splice(0, 1);
          }

          if (newItem !== undefined) {
            updatedBoxData.unshift({
              ...newItem,
              id: `${id}/${newItem.type}/${updatedBoxData.length + 1}`,
            });
          }

          return { ...item, boxData: updatedBoxData };
        }

        return item;
      });
    });
  };

  const [, drop] = useDrop(() => ({
    accept: ['ITEM', 'SECTION'],
    drop: (droppedItem: any) => {
      let data = localStorage.getItem('GridStatus');
      if (droppedItem.section) {
        droppedItem.section.items.forEach(handleDrop);
      } else {
        if (droppedItem.type != 'Grid') {
          if (data == 'true') {
            handleDrop(droppedItem);
          }
        }
      }
    },
  }));
  const moveCard = (dragIndex: number, hoverIndex: number) => {
    let updatedBoxData = [...data];
    // Perform the drag-and-drop logic
    let [draggedItem] = updatedBoxData.splice(dragIndex, 1);
    updatedBoxData.splice(hoverIndex, 0, draggedItem);
    const newChange = canvasItems.map((card: any) => {
      if (card.id === id && card.type === 'Grid') {
        // Create a copy of the boxData array
        return {
          ...card,
          boxData: updatedBoxData,
        };
      }
      // Return the card unchanged if it doesn't match the criteria
      return card;
    });
    window.onr
    // Step 2: Update the state with the new array
    setCanvasItems(newChange);
    // setData(updatedBoxData);
  };
  //grid-rows-[50%_50%]
  return (
    <div
      ref={drop}
      className={`canvas min-h-40 h-auto p-2`}
      style={{width:boxWidth==0?'100%':`${boxWidth}%`}}
      onClick={() => {
        setSelectedItem(null);
      }}
    >
      <div
        className={`canvas grid ${gridColumns} h-auto gap-2 overflow-x-scroll`}
        style={{height:boxHeight ==0? '100%':`${boxHeight}%`,gap:`${boxSpacing}px` }}
      >
        {data?.map(
          (item: any, index: any) =>
            item != undefined && (
              <CanvasItem
                // key={index}
                item={item}
                onSelectItem={setSelectedItem}
                key={item.id}
                index={index}
                id={item.id}
                moveCard={moveCard}
                // globalCardStyle={globalCardStyle}
                // setBarStatus={setBarStatus}
              />
            ),
        )}
      </div>
    </div>
  );
};

const CanvasItem: React.FunctionComponent<any> = ({
  item,
  onSelectItem,
  id,
  index,
  moveCard,
  //   globalCardStyle,
  //   setBarStatus,
}) => {
  const ref = useRef(null);
  let data = localStorage.getItem('GridStatus');

  if (item.type != 'demo') {
    const [{ handlerId }, drop] = useDrop({
      accept: 'CARD',
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
        };
      },
      hover(item:any, monitor) {
        if (data == 'true') {
          if (!ref.current) {
            return;
          }
          const dragIndex = item.index;

          const hoverIndex = index;

          // Don't replace items with themselves
          if (dragIndex === hoverIndex) {
            return;
          }
          // Determine rectangle on screen
          const hoverBoundingRect = ref.current?.getBoundingClientRect();


          // Get vertical middle
          const hoverMiddleY =
            (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
          // Determine mouse position
          const clientOffset:any = monitor.getClientOffset();
          // Get pixels to the top
          const hoverClientY = clientOffset.y - hoverBoundingRect.top;
          // Only perform the move when the mouse has crossed half of the items height
          // When dragging downwards, only move when the cursor is below 50%
          // When dragging upwards, only move when the cursor is above 50%
          // Dragging downwards
          if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
            return;
          }
          // Dragging upwards
          if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            return;
          }
          // Time to actually perform the action

          moveCard(dragIndex, hoverIndex);
          // Note: we're mutating the monitor item here!
          // Generally it's better to avoid mutations,
          // but it's good here for the sake of performance
          // to avoid expensive index searches.
          item.index = hoverIndex;
        }
      },
    });

    const [{ isDragging }, drag] = useDrag({
      type: 'CARD',
      item: () => {
        return { id, index };
      },
      collect: (monitor) => {
        return {
          isDragging: data === 'true' ? monitor.isDragging() : false,
        };
      },
    });

    const opacity = isDragging ? 0 : 1;

    drag(drop(ref));
    const Component = themeData.theme1s.find(
      (component) => component.componentName === item.componentName,
    )?.component;

    if (!Component) {
      console.error(
        `Component for type '${item.componentName}' not found in themeData.`,
      );
      return null;
    }

    // Get component props based on the component type
    const getComponentProps = (item: any) => {
      const options = item.customizationOptions;
      switch (item.componentName) {
        case 'Profile':
          return {
            backgroundImage: options.backgroundImage.default,
            fname: options.fname.default,
            lname: options.lname.default,
            companyLogo: options.companyLogo.default,
            profileImage: options.profileImage.default,
            jobTitles: options.jobTitles.default,
            companyName: options.companyName.default,
            hideCompanyLogo: options.hideCompanyLogo.default,
            fontColor: options?.fontColor?.default || '#ffffff',
            linkColor: options?.linkColor?.default || '#ffffff',
            companyNameTextColor:
              options?.companyNameTextColor?.default || '#ca8a04',
            globalStyling: {
              fontColor: options?.globalStyling?.fontColor,
              linkColor: options?.globalStyling?.linkColor,
              fontSize: options?.globalStyling?.fontSize,
              status: options?.globalStyling?.status,
              ignoreFieldArray: options?.globalStyling?.ignoreFieldArray,
              globalChangesList: options?.globalStyling?.globalChangesList,
            },
            typography: {
              type: options?.typography?.type?.default,
              family: options?.typography?.family?.default,
              nameFontSize: options?.typography?.nameFontSize?.default,
              typographyWeight: options?.typography?.typographyWeight?.default,
              typographyStyle: options?.typography?.typographyStyle?.default,
              lineHeight: options.typography.lineHeight.default,
            },
            companyTypography: {
              type: options?.companyTypography?.type?.default,
              family: options?.companyTypography?.family?.default,
              nameFontSize: options?.companyTypography?.nameFontSize?.default,
              companyTypographyWeight:
                options?.companyTypography?.companyTypographyWeight?.default,
              companyTypographyStyle:
                options?.companyTypography?.companyTypographyStyle?.default,
              lineHeight: options.companyTypography.lineHeight.default,
            },
            jobTypography: {
              type: options?.jobTypography?.type?.default,
              family: options?.jobTypography?.family?.default,
              nameFontSize: options?.jobTypography?.nameFontSize?.default,
              jobTypographyWeight:
                options?.jobTypography?.jobTypographyWeight?.default,
              jobTypographyStyle:
                options?.jobTypography?.jobTypographyStyle?.default,
              lineHeight: options.jobTypography.lineHeight.default,
            },
          };
        case 'Company':
          return {
            companyName: options.companyName.default,
            CompanyLogo: options.CompanyLogo.default,
            role: options.role.default,
            layout: options.layout.default,
            textColor: options?.textColor?.default || '#000000',
            companyPadding: options.companyPadding.default || [0, 0, 0, 0],
            companyMargin: options.companyMargin.default || [0, 0, 0, 0],
            globalStyling: {
              fontColor: options?.globalStyling?.fontColor,
              linkColor: options?.globalStyling?.linkColor,
              fontSize: options?.globalStyling?.fontSize,
              status: options?.globalStyling?.status,
              ignoreFieldArray: options?.globalStyling?.ignoreFieldArray,
              globalChangesList: options?.globalStyling?.globalChangesList,
            },
          };
        case 'Social':
          return {
            id: item.id,
            links: options.links.default,
            linkColor: options?.linkColor?.default || '#ffffff',
            linkShape: options?.linkShape.default,
            linkAlignment: options?.linkAlignment.default,
            linkSpacing: options?.linkSpacing.default,
            borderType: options?.borderType.default,
            borderWidth: options?.borderWidth.default,
            borderColor: options?.borderColor.default,
            borderRadius: options?.borderRadius.default,
            globalStyling: {
              fontColor: options?.globalStyling?.fontColor,
              linkColor: options?.globalStyling?.linkColor,
              fontSize: options?.globalStyling?.fontSize,
              pmColor: options?.globalStyling?.pmColor,
              seColor: options?.globalStyling?.seColor,
              status: options?.globalStyling?.status,
              ignoreFieldArray: options?.globalStyling?.ignoreFieldArray,
              globalChangesList: options?.globalStyling?.globalChangesList,
            },
          };
        case 'Carousel':
          return {
            images: options.imagesCarousel.default,
            autoplay: options.autoplay.default,
            interval: options.interval.default,
            imageResoluation: options?.imageResoluation.default,
            navigation: options?.navigation.default,
            borderType: options?.borderType.default,
            borderWidth: options.borderWidth.default || [0, 0, 0, 0],
            borderRadius: options?.borderRadius.default || [0, 0, 0, 0],
            carouselPadding: options.carouselPadding.default || [0, 0, 0, 0],
            carouselMargin: options.carouselMargin.default || [0, 0, 0, 0],
          };
        case 'Button':
          return {
            bgcolor: options.backgroundColor.default,
            name: options.name.default,
            url: options.url.default,
            fontColor: options.fontColor.default,
            alignItem: options.alignItem.default,
            full_width: options.full_width.default,
            buttonPadding: options.buttonPadding.default || [10, 10, 10, 10],
            buttonMargin: options.buttonMargin.default || [0, 0, 0, 0],
            buttonRadius: options.buttonRadius.default || [0, 0, 0, 0],
            buttonWidth: options.buttonRadius.default || [0, 0, 0, 0],
            buttonTypography: {
              type: options?.buttonTypography?.type?.default,
              family: options?.buttonTypography?.family?.default,
              nameFontSize: options?.buttonTypography?.nameFontSize?.default,
              typographyWeight:
                options?.buttonTypography?.typographyWeight?.default,
              typographyStyle:
                options?.buttonTypography?.typographyStyle?.default,
            },
            globalStyling: {
              fontColor: options?.globalStyling?.fontColor,
              linkColor: options?.globalStyling?.linkColor,
              fontSize: options?.globalStyling?.fontSize,
              groundColor: options?.globalStyling?.groundColor,
              status: options?.globalStyling?.status,
              ignoreFieldArray: options?.globalStyling?.ignoreFieldArray,
              globalChangesList: options?.globalStyling?.globalChangesList,
              buttonPadding: options?.globalStyling?.buttonPadding,
              buttonMargin: options?.globalStyling?.buttonMargin,
              buttonRadius: options?.globalStyling?.buttonRadius,
            },
          };
        case 'About Us':
          return {
            // videoUrl: options.videoUrl.default,
            textContent: options.textContent.default,
            hideText: options.hideText.default,
            fontColor: options.fontColor.default,
            aboutUsTypography: {
              type: options?.aboutUsTypography?.type?.default,
              family: options?.aboutUsTypography?.family?.default,
              nameFontSize: options?.aboutUsTypography?.nameFontSize?.default,
              typographyWeight:
                options?.aboutUsTypography?.typographyWeight?.default,
              typographyStyle:
                options?.aboutUsTypography?.typographyStyle?.default,
              lineHeight: options.aboutUsTypography.lineHeight.default,
            },
            aboutUsPadding: options.aboutUsPadding.default || [10, 10, 10, 10],
            aboutUsMargin: options.aboutUsMargin.default || [0, 0, 0, 0],
            globalStyling: {
              fontColor: options?.globalStyling?.fontColor,
              linkColor: options?.globalStyling?.linkColor,
              fontSize: options?.globalStyling?.fontSize,
              status: options?.globalStyling?.status,
              ignoreFieldArray: options?.globalStyling?.ignoreFieldArray,
              globalChangesList: options?.globalStyling?.globalChangesList,
            },
          };
        case 'Heading':
          return {
            textContent: options.textContent.default,
            hideText: options.hideText.default,
            fontColor: options.fontColor.default,
            textPosition: options.textPosition.default,
            headingTypography: {
              type: options?.headingTypography?.type?.default,
              family: options?.headingTypography?.family?.default,
              nameFontSize: options?.headingTypography?.nameFontSize?.default,
              typographyWeight:
                options?.headingTypography?.typographyWeight?.default,
              typographyStyle:
                options?.headingTypography?.typographyStyle?.default,
              lineHeight: options.headingTypography.lineHeight.default,
            },
            headingPadding: options.headingPadding.default || [10, 10, 10, 10],
            headingMargin: options.headingMargin.default || [0, 0, 0, 0],
            globalStyling: {
              fontColor: options?.globalStyling?.fontColor,
              linkColor: options?.globalStyling?.linkColor,
              fontSize: options?.globalStyling?.fontSize,
              status: options?.globalStyling?.status,
              ignoreFieldArray: options?.globalStyling?.ignoreFieldArray,
              globalChangesList: options?.globalStyling?.globalChangesList,
            },
          };
        case 'Testimonial':
          return {
            // textContent: options.textContent.default,
            Testimonial: options.Testimonial.default,
            fontColor: options.fontColor.default,
            namefontColor: options.namefontColor.default,
            testimonialTypography: {
              type: options?.testimonialTypography?.type?.default,
              family: options?.testimonialTypography?.family?.default,
              nameFontSize:
                options?.testimonialTypography?.nameFontSize?.default,
              typographyWeight:
                options?.testimonialTypography?.typographyWeight?.default,
              typographyStyle:
                options?.testimonialTypography?.typographyStyle?.default,
            },
            testimonialPadding: options.testimonialPadding.default || [
              0, 0, 0, 0,
            ],
            testimonialMargin: options.testimonialMargin.default || [
              0, 0, 0, 0,
            ],
            globalStyling: {
              fontColor: options?.globalStyling?.fontColor,
              linkColor: options?.globalStyling?.linkColor,
              fontSize: options?.globalStyling?.fontSize,
              status: options?.globalStyling?.status,
              ignoreFieldArray: options?.globalStyling?.ignoreFieldArray,
              globalChangesList: options?.globalStyling?.globalChangesList,
            },
          };
        case 'Videos':
          return {
            videoUrls: options.videoUrls.default,
            videosPadding: options.videosPadding.default || [0, 0, 0, 0],
            videosMargin: options.videosMargin.default || [0, 0, 0, 0],
          };
        case 'Divider':
          return {
            id: item.id,
            dividerBorderStyle: options.dividerBorderStyle.default,
            dividerWidth: options.dividerWidth.default,
            dividerPosition: options.dividerPosition.default,
            // dividerElement:options.dividerElement.default,
            icon: options.icon.default,
            borderColor: options.borderColor.default,
            globalStyling: {
              pmColor: options?.globalStyling?.pmColor,
              seColor: options?.globalStyling?.seColor,
              status: options?.globalStyling?.status,
              ignoreFieldArray: options?.globalStyling?.ignoreFieldArray,
              globalChangesList: options?.globalStyling?.globalChangesList,
            },
          };
        case 'Image':
          return {
            image: options.image.default,
            imageResoluation: options.imageResoluation.default,
            caption: options.caption.default,
            hideCaption: options.hideCaption.default,
            link: options?.link?.default,
            hideLink: options?.hideLink?.default,
            imagePosition: options?.imagePosition?.default,
            imageTypography: {
              type: options?.imageTypography?.type?.default,
              family: options?.imageTypography?.family?.default,
              nameFontSize: options?.imageTypography?.nameFontSize?.default,
              typographyWeight:
                options?.imageTypography?.typographyWeight?.default,
              typographyStyle:
                options?.imageTypography?.typographyStyle?.default,
              lineHeight: options.imageTypography.lineHeight.default,
            },
            imagePadding: options.imagePadding.default || [10, 10, 10, 10],
            imageMargin: options.imageMargin.default || [0, 0, 0, 0],
            globalStyling: {
              fontColor: options?.globalStyling?.fontColor,
              linkColor: options?.globalStyling?.linkColor,
              fontSize: options?.globalStyling?.fontSize,
              status: options?.globalStyling?.status,
              ignoreFieldArray: options?.globalStyling?.ignoreFieldArray,
              globalChangesList: options?.globalStyling?.globalChangesList,
            },
          };
        default:
          return {};
      }
    };

    const componentProps:any = getComponentProps(item);
    // const handleClick = () => {
    //   onSelectItem(item.section ? item.section : item, item);
    // };
    const handleClick = () => {
      const foundComponent = themeData.theme1s.find(
        (component) => component.componentName === item.componentName,
      );

      if (foundComponent) {
        const missingFields: any = [];
        const removedFields: any = [];

        const customizationOptionsItem = item.customizationOptions || {};
        const customizationOptionsFound:any =
          foundComponent.customizationOptions || {};
        const divider = item.divider || [{ label: 'Button', value: [] }];

        // Add missing fields from foundComponent to item
        Object.keys(customizationOptionsFound).forEach((key) => {
          const foundField = customizationOptionsFound[key];
          const itemField = customizationOptionsItem[key];

          if (itemField === undefined || itemField === null) {
            // If the field is missing or has a null/undefined value in item, add it
            missingFields.push({ key, value: foundField });
            customizationOptionsItem[key] = foundField;
            if (
              divider.length > 0 &&
              divider[0].value &&
              !divider[0].value.includes(key)
            ) {
              divider[0].value.push(key);
            }
          } else {
            // Update item field with label if it exists in foundComponent
            if (
              itemField.label !== undefined &&
              foundField.label !== undefined
            ) {
              itemField.label = foundField.label;
            }
          }
        });

        // Remove fields from item that are not present in foundComponent
        Object.keys(customizationOptionsItem).forEach((key) => {
          if (!(key in customizationOptionsFound)) {
            removedFields.push({ key, value: customizationOptionsItem[key] });
            delete customizationOptionsItem[key];
            if (
              divider.length > 0 &&
              divider[0].value &&
              divider[0].value.includes(key)
            ) {
              divider[0].value = divider[0].value.filter((v:any) => v !== key);
            }
          }
        });
        // Update the item with the new customizationOptions and divider
        item.customizationOptions = customizationOptionsItem;
        item.divider = divider;
      }
      if (item != null) {
        onSelectItem(item);
      }
    };
    return (
      <div
        ref={ref}
        style={{ ...style, opacity }}
        data-handler-id={handlerId}
        className="relative overflow-y-scroll"
        onClick={(e) => {
          if (data == 'true') {
            e.stopPropagation();
            handleClick();
          }
        }}
      >
        {data == 'true' && (
          <div className="absolute top-0 right-0 z-1 w-6 h-5 bg-[rgba(0,0,0,0.5)] rounded-bl-lg flex content-center justify-center">
            <MyIcon iconName="edit" />
          </div>
        )}
        <Component {...componentProps} />
      </div>
    );
  } else {
    return (
      <div className="border-[1px] border-dashed w-auto min-h-40 h-full flex justify-center items-center">
        <div className="cursor-pointer">
          <MyIcon iconName="plus" color="gray" />
        </div>
      </div>
    );
  }
};
export default Canvas;
