import React, { ComponentType, useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { getSubscription, ProfileData } from './api/Api';
import Loader from './common/loader/Index';
import { useAuth } from './Authcontext';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser, setSubscription, setUser } from './store/slice/Userslice';
import { AdminProfileData } from './api/Adminapi';

interface PrivateRouteProps {
  element: ComponentType<any>;
  isAdminRoute?: boolean;
  [key: string]: any; // This allows any other props
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  element: Component,
  isAdminRoute = false,
  ...rest
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasSubscription, setHasSubscription] = useState(false);
  const { logout } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const subscriptionDetails = useSelector(selectSubscription);
  const selectUserDetails = useSelector(selectUser);
  useEffect(() => {
    const checkSubscriptionStatus = async () => {
      try {
        let userDetails: any;

        // Fetch user details if not already present
        // if (!selectUserDetails.user) {
        if (isAdminRoute) {
          const userData = await AdminProfileData();
          userDetails = userData?.data;
        } else {
          const userData = await ProfileData();
          userDetails = userData?.data;
        }
        // } else {
        //   const userData = await ProfileData();
        //   userDetails = userData?.data;
        // }

        if (userDetails.success) {
          dispatch(setUser({ user: userDetails.user }));

          // Handle admin-specific logic
          if (userDetails.user.user_role === 'Admin') {
            // Admin should bypass subscription checks
            if (isAdminRoute) {
              setHasSubscription(true);
            } else {
              // If admin tries to access a non-admin route, redirect to admin dashboard
              navigate('/admin/my-profile');
            }
          }
          // Handle team mate and user-specific logic
          else if (userDetails.user.user_role === 'Team mate') {
            setHasSubscription(userDetails.user.user_role === 'Team mate'); // Teammates don't need subscription
          } else {
            // Regular user: Check subscription
            const response = await getSubscription();
            setHasSubscription(response?.data.subscription.status === 'active');
            dispatch(
              setSubscription({ subscription: response?.data.subscription }),
            ); // dispatch the sunscription data
          }
        } else {
          // If user is not authenticated or valid, logout and redirect to home
          logout();
          navigate('/');
        }
      } catch (error) {
        console.error('Error checking subscription status:', error);
      } finally {
        setIsLoading(false);
      }
    };

    checkSubscriptionStatus();
  }, [logout, navigate, dispatch, isAdminRoute]);

  if (isLoading) {
    return <Loader />;
  }

  if (selectUserDetails.user?.user_role === 'Admin' || hasSubscription) {
    return <Component {...rest} />;
  }

  // Redirect non-admins without an active subscription
  return <Navigate to="/purchase-plan" />;
};

export default PrivateRoute;
