import axios from 'axios';
const URL = import.meta.env.VITE_API_URL;

const jsonconfig = {
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
};

export const PushInAppNotification = async (messageData: any) => {
  try {
    return await axios.post(
      `${URL}/notification/post-notification`,
      messageData,
      jsonconfig,
    );
  } catch (error) {
    console.error('Error getting admin support details api:', error);
  }
};

// get notifications 
export const getAdminNotification = async () => {
  try {
    return await axios.get(
      `${URL}/notification/admin/get-notification`,
      jsonconfig,
    );
  } catch (error) {
    console.error('Error retriving admin notifications:', error);
  }
};

export const getUserNotification = async () => {
  try {
    return await axios.get(
      `${URL}/notification/user/get-notification`,
      jsonconfig,
    );
  } catch (error) {
    console.error('Error retriving admin notifications:', error);
  }
};

export const getNotifications = async () => {
  try {
    return await axios.get(`${URL}/notification/get-notifications`);
  } catch (error) {
    console.error('Error getting user activity', error);
  }
};
