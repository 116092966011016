import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearUser } from '../../store/slice/Userslice';
import MyIcon from '../cardeditor/icons/Myicon';
import { useAuth } from '../../Authcontext';
const Header = (props: {
  sidebarOpen: string | boolean | undefined;
  setSidebarOpen: (arg0: boolean) => void;
  onAboutUsClick: () => void;
  onPricingClick: () => void;
  onContactUsClick: () => void;
}) => {
  const { isAuthenticated, logout } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <header className="sticky top-0 z-999 flex w-full bg-purpletacx ">
      <div className="flex flex-grow items-center justify-between px-4 py-4  md:px-6 2xl:px-11">
        <div className="flex items-center 2xsm:gap-4">
          <Link
            to={'/'}
            className="hidden sm:block w-[7%]"
            onClick={() => navigate('/')}
          >
            <MyIcon iconName="tacx" />
          </Link>
          <Link
            to={'/'}
            className="block sm:hidden"
            onClick={() => navigate('/')}
          >
            <MyIcon iconName="tacxSmall" />
          </Link>
        </div>
        {!isAuthenticated ? (
          <>
            <div className="flex flex-row  space-x-2 sm:space-x-10 font-semibold ">
              <button onClick={props.onAboutUsClick}>
                <p className="text-white hover:text-yellowtacx text-xs sm:text-sm md:text-lg">
                  How it works?
                </p>
              </button>
              <button onClick={props.onPricingClick}>
                <p className="text-white hover:text-yellowtacx text-xs sm:text-sm md:text-lg">
                  Pricing
                </p>
              </button>
            </div>
            <div className="flex items-center 2xsm:space-x-3 space-x-1">
              <Link
                to={'/login'}
                className="bg-yellowtacx text-black text-sm md:text-md rounded-full font-semibold px-3 py-1 md:px-5 md:py-2 hover:text-white hover:bg-transparent border border-yellowtacx"
              >
                Login
              </Link>
              <Link
                to={'/signUp'}
                className="bg-yellowtacx text-black text-sm md:text-md rounded-full font-semibold px-2 py-1 md:px-4 md:py-2 hover:text-white hover:bg-transparent border border-yellowtacx"
              >
                SignUp
              </Link>
            </div>
          </>
        ) : (
          <>
            <div className="flex items-center 2xsm:gap-7">
              <Link
                to={'/'}
                className="flex items-center gap-3.5 text-white font-semibold text-sm duration-300 ease-in-out hover:text-yellowtacx lg:text-base"
                onClick={() => {
                  logout();
                  // Dispatch action to clear user data from Redux
                  dispatch(clearUser());
                }}
              >
                <svg
                  className="fill-current"
                  width="19"
                  height="19"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.5375 0.618744H11.6531C10.7594 0.618744 10.0031 1.37499 10.0031 2.26874V4.64062C10.0031 5.05312 10.3469 5.39687 10.7594 5.39687C11.1719 5.39687 11.55 5.05312 11.55 4.64062V2.23437C11.55 2.16562 11.5844 2.13124 11.6531 2.13124H15.5375C16.3625 2.13124 17.0156 2.78437 17.0156 3.60937V18.3562C17.0156 19.1812 16.3625 19.8344 15.5375 19.8344H11.6531C11.5844 19.8344 11.55 19.8 11.55 19.7312V17.3594C11.55 16.9469 11.2062 16.6031 10.7594 16.6031C10.3125 16.6031 10.0031 16.9469 10.0031 17.3594V19.7312C10.0031 20.625 10.7594 21.3812 11.6531 21.3812H15.5375C17.2219 21.3812 18.5625 20.0062 18.5625 18.3562V3.64374C18.5625 1.95937 17.1875 0.618744 15.5375 0.618744Z"
                    fill=""
                  />
                  <path
                    d="M6.05001 11.7563H12.2031C12.6156 11.7563 12.9594 11.4125 12.9594 11C12.9594 10.5875 12.6156 10.2438 12.2031 10.2438H6.08439L8.21564 8.07813C8.52501 7.76875 8.52501 7.2875 8.21564 6.97812C7.90626 6.66875 7.42501 6.66875 7.11564 6.97812L3.67814 10.4844C3.36876 10.7938 3.36876 11.275 3.67814 11.5844L7.11564 15.0906C7.25314 15.2281 7.45939 15.3312 7.66564 15.3312C7.87189 15.3312 8.04376 15.2625 8.21564 15.125C8.52501 14.8156 8.52501 14.3344 8.21564 14.025L6.05001 11.7563Z"
                    fill=""
                  />
                </svg>
                <span className="">Log Out</span>
              </Link>
            </div>
          </>
        )}
      </div>
    </header>
  );
};
export default Header;
