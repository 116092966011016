import React, { useState } from 'react';
import { RiSendPlaneFill } from 'react-icons/ri';
import { cardShareViaEmail } from '../../api/Api';

interface EmailShareProps {
  url: string;
}

const EmailShare: React.FC<EmailShareProps> = ({ url }) => {
  // const [isOpen, setIsOpen] = useState(false);
  const [emailVal, setEmailVal] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);

  // const toggleShareMenu = () => {
  //   setIsOpen((prev) => !prev);
  // };

  const handleInputChange = (e: any) => {
    const value = e.target.value;
    setError('');
    setEmailVal(value);
  };

  const validateEmail = (email: string) => {
    const re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return re.test(String(email).toLowerCase());
  };

  const handleShareCardEmail = async () => {
    const email = emailVal.trim();
    if (!validateEmail(email)) {
      setError('Please enter a valid email address.');
      return;
    }
    try {
      setLoading(true);
      const dataToSend = {
        email: emailVal,
        url: url,
      };
      const response = await cardShareViaEmail(dataToSend);
      if (response?.data.success) {
        setLoading(false);
        setSuccessMessage('Email sent successfully!');
        setTimeout(() => {
          setSuccessMessage('');
        }, 4000);
        setError('');
        setEmailVal('');
      } else {
        setLoading(false);
        setError(response?.data.error || 'Failed to send email.');
        setSuccessMessage('');
      }
    } catch (error) {
      setLoading(false);
      console.error('Error:', error);
      setError('An error occurred while sending the email.');
      setSuccessMessage('');
    }
  };

  return (
    <div className="">
      {/* <button
        onClick={toggleShareMenu}
        className="text-purpletacx  transition duration-300 ease-in-out "
      >
        <TfiEmail size={24} />
      </button> */}

      {/* {isOpen && ( */}
      <>
        <div className="flex flex-row bg-white justify-center rounded-b-lg  w-full  items-center mt-3 transition-all duration-300 ease-in-out ">
          <input
            className="text-sm w-full border-y border-l py-[9px] px-2 rounded-l-lg"
            type="text"
            value={emailVal}
            placeholder="Enter email"
            onChange={handleInputChange}
          />
          <button
            className="bg-yellowtacx text-purpletacx rounded-r-lg px-2 py-[7px]"
            onClick={handleShareCardEmail}
          >
            {loading ? (
              <svg
                className="h-6 w-6 border-4 border-t-transparent border-purpletacx-500 animate-spin rounded-full"
                viewBox="0 0 24 24"
              ></svg>
            ) : (
              <RiSendPlaneFill size={25} />
            )}
          </button>
        </div>
        {error && (
          <p className=" bg-white test w-full  rounded-b-lg text-red-600 text-sm">
            {error}
          </p>
        )}
        {successMessage && (
          <p className=" bg-white test w-full  rounded-b-lg text-green-600 text-sm">
            {successMessage}
          </p>
        )}
      </>
      {/* )} */}
    </div>
  );
};

export default EmailShare;
