import React, { useEffect, useState } from 'react';
import CardDataStats from '../../../components/Carddatastats';
import ChartFive from '../../../components/charts/ChartFive';
import ChartFour from '../../../components/charts/ChartFour';
import DefaultLayout from '../../../layout/Defaultlayout';
import MyIcon from '../../../components/cardeditor/icons/Myicon';
import {
  GetAllUsers,
  GetAllUsersHaveSubscriptions,
  GetViewEngagement,
} from '../../../api/Adminapi';
import { getCardEngagementData } from '../../../api/Api';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../store/slice/Userslice';
import Loader from '../../../common/loader/Index';
import { Link } from 'react-router-dom';
interface EngagementDataProps {
  pageView: number;
  connection: number;
  viewPerUser: any;
  engagementTime: any;
}

interface User {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  company_name?: string;
  amount_total?: number;
  plan_name?: string;
  created_at: string;
  is_active: boolean;
  expiration_date?: string;
  profile_picture?: string;
  phone_number?: string;
}
const AnalyticsAdmin: React.FC = () => {
  const userDetails = useSelector(selectUser);
  const userData = userDetails?.user;
  const [loading, setLoading] = useState<boolean>(false);
  const [engagementData, setEngagementData] = useState<EngagementDataProps>();
  const [chartData, setChartData] = useState();
  const [subsscriptionPlan, setSubPlan] = useState();
  const [users, setUsers] = useState<User[]>([]);
  const [todaySubscriptionsLength, setTodaySubscriptionsLength] = useState(0);
  const [viewCount, setViewCount] = useState();

  useEffect(() => {
    fetchUserAndSubscriptions();
    fetchSubscriptions();
    fetchEngagement();
  }, []);

  const fetchUserAndSubscriptions = async () => {
    setLoading(true);
    try {
      const response = await GetAllUsers();
      const data = response?.data.user;
      setUsers(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSubscriptions = async () => {
    setLoading(true);
    try {
      const response = await GetAllUsersHaveSubscriptions();
      setSubPlan(response?.data);
      const data = response?.data.user;
      // Get today's date in YYYY-MM-DD format
      const today = new Date().toISOString().split('T')[0];
      // Filter subscriptions created today
      const todaySubscriptions = data.filter(
        (user: { created_date: string | number | Date }) => {
          const createdDate = new Date(user.created_date)
            .toISOString()
            .split('T')[0];
          return createdDate === today;
        },
      );

      // Get the length of subscriptions created today
      const todaySubscriptionsLength = todaySubscriptions.length;
      setTodaySubscriptionsLength(todaySubscriptions.length);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchEngagement = async () => {
    setLoading(true);
    try {
      const response = await GetViewEngagement();
      setViewCount(response?.data?.totalViewCount);
      setChartData(response?.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <DefaultLayout title="Analytics">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-3 2xl:gap-7.5">
            <Link to="/admin/client/dashboard">
              <CardDataStats
                title="Total Users"
                total={users.length}
                rate="0.43%"
                levelUp
              >
                <MyIcon iconName="pageView" />
              </CardDataStats>
            </Link>
            <Link to="/admin/users/subscriptions">
              <CardDataStats
                title="Today Total Subscription"
                //   total={engagementData?.connection}
                total={todaySubscriptionsLength}
                rate="4.35%"
                levelUp
              >
                <MyIcon iconName="connection" />
              </CardDataStats>
            </Link>
            <CardDataStats
              title="Engagement"
              //   total={engagementData?.viewPerUser}
              total={viewCount}
              rate="2.59%"
              levelUp
            >
              <MyIcon iconName="viewPerUser" />
            </CardDataStats>
          </div>

          <div className="mt-4 grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
            <ChartFour chartData={chartData} />
            <ChartFive subsscriptionPlan={subsscriptionPlan} />
          </div>
        </>
      )}
    </DefaultLayout>
  );
};
export default AnalyticsAdmin;
