import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel';
import PropTypes from 'prop-types';
import defaultImage1 from '../../../assets/carousel-1.svg';
import defaultImage2 from '../../../assets/carousel-2.svg';
import defaultImage3 from '../../../assets/carousel-3.svg';
import defaultImage4 from '../../../assets/carousel-4.svg';
import { useEffect, useState } from 'react';
const Carousel = ({
  images = [
    { src: 'carousel.jpg', alt: 'Image 1s' },
    { src: 'carousel.jpg', alt: 'Image 2s' },
  ],
  autoplay = true,
  interval = 3, // Default to 3 seconds if not provided
  imageResoluation = '100%',
  navigation = 'arrows and dots',
  borderType = 'none',
  borderWidth = [0, 0, 0, 0],
  borderRadius = [0, 0, 0, 0],
  carouselPadding = [0, 0, 0, 0],
  carouselMargin = [0, 0, 0, 0],
}) => {
  const autoSlideInterval = interval * 1000; // Convert interval from seconds to milliseconds
  const api_url = import.meta.env.VITE_API_URL;
  const [showArrows, setShowArrows] = useState(true);
  const [showDots, setShowDots] = useState(true);
  useEffect(() => {
    switch (navigation) {
      case 'arrows and dots':
        setShowArrows(true);
        setShowDots(true);
        break;
      case 'arrows':
        setShowArrows(true);
        setShowDots(false);
        break;
      case 'dots':
        setShowArrows(false);
        setShowDots(true);
        break;
      case 'none':
        setShowArrows(false);
        setShowDots(false);
        break;
      default:
        setShowArrows(true);
        setShowDots(true);
        break;
    }
  }, [navigation]);
  return (
    <div className="relative w-full p-2">
      <div
        style={{
          padding: `${carouselPadding?.[0]}px ${carouselPadding?.[1]}px ${carouselPadding?.[2]}px ${carouselPadding?.[3]}px`,
          margin: `${carouselMargin?.[0]}px ${carouselMargin?.[1]}px ${carouselMargin?.[2]}px ${carouselMargin?.[3]}px`,
        }}
      >
        <ResponsiveCarousel
          showArrows={showArrows}
          showIndicators={showDots}
          showThumbs={false}
          showStatus={false}
          infiniteLoop={true}
          autoPlay={autoplay}
          interval={autoSlideInterval}
        >
          {images.map((image, index) => (
            <div key={index}>
              <img
                src={`${api_url}/public/cards/images/${image.src}`}
                alt={image.alt || `carousel-${index + 1}`}
                className="block object-cover"
                style={{
                  width: imageResoluation,
                  height: imageResoluation,
                  borderWidth: `${borderWidth?.[0]}px ${borderWidth?.[1]}px ${borderWidth?.[2]}px ${borderWidth?.[3]}px`,
                  borderStyle: borderType,
                  borderRadius: `${borderRadius?.[0]}px ${borderRadius?.[1]}px ${borderRadius?.[2]}px ${borderRadius?.[3]}px`,
                }}
              />
            </div>
          ))}
        </ResponsiveCarousel>
      </div>
    </div>
  );
};
Carousel.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string,
    }),
  ).isRequired,
  autoplay: PropTypes.bool,
  interval: PropTypes.number, // Interval in seconds
};
Carousel.defaultProps = {
  images: [
    { src: defaultImage1, alt: 'Default Image 1' },
    { src: defaultImage2, alt: 'Default Image 2' },
    { src: defaultImage3, alt: 'Default Image 3' },
    { src: defaultImage4, alt: 'Default Image 4' },
  ],
  autoplay: true,
  interval: 3, // Default interval of 3 seconds
};
export default Carousel;