import React, { useEffect, useState } from 'react';
import DefaultLayout from '../../../layout/Defaultlayout';
import {
  GetAllUsersHaveSubscriptions,
  GetSubscriptionUserDetails,
} from '../../../api/Adminapi';
import { FaSearch } from 'react-icons/fa';
import Loader from '../../../common/loader/Index';
import MyIcon from '../../../components/cardeditor/icons/Myicon';
import CardDataStats from '../../../components/Carddatastats';
import Select from 'react-select';
import Pagination from '../../../components/pagination/Pagination';

/* Dropdown filter  */
const filterOptions = [
  { value: 'All', label: 'All' },
  { value: 'Active', label: 'Active' },
  { value: 'Expired', label: 'Expired' },
];

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    borderRadius: '12px',
    padding: '0',
    borderColor: '#ffff',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#ffff',
    },
    fontSize: '14px',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#DFC749' : '#fff',
    color: state.isDisabled ? '#ccc' : state.isSelected ? '#fff' : '#000',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'transperant',
      // color: '#000',
    },
    fontSize: '14px',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: '#000',
  }),
  menu: (provided: any) => ({
    ...provided,
    borderRadius: '10px',
    overflow: 'hidden',
    fontSize: '14px',
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
};

const UsersSubscriptions: React.FC = () => {
  const [users, setUsers] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filter, setFilter] = useState<string>('All');
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [activeDescriptionId, setActiveDescriptionId] = useState(null);
  const [userSubscriptionDetails, setUserSubscriptionDetails] =
    useState<any>(null);
  const [loadingSubscription, setLoadingSubscription] = useState(false);

  // const navigate = useNavigate();

  // const encodeId = (id: string) => {
  //   return btoa(id);
  // };

  useEffect(() => {
    fetchTheSubscriptions();
  }, []);

  const fetchTheSubscriptions = async () => {
    setLoading(true);
    try {
      const response = await GetAllUsersHaveSubscriptions();
      const userDetail = response?.data?.user || [];
      setUsers(userDetail);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleToggleFilter = (value: string) => {
    setFilter(value);
  };

  const filteredUsers = users
    .filter((user) => {
      const fullName = `${user.first_name} ${user.last_name}`.toLowerCase();
      return fullName.includes(searchTerm.toLowerCase());
    })
    .filter((user) => {
      if (filter === 'Active') {
        return new Date(user.expire_date) > new Date();
      } else if (filter === 'Expired') {
        return new Date(user.expire_date) <= new Date();
      }
      return true; // If 'All', return all users
    })
    .sort(
      (a, b) =>
        new Date(a.expire_date).getTime() - new Date(b.expire_date).getTime(),
    );

  // const handleViewDetails = async (id: any) => {
  //   setLoadingSubscription(true);
  //   try {
  //     const response = await GetSubscriptionUserDetails(id);
  //     if (response) {
  //       setUserSubscriptionDetails(response.data.user[0]); // Set the subscription details in state
  //       setActiveDescriptionId(activeDescriptionId === id ? null : id);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching subscription details:', error);
  //   } finally {
  //     setLoadingSubscription(false);
  //   }
  //   // const token = encodeId(id);
  //   // navigate(`/admin/users/subscription/view/page/${token}`);
  // };

  const handleViewDetails = async (id: any) => {
    // Check if the clicked user is the same as the currently active one
    if (activeDescriptionId === id) {
      setActiveDescriptionId(null);
      setUserSubscriptionDetails(null);
      return;
    }
    // If a different user ID is clicked, set the active ID and fetch details
    setLoadingSubscription(true);
    try {
      if (!userSubscriptionDetails || userSubscriptionDetails.id !== id) {
        const response = await GetSubscriptionUserDetails(id);
        if (response) {
          setUserSubscriptionDetails(response.data.user[0]); // Set the subscription details in state
          setActiveDescriptionId(id);
        }
      }
    } catch (error) {
      console.error('Error fetching subscription details:', error);
    } finally {
      setLoadingSubscription(false);
    }
    // const token = encodeId(id);
    // navigate(`/admin/users/subscription/view/page/${token}`);
  };

  const formatDateTime = (dateString: string) => {
    const date = new Date(dateString);
    return `${date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })} at ${date.toLocaleTimeString('en-GB', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    })}`;
  };

  const getStatusClassNames = (status: string) => {
    switch (status) {
      case 'expired':
        return 'text-red-500';
      case 'refunded':
        return 'text-blue-500';
      case 'upgraded':
        return 'text-blue-500';
      case 'active':
        return 'text-green-500 ml-2';
      case 'ended':
        return 'text-red-500';
      default:
        return 'text-blue-500';
    }
  };

  /* Pagination start */
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4; // Adjust this number as needed

  const totalItems = filteredUsers.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const paginatedUsers = filteredUsers.slice(startIndex, endIndex);

  return (
    <DefaultLayout title="User Subscriptions">
      {loading ? (
        <Loader />
      ) : (
        <div className="container mx-auto">
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-3 2xl:gap-7.5">
            <CardDataStats
              title="Total Subscriptions"
              total={users.length}
              rate="0.43%"
              levelUp
            >
              <MyIcon iconName="cancelSubscription" />
            </CardDataStats>
            <CardDataStats
              title="Active Subscriptions"
              total={
                users.filter(
                  (user) =>
                    new Date(user.expire_date) > new Date() &&
                    user.status !== 'pending' &&
                    user.status !== 'ended',
                ).length
              }
              rate="4.35%"
              levelUp
            >
              <MyIcon iconName="activeSubscription" color="#fff" />
            </CardDataStats>
            <CardDataStats
              title="Inactive Subscriptions"
              total={
                users.filter(
                  (user) =>
                    (new Date(user.expire_date) <= new Date() &&
                      user.status !== 'pending') ||
                    user?.status == 'ended',
                ).length
              }
              rate="2.59%"
              levelUp
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <circle cx="12" cy="12" r="10" stroke="#fff" />
                <line x1="9" y1="15" x2="15" y2="9" stroke="#fff" />
                <line x1="9" y1="9" x2="15" y2="15" stroke="#fff" />
              </svg>
            </CardDataStats>
          </div>

          <div className="bg-white shadow-lg rounded-lg mt-10">
            <div className="flex flex-col md:flex-row justify-between items-center mb-4 mt-2 bg-[#F7F7F7] p-4 rounded-lg gap-5">
              <div className="bg-gray-200 rounded-lg">
                <p className="font-medium text-md text-black animate-fade-in">
                  Showing {Math.min(endIndex, totalItems)} of {totalItems}{' '}
                  subscriptions
                </p>
              </div>
              <div className="flex flex-col sm:justify-end sm:flex-row sm:items-center w-full sm:w-[70%] sm:space-x-4">
                {/* Search Bar */}
                <div className="relative w-full xl:w-[50%] sm:w-full mb-4 sm:mb-0">
                  <input
                    type="text"
                    placeholder="Search users..."
                    value={searchTerm}
                    onChange={handleSearch}
                    className="p-2 border border-stroke rounded-xl pr-20 w-full bg-white text-gray-800"
                  />
                  <FaSearch className="absolute right-5 top-1/2 transform -translate-y-1/2 text-gray-500" />
                </div>

                {/* Dropdown for filter options */}
                <div className="relative w-full sm:w-1/3 xl:w-[20%]">
                  <Select
                    options={filterOptions}
                    styles={customStyles}
                    placeholder="Actions"
                    className="border border-stroke rounded-xl"
                    onMenuOpen={() => setIsOpen(true)}
                    onMenuClose={() => setIsOpen(false)}
                    value={filterOptions.find(
                      (option) => option.value === filter,
                    )}
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        handleToggleFilter(selectedOption.value);
                      }
                    }}
                  />
                  <span
                    className={`absolute top-0 right-3 h-full flex items-center pointer-events-none transition-transform duration-200 ease-in-out ${
                      isOpen ? 'rotate-180' : 'rotate-0'
                    } `}
                  >
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M7 10L12 15L17 10H7Z" fill="#000" />
                    </svg>
                  </span>
                </div>
              </div>
            </div>

            <div className="overflow-x-auto animate-fade-in scrollbar-custom rounded-lg shadow-md">
              <table className="min-w-full  rounded-lg ">
                <thead>
                  <tr className="hover:bg-gray-50 transition duration-200">
                    <th
                      scope="col"
                      className="py-3 px-6 text-left border-b border-gray whitespace-nowrap"
                    >
                      User
                    </th>
                    <th
                      scope="col"
                      className="py-3 px-6 text-left border-b border-gray whitespace-nowrap"
                    >
                      Plan
                    </th>
                    <th
                      scope="col"
                      className="py-3 px-6 text-left border-b border-gray whitespace-nowrap"
                    >
                      Start Date
                    </th>
                    <th
                      scope="col"
                      className="py-3 px-6 text-left border-b border-gray whitespace-nowrap"
                    >
                      End Date
                    </th>
                    <th
                      scope="col"
                      className="py-3 px-6 text-left border-b border-gray whitespace-nowrap"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="py-3 px-6 text-center border-b border-gray whitespace-nowrap "
                    >
                      View More
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {paginatedUsers?.length > 0 ? (
                    paginatedUsers?.map((user) => (
                      <>
                        <tr
                          key={user.id}
                          className="hover:bg-gray-50 transition duration-200"
                        >
                          <td className="py-3 px-6 border-b  border-gray whitespace-nowrap">
                            {user.first_name} {user.last_name}
                          </td>
                          <td className="py-3 px-6 border-b  border-gray whitespace-nowrap">
                            {user.plan_name}
                          </td>
                          <td className="py-3 px-6 border-b  border-gray whitespace-nowrap">
                            {formatDateTime(user.created_date)}
                          </td>
                          <td className="py-3 px-6 border-b  border-gray whitespace-nowrap">
                            {formatDateTime(user.expire_date)}
                          </td>
                          <td
                            className={`py-3 px-6 whitespace-nowrap border-b border-gray font-semibold ${getStatusClassNames(
                              user?.status,
                            )}`}
                          >
                            {user?.status == null
                              ? 'Pending'
                              : user?.status.charAt(0).toUpperCase() +
                                user?.status.slice(1)}
                          </td>
                          {/* <td
                            className={`py-3 px-6 whitespace-nowrap border-b border-gray font-semibold ${
                              new Date(user.expire_date) > new Date()
                                ? 'text-green-600'
                                : 'text-red-600'
                            }`}
                          >
                            {new Date(user.expire_date) > new Date()
                              ? 'Active'
                              : 'Expired'}
                          </td> */}
                          {/* <td className="py-3 px-6 flex justify-center border-b border-gray whitespace-nowrap ">
                            <button
                              onClick={() => handleViewDetails(user.id)}
                              disabled={loadingSubscription}
                              className="bg-purpletacx flex justify-center items-center gap-1 text-white text-sm rounded-lg py-1.5 w-full hover:bg-yellowtacx transition duration-200"
                            >
                              View
                              <IoEyeOutline
                                style={{
                                  width: '15px',
                                  height: '15px',
                                }}
                              />
                            </button>
                          </td> */}
                          <td
                            className={`flex justify-center py-3 px-6 border-b border-gray  whitespace-nowrap`}
                          >
                            <button
                              className={`text-white bg-[#F7F7F7] border border-gray rounded-lg px-2 py-2 text-center inline-flex items-center ${user?.status == null ? 'cursor-not-allowed' : ''} ${activeDescriptionId === user.id ? 'transform rotate-180' : ''}`}
                              onClick={() => handleViewDetails(user.id)}
                              disabled={
                                loadingSubscription || user?.status === null
                              }
                            >
                              <svg
                                className="w-6 h-6"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                              >
                                <path d="M11.9997 13.1714L16.9495 8.22168L18.3637 9.63589L11.9997 15.9999L5.63574 9.63589L7.04996 8.22168L11.9997 13.1714Z"></path>
                              </svg>
                            </button>
                          </td>
                        </tr>
                        <tr
                          id={`${user.id}`}
                          className={
                            activeDescriptionId === user.id
                              ? 'border-b border-gray bg-[#F7F7F7]'
                              : 'hidden py-4 px-4 border-t border-gray-200'
                          }
                        >
                          <td colSpan={6} className="p-4 px-6">
                            <div className="grid grid-cols-3 gap-2">
                              {/* <p>
                                <span className="font-bold">Name:</span>{' '}
                                {userSubscriptionDetails?.plan_name}
                              </p> */}
                              {/* <p>
                                <span className="font-bold">Status: </span>

                                {userSubscriptionDetails?.status == null
                                  ? 'Pending'
                                  : userSubscriptionDetails?.status
                                      .charAt(0)
                                      .toUpperCase() +
                                    userSubscriptionDetails?.status.slice(1)}
                              </p> */}
                              <p>
                                <span className="font-bold">Amount:</span>{' '}
                                {userSubscriptionDetails?.currency == 'inr'
                                  ? '₹'
                                  : '$'}
                                {userSubscriptionDetails?.amount_total}
                              </p>
                              <p>
                                <span className="font-bold"> User Limit: </span>
                                {userSubscriptionDetails?.user_limit}
                              </p>
                              <p>
                                <span className="font-bold">Period: </span>
                                {userSubscriptionDetails?.subscription_period
                                  .charAt(0)
                                  .toUpperCase() +
                                  userSubscriptionDetails?.subscription_period.slice(
                                    1,
                                  )}{' '}
                              </p>
                              {/* <p className="whitespace-nowrap">
                                <span className="font-bold">Start date: </span>
                                {userSubscriptionDetails?.created_date
                                  ? new Date(
                                      userSubscriptionDetails?.created_date,
                                    ).toLocaleString('en-GB', {
                                      year: 'numeric',
                                      month: 'short',
                                      day: 'numeric',
                                      // hour: '2-digit',
                                      // minute: '2-digit',
                                      // hour12: true,
                                    })
                                  : 'N/A'}
                              </p>
                              <p className="whitespace-nowrap">
                                <span className="font-bold">End date: </span>
                                {userSubscriptionDetails?.expire_date
                                  ? new Date(
                                      userSubscriptionDetails?.expire_date,
                                    ).toLocaleString('en-GB', {
                                      year: 'numeric',
                                      month: 'short',
                                      day: 'numeric',
                                    })
                                  : 'N/A'}
                              </p> */}
                            </div>
                          </td>
                        </tr>
                      </>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={6} className="py-4 text-center">
                        No subscriptions found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {users?.length > 4 && (
                <div className="mt-4">
                  <Pagination
                    totalPages={totalPages}
                    setCurrentPage={setCurrentPage}
                    startIndex={startIndex}
                    endIndex={endIndex}
                    totalItems={totalItems}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </DefaultLayout>
  );
};

export default UsersSubscriptions;
