import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { updateUserNotification, updateAdminNotification } from '../../api/Api';
import { useSelector } from 'react-redux';
import { selectUser } from '../../store/slice/Userslice';
import {
  getAdminNotification,
  getUserNotification,
} from '../../api/NotificationsApi';
import { IoMdNotifications } from 'react-icons/io';

const DropdownNotification = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  // const [notifying, setNotifying] = useState(true);
  const [notifications, setNotification] = useState<any[]>([]);
  const trigger = useRef<any>(null);
  const dropdown = useRef<any>(null);
  const userDetails = useSelector(selectUser);
  const isAdmin = userDetails?.user.user_role === 'Admin';
  const userRole =
    userDetails?.user.user_role === 'Team mate' ? 'team_mate' : 'user';

  const featchNotification = async () => {
    try {
      if (isAdmin) {
        const response = await getAdminNotification();
        setNotification(response?.data.data || []);
      } else {
        const response = await getUserNotification();
        setNotification(response?.data.data || []);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    featchNotification();
  }, []);

  const handleNotificationClick = async (id: number) => {
    try {
      if (isAdmin) {
        await updateAdminNotification(id);
      } else {
        const dataTosend = {
          id: id,
          role: userRole,
        };
        await updateUserNotification(dataTosend); // API call to update notification status
      }
      featchNotification(); // Refresh notifications after updating
    } catch (error) {
      console.error('Error updating notification:', error);
    }
  };

  useEffect(() => {
    const clickHandler = ({ target }: MouseEvent) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });
  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }: KeyboardEvent) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });
  const formatTimestamp = (timestamp: any) => {
    const now = new Date();
    const createdAt = new Date(timestamp);
    const diffInSeconds = Math.floor(
      (now.getTime() - createdAt.getTime()) / 1000,
    );

    if (diffInSeconds < 60) {
      return `${diffInSeconds}sec ago`;
    } else if (diffInSeconds < 3600) {
      const diffInMinutes = Math.floor(diffInSeconds / 60);
      return `${diffInMinutes}m ago`;
    } else if (diffInSeconds < 86400) {
      const diffInHours = Math.floor(diffInSeconds / 3600);
      return `${diffInHours}h ago`;
    } else {
      return createdAt.toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      });
    }
  };
  return (
    <div id="notification" className="relative">
      <Link
        ref={trigger}
        onClick={() => {
          // setNotifying(false);
          setDropdownOpen(!dropdownOpen);
        }}
        to="#"
        className="relative flex h-[3.125rem] w-[3.125rem] items-center justify-center rounded-full border-[2.5px] border-stroke bg-[#f3f0f074] hover:text-customHover dark:border-strokedark dark:bg-meta-4 dark:text-white"
      >
        {notifications.length > 0 && (
          <span
            className={`absolute -top-0.5 right-0 z-1 h-2 w-2 rounded-full bg-meta-1`}
          >
            <span className="absolute -z-1 inline-flex h-full w-full animate-ping rounded-full bg-meta-1 opacity-75"></span>
          </span>
        )}

        {/* New Bell Icon from Heroicons */}
        <IoMdNotifications
          style={{
            height: '22px',
            width: '22px',
          }}
        />
      </Link>

      <div
        ref={dropdown}
        onFocus={() => setDropdownOpen(true)}
        onBlur={() => setDropdownOpen(false)}
        className={`absolute -right-27 mt-2.5 flex h-90 w-75 flex-col rounded-lg border border-stroke bg-white shadow-default sm:right-0 sm:w-80 ${
          dropdownOpen === true ? 'block' : 'hidden'
        }`}
      >
        <div className="px-4.5 py-3">
          <h5 className="text-md font-bold text-black">Notifications</h5>
        </div>
        <ul className="flex h-auto flex-col overflow-y-auto scrollbar-custom">
          {notifications.length > 0 ? (
            notifications.map((notification) => (
              <li key={notification.id}>
                <Link
                  className="flex flex-col gap-1 border-t border-stroke px-4.5 py-3 hover:bg-gray-2"
                  to="#"
                  onClick={() => handleNotificationClick(notification.id)} // Call the handler with notification id
                >
                  <p className="text-sm font-medium">
                    <span className="text-black">{notification.message}</span>
                  </p>
                  {/* <p className="text-xs">
                    {new Date(notification.created_at).toLocaleDateString()}
                  </p> */}
                  <p className="text-sm">
                    {formatTimestamp(notification.created_at)}
                  </p>
                </Link>
              </li>
            ))
          ) : (
            <li className="px-4.5 py-3 text-sm text-gray-500">
              No notifications found
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};
export default DropdownNotification;
