import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import { SignOut } from './api/Api';

interface FormState {
  firstName: string;
  lastName: string;
  companyName: string;
  mailId: string;
  cellPhoneNumber: string;
  officeNumber: string;
  websiteUrl: string;
  faxNumber: string;
  industry: string;
  timeZone: string;
  country: string;
  address: string;
  aptSuite: string;
  state: string;
  city: string;
  postalCode: string;
  profilePicture: string | null;
  jobTitles: string[];
}
interface FormState1 {
  aboutBusiness: string;
  youTubeLink: string;
  bookingLink: string;
  socialLinks: { [key: string]: string }[];
  otherLinks: { [key: string]: string }[];
  licenseNumber: string;
}

interface AuthContextType {
  isAuthenticated: boolean;
  login: (token: string) => void;
  logout: () => void;
  savedFormData: FormState | null;
  setSavedFormData: React.Dispatch<React.SetStateAction<FormState | null>>;
  updateSavedFormData: (data: FormState) => void;
  savedFormDataAbout: FormState1 | null;
  setSavedFormDataAbout: React.Dispatch<
    React.SetStateAction<FormState1 | null>
  >;
  canvasItems: any;
  setCanvasItems: React.Dispatch<React.SetStateAction<any | null>>;
  redoStack: any;
  setRedoStack: React.Dispatch<React.SetStateAction<any | null>>;
  selectedItem: any;
  setSelectedItem: React.Dispatch<React.SetStateAction<any | null>>;
  tourStep: number;
  setTourStep: Dispatch<SetStateAction<number>>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [savedFormData, setSavedFormData] = useState<FormState | null>(null);
  const [canvasItems, setCanvasItems] = useState([]);
  const [redoStack, setRedoStack] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [tourStep, setTourStep] = useState(0); // Initial step is 0

  const [savedFormDataAbout, setSavedFormDataAbout] =
    useState<FormState1 | null>(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  const login = (token: string) => {
    
    localStorage.setItem('token', token);
    // Cookies.set('token', token, { expires: 7 });
    setIsAuthenticated(true);
  };

  const logout = async () => {
    localStorage.removeItem('token');
    // Cookies.remove('token');
    setIsAuthenticated(false);
    await SignOut();
  };

  const updateSavedFormData = (data: FormState) => {
    setSavedFormData(data);
  };
 

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        login,
        logout,
        savedFormData,
        setSavedFormData,
        updateSavedFormData,
        savedFormDataAbout,
        setSavedFormDataAbout,
        canvasItems,
        setCanvasItems,
        redoStack,
        setRedoStack,
        selectedItem,
        setSelectedItem,
        tourStep, 
        setTourStep
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
// Custom hook to use the TourContext in other components
export const useTour = () => {
  const context = useContext(AuthContext);    
  if (!context) {
    throw new Error('useTour must be used within a AuthProvider');
  }
  return context;
};


export default AuthContext;
