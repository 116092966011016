import React, { useState, useEffect } from 'react';
import MyIcon from '../cardeditor/icons/Myicon'; // Import your custom icon
import { CreateQuerySendToAdmin } from '../../api/Api';
import Loader from '../../common/loader/Customloader.tsx'

// Interface for the form data
interface FormData {
  name: string;
  phone: string;
  email: string;
  subject: string;
  message: string;
}

interface UserData {
  user: {
    id: string;
    first_name: string;
    last_name: string;
    phone_number: string;
    email: string;
  } | null;
}

const IconModalComponent: React.FC<UserData> = ({ user }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    name: 'Guest',
    phone: 'N/A',
    email: 'N/A',
    subject: '',
    message: '',
  });

  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [errorMessages, setErrorMessages] = useState<{
    subject?: string;
    message?: string;
  }>({}); // Error messages
  const [successMessage, setSuccessMessage] = useState(''); // Success message
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false); // Success modal state

  // Update formData when user prop changes
  useEffect(() => {
    if (user) {
      setFormData({
        name: `${user.first_name} ${user.last_name}`,
        phone: user.phone_number || 'N/A',
        email: user.email,
        subject: '',
        message: '',
      });
    }
  }, [user]);

  // Handle input change
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Clear specific error message when the user starts typing
    if (name === 'subject' || name === 'message') {
      setErrorMessages((prev) => ({ ...prev, [name]: undefined }));
    }
  };

  const handleModalClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (e.target === e.currentTarget) {
      setIsModalOpen(false);
    }
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setErrorMessages({});
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const newErrorMessages: { subject?: string; message?: string } = {};
    if (!formData.subject) {
      newErrorMessages.subject = 'Subject cannot be empty.';
    }
    if (!formData.message) {
      newErrorMessages.message = 'Message cannot be empty.';
    }
    if (Object.keys(newErrorMessages).length > 0) {
      setErrorMessages(newErrorMessages);
      return;
    }

    setErrorMessages({});
    setSuccessMessage('');
    setIsLoading(true)

    try {
      // Create the message structure
      const messagePayload = {
        messages: [
          {
            type: 'user',
            message: formData.message,
            timestamp: new Date().toISOString(), // Current timestamp
          },
        ],
      };

      const sendMessageToAdmin = await CreateQuerySendToAdmin({
        ...formData,
        message: messagePayload, // Send the formatted message
      });
      if (sendMessageToAdmin && sendMessageToAdmin.status === 200) {
        setSuccessMessage('Your query has been submitted successfully!');
        setIsSuccessModalOpen(true);
      }

      setFormData({
        ...formData,
        subject: '',
        message: '',
      });

      setIsModalOpen(false); // Close the main modal after submission
    } catch (error) {
      setErrorMessages({
        subject: 'Failed to submit the form. Please try again later.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Close success modal
  const handleSuccessClose = () => {
    setIsSuccessModalOpen(false);
    setSuccessMessage(''); // Clear success message
  };

  return (
    <div id="support" className="relative">
      <div
        className="flex h-[3.125rem] w-[3.125rem] items-center justify-center rounded-full border-[2.5px] border-stroke bg-[#f3f0f074] hover:text-customHover cursor-pointer"
        onClick={() => setIsModalOpen(true)}
      >
        <MyIcon iconName="SupportNavBar" color="currentColor" />
      </div>

      {/* Modal for Form */}
      {isModalOpen && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-[#59425580] bg-opacity-70 z-50"
          onClick={handleModalClick}
        >
          <div
            className="bg-white rounded-lg max-w-sm sm:max-w-md md:max-w-lg w-full mx-4 slideInFromTop-animate text-black"
            onClick={(e) => e.stopPropagation()}
          >
            <h3 className="text-xl font-bold border-b-2 border-[#F1F5F9] p-4 sm:p-6 ">
              Send Your Query
            </h3>

            <form className="space-y-4 p-4 sm:p-6" onSubmit={handleSubmit}>
              <div>
                <label className="block text-sm font-bold mb-1 ">Name</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  readOnly
                  className="w-full px-3 py-2 border rounded-md bg-gray-200 cursor-not-allowed "
                />
              </div>
              <div>
                <label className="block text-sm font-bold mb-1 ">
                  Phone Number
                </label>
                <input
                  type="text"
                  name="phone"
                  value={formData.phone || 'N/A'}
                  readOnly
                  className="w-full px-3 py-2 border rounded-md bg-gray-200 cursor-not-allowed "
                />
              </div>
              <div>
                <label className="block text-sm font-bold mb-1 ">Email</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  readOnly
                  className="w-full px-3 py-2 border rounded-md bg-gray-200 cursor-not-allowed "
                />
              </div>
              <div>
                <label className="block text-sm font-bold mb-1 ">Subject</label>
                <input
                  type="text"
                  name="subject"
                  value={formData.subject}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border rounded-md "
                  placeholder="Enter the subject"
                />
                {/* Display error message for subject */}
                {errorMessages.subject && (
                  <div className="text-red-600  text-sm mt-1">
                    {errorMessages.subject}
                  </div>
                )}
              </div>
              <div>
                <label className="block text-sm font-bold mb-1 ">Message</label>
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border rounded-md "
                  placeholder="Enter your message"
                ></textarea>
                {errorMessages.message && (
                  <div className="text-red-600  text-sm mt-1">
                    {errorMessages.message}
                  </div>
                )}
              </div>

              <div className="flex justify-end space-x-2 mt-4">
                <button
                  type="button"
                  onClick={handleClose}
                  className="px-4 py-2 bg-red-600 text-white rounded-md "
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={isLoading}
                  className="px-4 py-2 bg-[#594255] text-white rounded-md "
                >
                  {isLoading ? 'Submitting...' : 'Submit'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
         {isLoading && (
        <div className="flex justify-center p-4">
          <Loader /> {/* Your loader component */}
        </div>
      )}

      {isSuccessModalOpen && (
        <>
          <div className="fixed grid content-center top-0 bottom-0 left-0 z-[999999] w-screen h:screen bg-[#59425580] bg-opacity-70  fade-in-animate">
            <div className="flex m-auto justify-center rounded-lg relative w-[90%] md:w-1/2 xl:w-1/3 slideInFromTop-animate">
              <div
                className="bg-white rounded-lg max-w-sm w-full mx-4 p-4 flex flex-col text-center"
                onClick={(e) => e.stopPropagation()}
              >
                <h3 className="text-xl font-bold text-purpletacx">Success!</h3>
                <p className="mt-2 text-black">{successMessage}</p>
                <button
                  className="mt-4 px-4 py-2 bg-[#594255] text-white rounded-md"
                  onClick={handleSuccessClose}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default IconModalComponent;
