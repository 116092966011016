import React, { ComponentType, useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { getSubscription, ProfileData } from './api/Api';
import Loader from './common/loader/Index';
import { useAuth } from './Authcontext';
import { useDispatch } from 'react-redux';
import { setSubscription, setUser } from './store/slice/Userslice';

interface PrivateRouteProps {
  element: ComponentType<any>;
  [key: string]: any; // This allows any other props
}

const PublicRoute: React.FC<PrivateRouteProps> = ({
  element: Component,
  ...rest
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasSubscription, setHasSubscription] = useState(false);
  const { logout } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const subscriptionDetails = useSelector(selectSubscription);
  // const selectUserDetails = useSelector(selectUser);

  useEffect(() => {
    const checkSubscriptionStatus = async () => {
      try {
        const userData = await ProfileData();
        if (userData?.data.success) {
          if (userData.data.user.error) {
            logout();
            navigate('/');
            return;
          }
          dispatch(setUser({ user: userData?.data.user }));

          if (userData.data.user.user_role === 'Team mate') {
            setHasSubscription(userData.data.user.user_role === 'Team mate');
          } else {
            const response = await getSubscription();
            if (response?.data.success) {
              setHasSubscription(
                response?.data?.subscription?.status === 'active',
              );
              dispatch(
                setSubscription({ subscription: response?.data.subscription }),
              );
            } else {
              setHasSubscription(false);
            }
          }
        } else {
          logout();
          navigate('/');
        }
      } catch (error) {
        console.error('Error checking subscription status:', error);
      } finally {
        setIsLoading(false);
      }
    };

    checkSubscriptionStatus();
  }, [logout, navigate, dispatch]);

  if (isLoading) {
    return <Loader />;
  }

  return hasSubscription ? (
    <Navigate to="/my-profile" />
  ) : (
    <Component {...rest} />
  );
};

export default PublicRoute;
