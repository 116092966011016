import React, { useEffect, useState } from 'react';
import { Icon } from '@iconify/react/dist/iconify.js';
import { SocialIcon } from 'react-social-icons';
interface Field {
  id: number;
  label: string;
  type: string;
  value: string;
  placeholder?: string;
  required?: boolean;
}
interface ButtonStyle {
  label: string;
  alignment: string;
  backgroundColor: string;
  fontSize?: string;
  textColor?: any;
}
interface TypographyProps {
  type: string;
  family: string;
  nameFontSize: string;
  typographyWeight: string;
  typographyStyle: string;
  lineHeight: string;
}
interface iconProps {
  name: string;
  iconType: string;
  iconName: string;
  fgColor: string;
  bgColor: string;
  show: string;
}
interface globalStylingProps {
  fontColor: string;
  textColor: string;
  fontSize: string;
  pmColor: string;
  seColor: string;
  groundColor: string;
  status: boolean;
  ignoreFieldArray: any;
  globalChangesList: any;
  buttonPadding: any;
  buttonMargin: any;
  buttonRadius: any;
}
interface FormBlockProps {
  id: string;
  fields: Field[];
  labelShow: boolean;
  buttonSize: string;
  buttonWidth: string;
  backgroundColor: string;
  textColor: string;
  buttonStyle: ButtonStyle;
  buttonLabel: string;
  icon: iconProps[];
  iconPosition: string;
  iconSpacing: string;
  rowGap: string;
  labelSpacing: string;
  labelTextColor: string;
  labelTextTypography: TypographyProps;
  fieldTextColor: string;
  fieldTextTypography: TypographyProps;
  buttonPosition: string;
  buttonAlignmenrt: string;
  buttonBackgroundColor: string;
  buttonFontColor: string;
  borderType: string;
  borderColor: string;
  borderWidth: any;
  borderRadius: any;
  globalStyling: globalStylingProps;
}
const FormBlock: React.FC<FormBlockProps> = ({
  id,
  fields,
  labelShow,
  buttonSize,
  buttonWidth,
  buttonLabel,
  icon,
  iconPosition,
  iconSpacing,
  rowGap,
  labelSpacing,
  labelTextColor,
  labelTextTypography,
  fieldTextColor,
  fieldTextTypography,
  buttonPosition,
  buttonAlignmenrt,
  buttonBackgroundColor,
  buttonFontColor,
  borderType,
  borderColor,
  borderWidth,
  borderRadius,
  globalStyling,
}) => {
  const api_url = import.meta.env.VITE_API_URL;
  let handleGlobalStyling = () => {
    let data = {
      icon: icon,
      buttonBackgroundColor: globalStyling.groundColor,
      buttonFontColor: globalStyling.fontColor,
      // buttonFontSize: globalStyling.fontSize,
      labelTextColor: globalStyling.textColor,
      fieldTextColor: globalStyling.textColor,
      labeltextFontSize: globalStyling.fontSize,
      fieldtextFontSize: globalStyling.fontSize,
    };

    if (globalStyling.status) {
      if (globalStyling?.ignoreFieldArray?.length === 0) {
        if (globalStyling?.globalChangesList.includes('pmColor')) {
          data.icon.filter((item: any) => {
            item.bgColor = globalStyling.pmColor;
          });
        }
      } else {
        data.icon = icon.filter((item: any, index: any) => {
          if (
            globalStyling?.ignoreFieldArray?.includes(
              `icon-${id}-${index}-bgColor`,
            )
          ) {
            item.bgColor = item.bgColor;
            return item;
          } else {
            item.bgColor = globalStyling.pmColor;
            return item;
          }
        });
      }
      if (globalStyling?.ignoreFieldArray?.length === 0) {
        if (globalStyling?.globalChangesList.includes('seColor')) {
          data.icon.filter((item: any) => {
            item.fgColor = globalStyling.seColor;
          });
        }
      } else {
        data.icon = icon.filter((item: any, index: any) => {
          if (
            globalStyling?.ignoreFieldArray?.includes(
              `icon-${id}-${index}-fgColor`,
            )
          ) {
            item.fgColor = item.fgColor;
            return item;
          } else {
            item.fgColor = globalStyling.seColor;
            return item;
          }
        });
      }
      if (globalStyling?.ignoreFieldArray?.length === 0) {
        if (globalStyling?.globalChangesList.includes('groundColor')) {
          data.buttonBackgroundColor = globalStyling.groundColor;
        }
      } else {
        if (
          globalStyling?.ignoreFieldArray?.includes('buttonBackgroundColor')
        ) {
          data.buttonBackgroundColor = buttonBackgroundColor;
        } else {
          data.buttonBackgroundColor = globalStyling.groundColor;
        }
      }
      if (globalStyling?.ignoreFieldArray?.length === 0) {
        if (globalStyling?.globalChangesList.includes('textColor')) {
          data.labelTextColor = globalStyling.textColor;
          data.fieldTextColor = globalStyling.textColor;
        }
      } else {
        if (globalStyling?.ignoreFieldArray?.includes('labelTextColor')) {
          data.labelTextColor = labelTextColor;
        } else {
          data.labelTextColor = globalStyling.textColor;
        }
        if (globalStyling?.ignoreFieldArray?.includes('fieldTextColor')) {
          data.fieldTextColor = fieldTextColor;
        } else {
          data.fieldTextColor = globalStyling.textColor;
        }
      }
      if (globalStyling?.ignoreFieldArray?.length === 0) {
        if (globalStyling?.globalChangesList.includes('fontColor')) {
          data.buttonFontColor = globalStyling.fontColor;
        }
      } else {
        if (globalStyling?.ignoreFieldArray?.includes('buttonFontColor')) {
          data.buttonFontColor = buttonFontColor;
        } else {
          data.buttonFontColor = globalStyling.fontColor;
        }
      }
      if (globalStyling?.ignoreFieldArray?.length === 0) {
        if (globalStyling?.globalChangesList.includes('fontSize')) {
          data.labeltextFontSize = globalStyling.fontSize;
          data.fieldtextFontSize = globalStyling.fontSize;
          // data.buttonFontSize = globalStyling.fontSize;
        }
      } else {
        if (
          globalStyling?.ignoreFieldArray?.includes(
            'labelTextTypography.nameFontSize',
          )
        ) {
          data.labeltextFontSize = labelTextTypography.nameFontSize;
        } else {
          data.labeltextFontSize = globalStyling.fontSize;
        }
        if (
          globalStyling?.ignoreFieldArray?.includes(
            'fieldTextTypography.nameFontSize',
          )
        ) {
          data.fieldtextFontSize = fieldTextTypography.nameFontSize;
        } else {
          data.fieldtextFontSize = globalStyling.fontSize;
        }
      }
    } else {
      data.icon = icon.filter((item: any, index: any) => {
        if (
          globalStyling?.ignoreFieldArray?.includes(
            `icon-${id}-${index}-bgColor`,
          )
        ) {
          item.bgColor = item.bgColor;
          return item;
        } else {
          return item;
        }
      });
      data.icon = icon.filter((item: any, index: any) => {
        if (
          globalStyling?.ignoreFieldArray?.includes(
            `icon-${id}-${index}-fgColor`,
          )
        ) {
          item.fgColor = item.fgColor;
          return item;
        } else {
          return item;
        }
      });
      if (globalStyling?.ignoreFieldArray?.includes('buttonBackgroundColor')) {
        data.buttonBackgroundColor = buttonBackgroundColor;
      }
      if (globalStyling?.ignoreFieldArray?.includes('buttonFontColor')) {
        data.buttonFontColor = buttonFontColor;
      }
      if (globalStyling?.ignoreFieldArray?.includes('labelTextColor')) {
        data.labelTextColor = labelTextColor;
      }
      if (globalStyling?.ignoreFieldArray?.includes('fieldTextColor')) {
        data.fieldTextColor = fieldTextColor;
      }
      if (
        globalStyling?.ignoreFieldArray?.includes(
          'labelTextTypography.nameFontSize',
        )
      ) {
        data.labeltextFontSize = labelTextTypography.nameFontSize;
      }
      if (
        globalStyling?.ignoreFieldArray?.includes(
          'fieldTextTypography.nameFontSize',
        )
      ) {
        data.fieldtextFontSize = fieldTextTypography.nameFontSize;
      }
    }
    return data;
  };
  useEffect(() => {
    let data = handleGlobalStyling();
    setGlobalStylingData(data);
  }, [globalStyling]);
  const [globalStylingData, setGlobalStylingData] = useState(() =>
    handleGlobalStyling(),
  );
  return (
    <div className="form-block p-2">
      {fields?.map((field) => (
        <div
          key={field.id}
          className="form-group"
          style={{
            marginBottom: `${rowGap}px`,
          }}
        >
          <div className="">
            {labelShow && (
              <label
                htmlFor={`field-${field.id}`}
                style={{
                  color: globalStylingData?.labelTextColor,
                  fontFamily: labelTextTypography.family,
                  fontStyle: labelTextTypography.typographyStyle,
                  lineHeight: labelTextTypography.lineHeight,
                  fontSize: globalStylingData.labeltextFontSize,
                }}
                className={`${labelTextTypography.typographyWeight}`}
              >
                {field.label}
                {field.required && <span className="text-red-500 ml-1">*</span>}
              </label>
            )}
            <div>
              {field.type === 'textarea' ? (
                <textarea
                  id={`field-${field.id}`}
                  name={`field-${field.id}`}
                  value={field.value}
                  placeholder={field.placeholder}
                  className={`w-full border border-black outline-none focus:border-black rounded-sm p-2 ${fieldTextTypography.typographyWeight}`}
                  style={{
                    marginTop: `${labelSpacing}px`,
                    color: globalStylingData?.fieldTextColor,
                    fontFamily: fieldTextTypography.family,
                    fontStyle: fieldTextTypography.typographyStyle,
                    lineHeight: fieldTextTypography.lineHeight,
                    fontSize: globalStylingData.fieldtextFontSize,
                  }}
                />
              ) : (
                <input
                  type={field.type}
                  id={`field-${field.id}`}
                  name={`field-${field.id}`}
                  value={field.value}
                  placeholder={field.placeholder}
                  className={`w-full border border-black outline-none focus:border-black rounded-sm p-2 ${fieldTextTypography.typographyWeight}`}
                  style={{
                    marginTop: `${labelSpacing}px`,
                    color: globalStylingData?.fieldTextColor,
                    fontFamily: fieldTextTypography.family,
                    fontStyle: fieldTextTypography.typographyStyle,
                    lineHeight: fieldTextTypography.lineHeight,
                    fontSize: globalStylingData.fieldtextFontSize,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      ))}
      {/* Submit Button */}
      <div className={`flex ${buttonPosition}  mt-4`}>
        <button
          type="submit"
          style={{
            backgroundColor: globalStylingData?.buttonBackgroundColor,
            width: `${buttonWidth}%`,
            gap: `${iconSpacing}px`,
            borderWidth: `${borderWidth?.[0]}px ${borderWidth?.[1]}px ${borderWidth?.[2]}px ${borderWidth?.[3]}px`,
            borderStyle: borderType,
            borderColor: borderColor,
            borderRadius: `${borderRadius?.[0]}px ${borderRadius?.[1]}px ${borderRadius?.[2]}px ${borderRadius?.[3]}px`,
          }}
          className={`${buttonSize} rounded flex items-center ${buttonAlignmenrt} ${iconPosition}`}
        >
          {globalStylingData?.icon[0].show == 'icon' &&
            globalStylingData?.icon.length > 0 &&
            globalStylingData?.icon.map((link: any) => (
              <>
                {link.iconType === 'local' && (
                  <Icon
                    icon={link.iconName}
                    className={` rounded`}
                    color={link.fgColor}
                  />
                )}
                {link.iconType === 'browse' && (
                  <SocialIcon
                    url=""
                    fgColor={link.fgColor}
                    as="div"
                  />
                )}
                {link.iconType === 'image' && (
                  <img
                    src={`${api_url}/public/cards/images/${link.iconName}`}
                    alt={link.name}
                    className=" rounded-full object-cover"
                  />
                )}
              </>
            ))}
          <p
            style={{
              color: globalStylingData?.buttonFontColor,
              wordBreak: 'break-word',
            }}
          >
            {buttonLabel}
          </p>
        </button>
      </div>
    </div>
  );
};
export default FormBlock;
