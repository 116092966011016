import React, { useState, useRef, useEffect } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import MyIcon from '../cardeditor/icons/Myicon';
import Tacx from './../../images/logo/Tacx.svg';
import { useSelector } from 'react-redux';
import { selectUser, selectSubscription } from '../../store/slice/Userslice';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

interface SidebarProps {
  sidebarOpen: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Sidebar: React.FC<SidebarProps> = ({ sidebarOpen, setSidebarOpen }) => {
  const location = useLocation();
  const { pathname } = location;
  const trigger = useRef<any>(null);
  const sidebar = useRef<any>(null);
  const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? true : storedSidebarExpanded === 'true',
  );
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const userDetails = useSelector(selectUser);
  const isTeamMate = userDetails?.user?.user_role === 'Team mate';
  const isAdmin = userDetails?.user?.user_role === 'Admin';
  const subscriptionDetails = useSelector(selectSubscription);
  const isUserLimit = subscriptionDetails?.user_limit === 0;

  useEffect(() => {
    const clickHandler = ({ target }: MouseEvent) => {
      if (!sidebar.current || !trigger.current) return;
      if (sidebar.current.contains(target) || trigger.current.contains(target))
        return;
      setSidebarOpen(false); // Close sidebar when clicking outside
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  }, []);

  useEffect(() => {
    const keyHandler = ({ keyCode }: KeyboardEvent) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  }, [sidebarOpen]);

  useEffect(() => {
    localStorage.setItem('sidebar-expanded', sidebarExpanded.toString());
    if (sidebarExpanded) {
      document.querySelector('body')?.classList.add('sidebar-expanded');
    } else {
      document.querySelector('body')?.classList.remove('sidebar-expanded');
    }
  }, [sidebarExpanded]);

  const toggleSidebar = () => {
    setSidebarVisible((prev) => !prev);
    setSidebarOpen((prev: any) => !prev);
  };

  const handleExpandToggle = () => {
    const isPhoneScreen = window.innerWidth <= 1023;
    if (isPhoneScreen) {
      setSidebarOpen(!sidebarOpen);
      setSidebarExpanded(true);
    } else {
      setSidebarExpanded((prev) => !prev);
    }
  };

  return (
    <>
      <aside
        ref={sidebar}
        id="sidebar"
        className={`absolute left-0 top-0 z-[9999] flex h-screen ${
          window.innerWidth <= 1023
            ? sidebarOpen
              ? 'w-72.5'
              : 'w-0 lg:w-72.5'
            : sidebarExpanded
              ? 'w-72.5'
              : 'w-0 sm:w-16'
        } flex-col overflow-y-hidden bg-purpletacx duration-300 ease-linear dark:bg-boxdark lg:static lg:translate-x-0 ${
          sidebarVisible ? 'translate-x-0' : '-translate-x-full'
        }`}
      >
        <div className="flex flex-col">
          {sidebarExpanded && (
            <>
              <div className="flex flex-row w-full pt-4 pr-0">
                <div className="flex w-[92%] justify-center relative pt-4">
                  {isAdmin ? (
                    <Link
                      to={'/admin/dashboard'}
                      className="flex justify-center items-center w-[40%]"
                    >
                      <img
                        src={Tacx}
                        alt="Logo"
                        className={`transition-opacity duration-300 ${sidebarExpanded ? 'block' : 'hidden'}`}
                      />
                    </Link>
                  ) : (
                    <Link
                      to={'/my-profile'}
                      className="flex justify-center items-center w-[40%]"
                    >
                      <img
                        src={Tacx}
                        alt="Logo"
                        className={`transition-opacity duration-300 ${sidebarExpanded ? 'block' : 'hidden'}`}
                      />
                    </Link>
                  )}
                </div>
                <div
                  data-tooltip-id="sidebar-shrink"
                  className="w-[8%] h-12 flex justify-center self-center cursor-pointer items-center bg-yellowtacx shadow-cardBlack rounded-tl-sm rounded-bl-sm"
                  onClick={handleExpandToggle}
                >
                  <MyIcon iconName="arrowLeft" color="black" />
                </div>
              </div>
              <ReactTooltip
                id="sidebar-shrink"
                place="right"
                content="Shrink Sidebar"
              />
            </>
          )}
          {!sidebarVisible && (
            <div
              className="w-full flex justify-center items-center h-14 cursor-pointer"
              onClick={toggleSidebar}
            >
              <div className="rounded-[50px] bg-yellowtacx border-[1px] shadow-cardBlack p-2">
                <MyIcon iconName="arrowRight" color="black" />
              </div>
            </div>
          )}

          <div
            className={`flex flex-col w-full pr-0 justify-between ${sidebarExpanded ? 'ps-7' : ''}`}
          >
            <div className="no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear">
              {!sidebarExpanded && (
                <div
                  className="flex items-center justify-center relative py-4"
                  onClick={handleExpandToggle}
                >
                  <div
                    data-tooltip-id="sidebar-expand"
                    className="rounded-[50px] bg-yellowtacx border-[1px] shadow-cardBlack p-2 cursor-pointer"
                  >
                    <MyIcon iconName="arrowRight" color="black" />
                  </div>
                </div>
              )}
              <nav className={`mt-5 lg:mt-7`}>
                <ul className={`mb-6 flex flex-col gap-1.5`}>
                  {[
                    {
                      to: '/my-profile',
                      iconName: 'profile',
                      label: 'My Profile',
                    },
                    { to: '/my-card', iconName: 'myCard', label: 'My Cards' },
                    {
                      to: '/analytics',
                      iconName: 'analytics',
                      label: 'Analytics',
                    },
                    {
                      to: '/users',
                      iconName: 'users',
                      label: 'Users',
                      shouldHide: isUserLimit,
                    },
                    {
                      to: '/account-billing',
                      iconName: 'accountandbilling',
                      label: 'Accounts and Billings',
                    },
                    {
                      to: '/visitors',
                      iconName: 'message',
                      label: 'Visitors Details',
                    },
                    {
                      to: '/integration',
                      iconName: 'plugged',
                      label: 'Integration',
                    },
                    {
                      to: '/my-activity',
                      iconName: 'myActivity',
                      label: 'My Activity',
                    },
                    {
                      to: '/user/support',
                      iconName: 'SupportSideBar',
                      label: 'Support Details',
                    },
                    { to: '/settings', iconName: 'setting', label: 'Settings' },
                  ]
                    .filter((item) => {
                      if (isTeamMate) {
                        return (
                          item.to === '/my-profile' ||
                          item.to === '/my-card' ||
                          item.to === '/my-activity' ||
                          item.to === '/analytics' ||
                          item.to === '/settings'
                        );
                      }
                      if (isAdmin) {
                        return ![
                          '/my-card',
                          '/analytics',
                          '/users',
                          '/account-billing',
                          '/visitors',
                          '/integration',
                          '/settings',
                          '/my-profile',
                          '/my-activity',
                          '/user/support',
                        ].includes(item.to);
                      }
                      return !(item.shouldHide && isUserLimit);
                    })
                    .map((item, index) => (
                      <li
                        key={index}
                        className="flex items-center transition-opacity duration-300 w-[100%] mb-1"
                      >
                        <NavLink
                          to={item.to}
                          className={`w-[240px] group relative flex items-center gap-2.5 rounded-2xl py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-[#fff] hover:text-purpletacx dark:hover:bg-meta-4 ${
                            pathname.includes(item.to)
                              ? 'bg-[#fff] dark:bg-meta-4 !text-purpletacx'
                              : ''
                          }`}
                          onClick={() => {
                            if (window.innerWidth <= 768) {
                              setSidebarOpen(false); // Close sidebar on link click
                            }
                          }}
                        >
                          <span
                            className={`bg-purpletacx p-1 rounded-lg ${!sidebarExpanded ? 'flex items-center justify-center' : ''}`}
                          >
                            <MyIcon
                              iconName={item.iconName}
                              color="currentColor"
                            />
                          </span>
                          {sidebarExpanded && (
                            <span className="ml-2">{item.label}</span>
                          )}
                        </NavLink>
                      </li>
                    ))}

                  {isAdmin && (
                    <>
                      {[
                        {
                          to: '/admin/dashboard',
                          iconName: 'dashboard',
                          label: 'Dashboard',
                        },
                        {
                          to: '/admin/my-profile',
                          iconName: 'profile',
                          label: 'My Profile',
                        },
                        {
                          to: '/admin/users/subscriptions',
                          iconName: 'accountandbilling',
                          label: 'Subscriptions',
                        },
                        {
                          to: '/admin/client/dashboard',
                          iconName: 'users',
                          label: 'Clients',
                        },
                        {
                          to: '/admin/user-activity',
                          iconName: 'myActivity',
                          label: 'User Activities',
                        },
                        {
                          to: '/admin/analytics',
                          iconName: 'analytics',
                          label: 'Analytics',
                        },
                        {
                          to: '/admin/support',
                          iconName: 'SupportSideBar',
                          label: 'Support Message',
                        },
                        {
                          to: '/admin/email-template',
                          iconName: 'emailTemplate',
                          label: 'Email Template',
                        },
                      ].map((item, index) => (
                        <li
                          key={index}
                          className="flex items-center transition-opacity duration-300 w-[100%] mb-1"
                        >
                          <NavLink
                            to={item.to}
                            className={`w-[240px] group relative flex items-center gap-2.5 rounded-2xl py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-[#fff] hover:text-purpletacx dark:hover:bg-meta-4 ${
                              pathname.includes(item.to)
                                ? 'bg-[#fff] dark:bg-meta-4 !text-purpletacx'
                                : ''
                            }`}
                            onClick={() => {
                              if (window.innerWidth <= 768) {
                                setSidebarOpen(false); // Close sidebar on link click
                              }
                            }}
                          >
                            <span
                              className={`bg-purpletacx p-1 rounded-lg ${
                                !sidebarExpanded
                                  ? 'flex items-center justify-center'
                                  : ''
                              }`}
                            >
                              <MyIcon
                                iconName={item.iconName}
                                color="currentColor"
                              />
                            </span>
                            {sidebarExpanded && (
                              <span className="ml-2">{item.label}</span>
                            )}
                          </NavLink>
                        </li>
                      ))}
                    </>
                  )}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </aside>
      {!sidebarVisible && (
        <div
          className="w-full flex justify-center items-center h-14 cursor-pointer"
          onClick={toggleSidebar}
        >
          <div className="rounded-[50px] bg-yellowtacx border-[1px] shadow-cardBlack p-2">
            <MyIcon iconName="arrowRight" color="black" />
          </div>
        </div>
      )}
      <ReactTooltip
        id="sidebar-expand"
        // place="bottom"
        content="Expand Sidebar"
        style={{ zIndex: 9999 }}
      />
    </>
  );
};

export default Sidebar;
