import React, { useEffect, useState } from 'react';
import DefaultLayout from '../../../layout/Defaultlayout';
import Select, { StylesConfig } from 'react-select';
import {
  AdminProfileData,
  AdminEditProfileData,
  uploadImage,
  changeAdminPassword,
  saveAdminNotificationSettings,
  getAdminNotificationSetting,
} from '../../../api/Adminapi';
import { getIndustryList } from '../../../api/Api';
import { Link, useNavigate } from 'react-router-dom';
import MyIcon from '../../../components/cardeditor/icons/Myicon';
import MessageModel from '../../../components/model/Messagemodel';
import Loader from '../../../common/loader/Index';

interface FormErrors {
  firstName?: string;
  lastName?: string;
  companyName?: string;
  email?: string;
  cellPhoneNumber?: string;
  officeNumber?: string;
  website?: string;
  faxNumber?: string;
  industry?: string;
  aboutBusiness?: string;
}

const customStyles: StylesConfig<any, false> = {
  control: (provided: any) => ({
    ...provided,
    borderRadius: '8px',
    padding: '0 10px',
    borderColor: '#D8D8D8',
    boxShadow: 'none',
    fontSize: '16px',
    '&:hover': {
      borderColor: '#DFC749',
    },
  }),
  option: (provided: any, state: { isFocused: any }) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#DFC749' : '#fff',
    color: state.isFocused ? '#fff' : '#000',
    '&:hover': {
      backgroundColor: '#DFC749',
      color: '#fff',
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: '#000',
  }),
  menu: (provided: any) => ({
    ...provided,
    borderRadius: '10px',
    overflow: 'hidden',
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
};

type PasswordField = 'currentPassword' | 'newPassword' | 'confirmPassword';
type checkBoxField =
  | 'createUserEmail'
  | 'createUserInApp'
  | 'subscriptionEmail'
  | 'subscriptionInApp'
  | 'digitalCardEmail'
  | 'digitalCardInApp';

const ProfileEdit: React.FC = () => {
  const api_url = import.meta.env.VITE_API_URL;
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    companyName: '',
    email: '',
    cellPhoneNumber: '',
    officeNumber: '',
    website: '',
    industry: '',
    aboutBusiness: '',
    profilePicture: '',
  });
  const [errors, setErrors] = useState<FormErrors>({});
  const [loading, setLoading] = useState(true);
  const [formSubmitSaveBtnLoading, setFormSubmitSaveBtnLoading] =
    useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isUploadErrorModalOpen, setIsUploadErrorModalOpen] = useState(false);
  const [imageValidationMainMessage, setImageValidationMainMessage] =
    useState('');
  const [imageValidationMessage, setImageValidationMessage] = useState('');
  const [industryOption, setIndustryOption] = useState<
    { value: string; label: string }[]
  >([{ value: '', label: 'Select' }]);
  const navigate = useNavigate();
  const [isSaveInfoSuccess, setIsSaveInfoSuccess] = useState<boolean>(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [changePassError, setChangePassError] = useState('');
  const [changePassSuccess, setChangePassSuccess] = useState('');
  const [showPassword, setShowPassword] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });
  const [passwordErrors, setPasswordErrors] = useState({
    length: true,
    specialChar: true,
    number: true,
    capital: true,
  });
  const [changePasswordLoading, setChangePasswrodLoading] =
    useState<boolean>(false);
  const [selectedComponents, setSelectedComponents] = useState('profile');
  const [activeNotificationTab, setActiveNotificationTab] =
    useState('notification');
  const [notificationSaveLoading, setNotificationSaveLoading] =
    useState<boolean>(false);
  const [checkedState, setCheckedState] = useState({
    createUserEmail: true,
    createUserInApp: true,
    subscriptionEmail: true,
    subscriptionInApp: true,
    digitalCardEmail: true,
    digitalCardInApp: true,
  });
  const [settingDataLoading, setSettingDataLoading] = useState<boolean>(true);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await AdminProfileData();

        // Ensure res is defined and has the expected structure
        if (res && res.data && res.data.user) {
          setUserData({
            firstName: res.data.user.first_name || '',
            lastName: res.data.user.last_name || '',
            companyName: res.data.user.company_name || '',
            email: res.data.user.email || '',
            cellPhoneNumber: res.data.user.phone_number || '',
            officeNumber: res.data.user.office_number || '',
            website: res.data.user.website || '',
            industry: res.data.user.industry || '',
            aboutBusiness: res.data.user.about_business || '',
            profilePicture: res.data.user.profile_picture || '',
          });
        }
      } catch (error) {
        console.error('Error fetching data: ', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchAllIndustryList = async () => {
      const List = await getIndustryList();
      if (List?.data.content) {
        const industryList = List?.data.content;
        const formattedOptions = industryList.map((industry: any) => ({
          value: industry.id,
          label: industry.label,
        }));
        setIndustryOption([
          { value: '', label: 'Select' },
          ...formattedOptions,
        ]);
      }
    };
    fetchAllIndustryList();
  }, []);

  const validateAndSetFieldFormData = (
    fieldname: string,
    value: string | number,
  ) => {
    setUserData((preFormData) => ({
      ...preFormData,
      [fieldname]: value,
    }));
    setErrors((preErrors) => ({
      ...preErrors,
      [fieldname]: '',
    }));
  };

  const validateWebsiteUrl = (url: string): boolean => {
    // Regular expression to validate URLs with optional protocol and www
    const re =
      /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\/\S*)?$/;
    return re.test(url);
  };

  // ************* submit information function *************
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const newFormError: FormErrors = {};

    // Validate required fields
    if (!userData.firstName.trim()) {
      newFormError.firstName = 'First name is required';
    }
    if (!userData.lastName.trim()) {
      newFormError.lastName = 'Last name is required';
    }
    if (!userData.cellPhoneNumber.trim()) {
      newFormError.cellPhoneNumber = 'Phone number is required';
    } else if (!/^\d{10}$/.test(userData.cellPhoneNumber)) {
      newFormError.cellPhoneNumber = 'Phone number must be 10 digits';
    }
    if (
      userData.website &&
      userData.website.trim() &&
      !validateWebsiteUrl(userData.website)
    ) {
      newFormError.website = 'Invalid website URL format';
    }
    if (userData.aboutBusiness) {
      const words = userData.aboutBusiness.trim().split(/\s+/);
      if (words.length > 100) {
        newFormError.aboutBusiness =
          'Word limit exceeded. Please reduce your text to 100 words or less.';
      }
    }

    if (Object.keys(newFormError).length > 0) {
      setErrors(newFormError);
      // scroll to error functionality
      const firstErrorField = Object.keys(newFormError)[0];
      if (firstErrorField) {
        const errorElement = document.getElementById(firstErrorField);
        if (errorElement) {
          errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
      return; // Prevent submission if there are errors
    }

    setFormSubmitSaveBtnLoading(true);
    setLoadingMessage('Saving...');

    try {
      // Check if there's a file to upload
      let profilePictureFilename = userData.profilePicture;
      if (selectedFile) {
        profilePictureFilename = await uploadFileUtility(selectedFile); // Upload image if selected
      }

      // Update user data with the uploaded image file name
      const updatedUserData = {
        ...userData,
        profilePicture: profilePictureFilename as string,
      };

      const response = await AdminEditProfileData(updatedUserData);
      if (response?.data.success) {
        setIsSaveInfoSuccess(true);
        setTimeout(() => {
          setIsSaveInfoSuccess(false);
          navigate('/admin/my-profile');
        }, 3000);
      }
    } catch (error) {
      setIsSaveInfoSuccess(false);
      console.error('Error saving profile:', error);
    } finally {
      setFormSubmitSaveBtnLoading(false);
      setLoadingMessage(''); // Reset loading message
    }
  };

  //  ************* upload profile image functionality *************
  const openImageValidationModal = (mainMessage: string, message: string) => {
    setImageValidationMainMessage(mainMessage);
    setImageValidationMessage(message);
    setIsUploadErrorModalOpen(true);
  };
  const closeImageValidationModal = () => {
    setSelectedFile(null);
    setIsUploadErrorModalOpen(false);
  };

  const uploadFileUtility = async (file: File) => {
    try {
      const response = await uploadImage(file);
      return response.filename;
    } catch (error) {
      console.error('Error uploading file:', error);
      throw error; // Rethrow the error so that the calling function can handle it if needed
    }
  };
  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      // setSelectedFile(files[0]);
      const file = files[0];
      const allowedExtensions = /(\.jpeg|\.jpg|\.png|\.heic)$/i;
      if (!allowedExtensions.exec(file.name)) {
        openImageValidationModal(
          'Error while uploading image',
          'Invalid file type. Only JPEG, JPG, PNG, and HEIC files are allowed. Upload again.',
        );
        event.target.value = ''; // Clear the input
        setSelectedFile(null);
        throw new Error('Invalid file type');
      }
      // Validate file size
      const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
      if (file.size > maxSizeInBytes) {
        openImageValidationModal(
          'Error while uploading image',
          'File size exceeds the maximum limit of 5MB. Upload again.',
        );
        setSelectedFile(null);
        throw new Error('File size exceeds the limit');
      }
      setSelectedFile(file);
      // try {
      //   const filename = await uploadFileUtility(file);
      //   setUserData((prev) => ({
      //     ...prev,
      //     profilePicture: filename as string,
      //   }));
      //   setSelectedFile(null);
      // } catch (error) {
      //   console.error('[image field] Error uploading file:', error);
      // } finally {
      //   // setLoadingUploadImage(false);
      // }
    }
  };

  //  ************* Change password functionality *************
  const togglePasswordVisibility = (field: PasswordField) => {
    setShowPassword((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const validatePassword = (password: string) => {
    const errors = {
      length: password.length < 8,
      specialChar: !/[!@#$%^&*()]/.test(password),
      number: !/[0-9]/.test(password),
      capital: !/[A-Z]/.test(password),
    };
    setPasswordErrors(errors);
  };
  const handlePasswordChangeInput = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newPassword = e.target.value;
    setNewPassword(newPassword);
    validatePassword(newPassword);
  };

  const handlePasswordChange = async () => {
    if (!currentPassword || !newPassword || !confirmPassword) {
      setChangePassError('Please fill all fields');
      return;
    }
    if (
      passwordErrors.length == true ||
      passwordErrors.specialChar == true ||
      passwordErrors.number == true ||
      passwordErrors.capital == true
    ) {
      setChangePassError('');
      return;
    }
    if (newPassword !== confirmPassword) {
      setChangePassError('Passwords do not match.');
      return;
    }
    setChangePassError('');

    try {
      setChangePasswrodLoading(true);
      const response = await changeAdminPassword({
        currentPassword,
        newPassword,
      });

      if (response?.data.success) {
        if (response?.data.message) {
          setChangePassSuccess('Password changed successfully');
          setTimeout(() => {
            setChangePassSuccess('');
          }, 3000);
          setChangePassError('');
          setCurrentPassword('');
          setNewPassword('');
          setConfirmPassword('');
        } else {
          setChangePassError(
            response?.data.error || 'Failed to change password',
          );
        }
      } else {
        setChangePassSuccess('');
        setChangePassError(
          response?.data.message || 'Failed to change password',
        );
      }
    } catch (error) {
      setChangePassSuccess('');
      setChangePassError('Error occurred while changing password');
    } finally {
      setChangePasswrodLoading(false);
    }
  };

  const handleToggleAccordions = (component: any) => {
    if (selectedComponents !== component) {
      setSelectedComponents(component);
    }
  };

  //  ************* Notification setting functionality *************
  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      setSettingDataLoading(true);
      const response = await getAdminNotificationSetting();
      if (response?.data.success) {
        const settings = response?.data.data;

        if (settings && settings.length > 0) {
          const updatedCheckedState = {
            createUserEmail:
              settings.find((item: any) => item.notification_type === 'Singup')
                ?.email_notification === 1,
            createUserInApp:
              settings.find((item: any) => item.notification_type === 'Singup')
                ?.inapp_notification === 1,
            subscriptionEmail:
              settings.find(
                (item: any) => item.notification_type === 'subscription',
              )?.email_notification === 1,
            subscriptionInApp:
              settings.find(
                (item: any) => item.notification_type === 'subscription',
              )?.inapp_notification === 1,
            digitalCardEmail:
              settings.find((item: any) => item.notification_type === 'card')
                ?.email_notification === 1,
            digitalCardInApp:
              settings.find((item: any) => item.notification_type === 'card')
                ?.inapp_notification === 1,
          };
          setCheckedState(updatedCheckedState);
        }
      }
    } catch (error) {
      console.error('Error fetching notification settings:', error);
    } finally {
      setSettingDataLoading(false);
    }
  };

  const toggleCheckbox = (checkboxName: checkBoxField) => {
    setCheckedState((prevState) => ({
      ...prevState,
      [checkboxName]: !prevState[checkboxName],
    }));
  };

  const handleNotificationSettings = async (e: any) => {
    e.preventDefault();

    const notificationSettings = [
      {
        notification_type: 'Singup',
        email_notification: checkedState.createUserEmail ? 1 : 0,
        inapp_notification: checkedState.createUserInApp ? 1 : 0,
        role: 'admin',
      },
      {
        notification_type: 'subscription',
        email_notification: checkedState.subscriptionEmail ? 1 : 0,
        inapp_notification: checkedState.subscriptionInApp ? 1 : 0,
        role: 'admin',
      },
      {
        notification_type: 'card',
        email_notification: checkedState.digitalCardEmail ? 1 : 0,
        inapp_notification: checkedState.digitalCardInApp ? 1 : 0,
        role: 'admin',
      },
    ];

    try {
      setNotificationSaveLoading(true);
      await saveAdminNotificationSettings(notificationSettings);
      fetchSettings();
    } catch (error) {
      console.error('Error saving settings:', error);
    } finally {
      setNotificationSaveLoading(false);
    }
  };

  const customFilter = (
    option: any,
    inputValue: string
  ): boolean => {
    return option?.label?.toLowerCase().startsWith(inputValue?.toLowerCase());
  };
  return (
    <DefaultLayout title="Profile Edit">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="max-w-full mx-auto">
            <div className="flex justify-start mb-4">
              <button
                className={`bg-purpletacx text-[#000] rounded-full px-3 py-3 transition-transform duration-300 `}
                onClick={() => {
                  navigate('/admin/my-profile');
                }}
              >
                <svg
                  width="17"
                  height="15"
                  viewBox="0 0 19 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.83227 5.22791H1.31073C1.05147 5.22786 0.79806 5.15094 0.582517 5.00688C0.366975 4.86282 0.198983 4.65809 0.0997794 4.41857C0.000575467 4.17905 -0.0253864 3.91549 0.0251759 3.66122C0.0757382 3.40695 0.200554 3.17337 0.383845 2.99003L3.00586 0.368015C3.25312 0.129204 3.58428 -0.00293746 3.92802 4.95595e-05C4.27176 0.00303658 4.60058 0.140914 4.84365 0.383985C5.08672 0.627056 5.2246 0.955872 5.22759 1.29961C5.23057 1.64336 5.09843 1.97452 4.85962 2.22178L4.47681 2.6059H9.83227C10.18 2.6059 10.5134 2.74403 10.7593 2.98989C11.0051 3.23575 11.1433 3.56921 11.1433 3.91691C11.1433 4.26461 11.0051 4.59807 10.7593 4.84393C10.5134 5.08979 10.18 5.22791 9.83227 5.22791Z"
                    fill="#DFC749"
                  />
                  <path
                    d="M12.4546 14.4053H1.31101C0.963306 14.4053 0.629846 14.2672 0.383985 14.0213C0.138123 13.7754 0 13.442 0 13.0943C0 12.7466 0.138123 12.4131 0.383985 12.1673C0.629846 11.9214 0.963306 11.7833 1.31101 11.7833H12.4546C13.2423 11.7833 14.0037 11.4997 14.5994 10.9843C15.1951 10.4689 15.5853 9.75626 15.6987 8.97673C15.812 8.19721 15.6408 7.40294 15.2165 6.73927C14.7922 6.0756 14.1431 5.58691 13.388 5.36263C13.0548 5.26363 12.7746 5.03639 12.6089 4.73085C12.4433 4.42532 12.4057 4.0665 12.5045 3.73328C12.6033 3.40005 12.8304 3.11969 13.1358 2.95383C13.4412 2.78797 13.8 2.75018 14.1333 2.84877C15.4926 3.25218 16.6611 4.13159 17.4251 5.32605C18.1891 6.52051 18.4973 7.95014 18.2935 9.35329C18.0897 10.7564 17.3874 12.0393 16.3151 12.967C15.2429 13.8947 13.8724 14.4053 12.4546 14.4053Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
            <div className="bg-white shadow-md w-full rounded-lg mt-2">
              <div className=" flex w-full justify-between flex-wrap">
                <div className="p-6 flex flex-col gap-3 w-full md:basis-[30%] xl:basis-[25%]">
                  <div className="flex flex-row md:flex-col gap-4 w-full text-center md:text-start">
                    <div
                      className={`${selectedComponents === 'profile' ? 'bg-[#EFEDEE]' : ''} md:pl-3 py-2 cursor-pointer rounded-lg text-purpletacx font-bold w-full text-sm md:text-[16px]`}
                      onClick={() => handleToggleAccordions('profile')}
                    >
                      My Profile
                    </div>
                    <div
                      className={`${selectedComponents === 'settings' ? 'bg-[#EFEDEE]' : ''} md:pl-3 py-2 cursor-pointer rounded-lg text-purpletacx font-bold w-full text-sm md:text-[16px]`}
                      onClick={() => handleToggleAccordions('settings')}
                    >
                      Settings
                    </div>
                  </div>
                </div>
                <div className="p-6 flex flex-col w-full md:basis-[70%] xl:basis-[75%]">
                  {selectedComponents === 'profile' && (
                    <div
                      id="faxNumber"
                      className="my-3 flex flex-col gap-y-5 sm:p-6"
                    >
                      <div className="flex w-full justify-between flex-wrap">
                        <div className="w-full xl:basis-[25%]">
                          <div className="flex flex-col items-center xl:items-start w-full">
                            <div className="flex flex-col items-center">
                              <div className="text-center text-black font-semibold mb-3">
                                <p>Profile Picture</p>
                              </div>
                              <label
                                htmlFor="uploadFile1"
                                className={`flex text-center w-30 md:w-40 h-30 md:h-40 flex-col justify-center  text-white text-base outline-none rounded-full cursor-pointer ${
                                  userData.profilePicture
                                    ? 'border border-stroke'
                                    : 'bg-[#DED9DD]'
                                }`}
                                style={{
                                  backgroundImage: selectedFile
                                    ? `url(${URL.createObjectURL(selectedFile)})`
                                    : `url(${api_url}/public/cards/images/${userData.profilePicture})`,
                                  backgroundSize: 'cover',
                                  backgroundPosition: 'center',
                                }}
                              >
                                <label>
                                  <div className="flex flex-col justify-center items-center">
                                    <div
                                      className={`${
                                        userData?.profilePicture
                                          ? 'bg-[#ffffffc9]'
                                          : ''
                                      } rounded-full p-2`}
                                    >
                                      <span className="flex justify-center">
                                        <MyIcon
                                          iconName="cloudUpload"
                                          color="#594255"
                                        />
                                      </span>
                                      <span className="text-center text-[10px] text-[#594255] opacity-100">
                                        upload
                                      </span>
                                    </div>
                                  </div>
                                  <input
                                    type="file"
                                    id="uploadFile1"
                                    className="hidden"
                                    accept=".jpeg,.jpg,.png,.heic"
                                    onChange={(e) => handleFileChange(e)}
                                  />
                                </label>
                              </label>
                              <p className="mt-2 text-[10px] text-gray-600">
                                Accepted file type: SVG, PNG, JPG
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col gap-3 w-full xl:basis-[75%]">
                          {/* first name and last name field  */}
                          <div
                            id="firstName"
                            className="flex flex-col xl:flex-row justify-center items-center gap-5"
                          >
                            <div className="w-full">
                              <label className=" font-medium text-[#000000]">
                                First Name{' '}
                                <span className="text-red-600">*</span>
                              </label>
                              <input
                                type="text"
                                name="fname"
                                className=" mt-1 rounded-lg w-full border border-stroke bg-white py-2 px-4 text-[#000000] outline-none focus:border-yellowtacx focus-visible:shadow-none"
                                value={userData.firstName}
                                // onChange={handleChange('firstName')}
                                onChange={(e) =>
                                  validateAndSetFieldFormData(
                                    'firstName',
                                    e.target.value,
                                  )
                                }
                              />
                              <div
                                className={`h-1.5 ${!errors.firstName ? 'invisible' : ''}`}
                              >
                                <p className="text-red-500 text-sm">
                                  {errors.firstName}
                                </p>
                              </div>
                            </div>
                            <div id="lastName" className="w-full">
                              <label className=" font-medium text-[#000000]">
                                Last Name{' '}
                                <span className="text-red-600">*</span>
                              </label>
                              <input
                                type="text"
                                name="lname"
                                className=" mt-1 rounded-lg w-full border border-stroke bg-white py-2 pl-4 pr-4 text-[#000000] outline-none focus:border-yellowtacx focus-visible:shadow-none"
                                value={userData.lastName}
                                // onChange={handleChange('lastName')}
                                onChange={(e) =>
                                  validateAndSetFieldFormData(
                                    'lastName',
                                    e.target.value,
                                  )
                                }
                              />
                              <div
                                className={`h-1.5 ${!errors.lastName ? 'invisible' : ''}`}
                              >
                                <p className="text-red-500 text-sm">
                                  {errors.lastName}
                                </p>
                              </div>
                            </div>
                          </div>

                          {/* email and phone field  */}
                          <div className="flex flex-col xl:flex-row justify-center items-center gap-5">
                            <div className="w-full">
                              <label className="font-medium text-[#000000]">
                                Email <span className="text-red-600">*</span>
                              </label>
                              <input
                                type="text"
                                name="gemail"
                                className=" mt-1 rounded-lg w-full bg-[#EFEDEE] py-2 pl-4 pr-4 text-[#000000] outline-none focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-[#D4D4D4]"
                                value={userData.email}
                                readOnly
                                onChange={(e) =>
                                  validateAndSetFieldFormData(
                                    'mailId',
                                    e.target.value,
                                  )
                                }
                              />
                              <div
                                className={`h-1.5 ${!errors.email ? 'invisible' : ''}`}
                              >
                                <p className="text-red-500 text-sm">
                                  {errors.email}
                                </p>
                              </div>
                            </div>
                            <div id="cellPhoneNumber" className="w-full">
                              <label className=" font-medium text-[#000000]">
                                Cell Phone{' '}
                                <span className="text-red-600">*</span>
                              </label>
                              <input
                                type="text"
                                name="cellphonenumber"
                                className=" mt-1 rounded-lg w-full border border-stroke bg-white py-2 pl-4 pr-4 text-[#000000] outline-none focus:border-yellowtacx focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-[#D4D4D4]"
                                value={userData.cellPhoneNumber}
                                onChange={(e) =>
                                  validateAndSetFieldFormData(
                                    'cellPhoneNumber',
                                    e.target.value,
                                  )
                                }
                                onKeyPress={(e) => {
                                  if (!/^\d*$/.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                                maxLength={10}
                              />
                              <div
                                className={`h-1.5 ${!errors.cellPhoneNumber ? 'invisible' : ''}`}
                              >
                                <p className="text-red-500 text-sm">
                                  {errors.cellPhoneNumber}
                                </p>
                              </div>
                            </div>
                          </div>

                          {/* company name and industry field  */}
                          <div className="flex flex-col xl:flex-row justify-center items-center gap-5">
                            <div className="w-full">
                              <label className="font-medium text-[#000000]">
                                Company Name{' '}
                              </label>
                              <input
                                type="text"
                                name="companyname"
                                className=" mt-1 rounded-lg w-full border border-stroke bg-white py-2 pl-4 pr-4 text-[#000000] outline-none focus:border-yellowtacx focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-[#D4D4D4]"
                                value={userData.companyName}
                                onChange={(e) =>
                                  validateAndSetFieldFormData(
                                    'companyName',
                                    e.target.value,
                                  )
                                }
                              />
                            </div>
                            <div className="w-full">
                              <div>
                                <label className=" font-medium text-[#000000]">
                                  Industry
                                </label>
                              </div>
                              <div className="relative">
                                <Select
                                  options={industryOption}
                                  styles={customStyles}
                                  className="mt-1"
                                  placeholder="Select"
                                  value={industryOption.find(
                                    (option) =>
                                      option.value === userData.industry,
                                  )}
                                  onChange={(selectedOption) =>
                                    validateAndSetFieldFormData(
                                      'industry',
                                      selectedOption?.value || '',
                                    )
                                  }
                filterOption={customFilter}

                                />
                                <span className="absolute top-0 right-3 h-full flex items-center">
                                  <svg
                                    width="25"
                                    height="25"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M7 10L12 15L17 10H7Z"
                                      fill="#000"
                                    />
                                  </svg>
                                </span>
                              </div>
                            </div>
                          </div>

                          {/* website field  */}
                          <div className="flex flex-col xl:flex-row justify-center  gap-5">
                            <div id="website" className="w-full">
                              <label className=" font-medium text-[#000000]">
                                Website
                              </label>
                              <input
                                type="text"
                                name="websiteurl"
                                placeholder="for e.g: https://tacx.digital"
                                className=" mt-1 rounded-lg w-full border border-stroke bg-white py-2 pl-4 pr-4 text-[#000000] outline-none focus:border-yellowtacx focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-[#D4D4D4]"
                                value={userData.website}
                                onChange={(e) =>
                                  validateAndSetFieldFormData(
                                    'website',
                                    e.target.value,
                                  )
                                }
                              />
                              <div
                                className={`h-1.5 ${!errors.website ? 'invisible' : ''}`}
                              >
                                <p className="text-red-500 text-sm">
                                  {errors.website}
                                </p>
                              </div>
                            </div>
                          </div>

                          {/* about business field  */}
                          <div id="aboutBusiness" className="w-full">
                            <label className=" font-medium text-[#000000]">
                              About Business
                            </label>
                            <textarea
                              name="websiteurl"
                              placeholder="For example: The tacx specialize in creating personalized digital business cards that help professionals make a lasting impression."
                              className="w-full border border-stroke rounded-xl text-[#000000] p-3 focus:outline-yellowtacx mt-1 min-h-40"
                              value={userData.aboutBusiness}
                              onChange={(e) =>
                                validateAndSetFieldFormData(
                                  'aboutBusiness',
                                  e.target.value,
                                )
                              }
                            />
                            <div
                              className={`h-1.5 ${!errors.aboutBusiness ? 'invisible' : ''}`}
                            >
                              <p className="text-red-500 text-sm">
                                {errors.aboutBusiness}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* submit information button  */}
                      <div className="flex flex-col sm:flex-row justify-center sm:justify-end items-center gap-5">
                        <div className="w-full sm:w-auto">
                          <button
                            className="bg-yellowtacx text-sm md:text-base font-[500] rounded-lg text-[#000000] w-full sm:w-[174px] h-[47px] hover:bg-[#594255] hover:text-[#ffffff] transition-all duration-200"
                            onClick={(event) => handleSubmit(event)}
                            disabled={formSubmitSaveBtnLoading}
                          >
                            {formSubmitSaveBtnLoading ? (
                              <div
                                role="status"
                                className="flex items-center justify-center"
                              >
                                <div className="inline-block h-4 w-4 mr-2 animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"></div>
                                <span>{loadingMessage}</span>
                              </div>
                            ) : (
                              <span>Save</span>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  {selectedComponents === 'settings' && (
                    <>
                      <div className="w-full">
                        <div className="text-sm font-medium text-center text-gray-500 border-b border-stroke">
                          <ul className="flex flex-wrap -mb-px text-black">
                            <li className="me-2">
                              <div
                                onClick={() =>
                                  setActiveNotificationTab('notification')
                                }
                                className={`inline-block p-4 border-b-2 rounded-t-lg cursor-pointer ${
                                  activeNotificationTab === 'notification'
                                    ? 'border-yellowtacx text-yellowtacx'
                                    : 'border-transparent hover:text-gray-600 hover:border-gray-300'
                                }`}
                              >
                                Notifications
                              </div>
                            </li>
                            <li className="me-2">
                              <div
                                onClick={() =>
                                  setActiveNotificationTab('security')
                                }
                                className={`inline-block p-4 border-b-2 rounded-t-lg cursor-pointer ${
                                  activeNotificationTab === 'security'
                                    ? 'border-yellowtacx text-yellowtacx'
                                    : 'border-transparent hover:text-gray-600 hover:border-gray-300'
                                }`}
                              >
                                Security & Privacy
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="mt-4">
                          {activeNotificationTab === 'notification' &&
                            (settingDataLoading ? (
                              <Loader />
                            ) : (
                              <div>
                                <div className="text-black">
                                  <p className="font-bold text-lg">
                                    Notification settings
                                  </p>
                                  <p className="text-sm font-medium">
                                    We may still send you important
                                    notifications about your account outside of
                                    your notification settings.
                                  </p>
                                </div>
                                <hr className="border-stroke  mt-6" />

                                <div className="grid grid-cols-1 sm:grid-cols-2 mt-6 gap-3">
                                  {/* Blurring the entire div */}
                                  <div className="opacity-50 pointer-events-none">
                                    {' '}
                                    {/* Blur and disable pointer events */}
                                    <p className="text-black font-semibold">
                                      Create user
                                    </p>
                                    <p className="text-black text-sm">
                                      These are notifications for when user
                                      carete their account.
                                    </p>
                                  </div>
                                  <div>
                                    {/* Email checkbox - not checked by default */}
                                    <label
                                      htmlFor="createUserEmail"
                                      className="flex gap-2"
                                    >
                                      <div className="relative">
                                        <input
                                          type="checkbox"
                                          disabled={true}
                                          id="createUserEmail"
                                          className="sr-only"
                                          checked={
                                            checkedState.createUserEmail || true
                                          }
                                          onChange={() =>
                                            toggleCheckbox('createUserEmail')
                                          }
                                        />
                                        <div
                                          className={`flex h-4 mt-1 w-4 items-center justify-center rounded-sm border border-[#DFC749] ${
                                            (checkedState.createUserEmail ||
                                              true) &&
                                            'border-[#DFC749] bg-[#DFC749] dark:bg-transparent'
                                          }`}
                                        >
                                          <span
                                            className={`opacity-0 ${(checkedState.createUserEmail || true) && '!opacity-100'}`}
                                          >
                                            <svg
                                              width="11"
                                              height="8"
                                              viewBox="0 0 11 8"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M10.0915 0.951972L10.0867 0.946075L10.0813 0.940568C9.90076 0.753564 9.61034 0.753146 9.42927 0.939309L4.16201 6.22962L1.58507 3.63469C1.40401 3.44841 1.11351 3.44879 0.932892 3.63584C0.755703 3.81933 0.755703 4.10875 0.932892 4.29224L0.932878 4.29225L0.934851 4.29424L3.58046 6.95832C3.73676 7.11955 3.94983 7.2 4.1473 7.2C4.36196 7.2 4.55963 7.11773 4.71406 6.9584L10.0468 1.60234C10.2436 1.4199 10.2421 1.1339 10.0915 0.951972ZM4.2327 6.30081L4.2317 6.2998C4.23206 6.30015 4.23237 6.30049 4.23269 6.30082L4.2327 6.30081Z"
                                                fill="#ffff"
                                                stroke="#ffff"
                                                strokeWidth="0.4"
                                              ></path>
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                      <p className="text-black font-medium opacity-50 pointer-events-none">
                                        Email
                                      </p>
                                    </label>

                                    {/* In-app checkbox - checked by default */}
                                    <label
                                      htmlFor="createUserInApp"
                                      className="flex gap-2"
                                    >
                                      <div className="relative">
                                        <input
                                          type="checkbox"
                                          id="createUserInApp"
                                          className="sr-only"
                                          checked={
                                            checkedState.createUserInApp || true
                                          }
                                          onChange={() =>
                                            toggleCheckbox('createUserInApp')
                                          }
                                        />
                                        <div
                                          className={`flex h-4 mt-1 w-4 items-center justify-center rounded-sm border border-[#DFC749] ${
                                            (checkedState.createUserInApp ||
                                              true) &&
                                            'border-[#DFC749] bg-[#DFC749] dark:bg-transparent'
                                          }`}
                                        >
                                          <span
                                            className={`opacity-0 ${(checkedState.createUserInApp || true) && '!opacity-100'}`}
                                          >
                                            <svg
                                              width="11"
                                              height="8"
                                              viewBox="0 0 11 8"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M10.0915 0.951972L10.0867 0.946075L10.0813 0.940568C9.90076 0.753564 9.61034 0.753146 9.42927 0.939309L4.16201 6.22962L1.58507 3.63469C1.40401 3.44841 1.11351 3.44879 0.932892 3.63584C0.755703 3.81933 0.755703 4.10875 0.932892 4.29224L0.932878 4.29225L0.934851 4.29424L3.58046 6.95832C3.73676 7.11955 3.94983 7.2 4.1473 7.2C4.36196 7.2 4.55963 7.11773 4.71406 6.9584L10.0468 1.60234C10.2436 1.4199 10.2421 1.1339 10.0915 0.951972ZM4.2327 6.30081L4.2317 6.2998C4.23206 6.30015 4.23237 6.30049 4.23269 6.30082L4.2327 6.30081Z"
                                                fill="#ffff"
                                                stroke="#ffff"
                                                strokeWidth="0.4"
                                              ></path>
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                      <p className="text-black font-medium opacity-50 pointer-events-none">
                                        In-app
                                      </p>
                                    </label>
                                  </div>
                                </div>

                                <hr className="border-stroke  mt-6" />

                                <div className="grid grid-cols-1 sm:grid-cols-2 mt-6 gap-3">
                                  <div className="">
                                    <p className="text-black font-semibold">
                                      Subscriptions
                                    </p>
                                    <p className="text-black text-sm break-words">
                                      These are notifications for when user
                                      purchase their subcscription.
                                    </p>
                                  </div>
                                  <div>
                                    <label
                                      htmlFor="subscriptionEmail"
                                      className="cursor-pointer flex gap-2"
                                    >
                                      <div className="relative">
                                        <input
                                          type="checkbox"
                                          id="subscriptionEmail"
                                          className="sr-only"
                                          checked={
                                            checkedState.subscriptionEmail
                                          }
                                          onChange={() =>
                                            toggleCheckbox('subscriptionEmail')
                                          }
                                        />
                                        <div
                                          className={`flex h-4 mt-1 w-4 items-center justify-center rounded-sm border border-[#DFC749] ${
                                            checkedState.subscriptionEmail &&
                                            'border-[#DFC749] bg-[#DFC749] dark:bg-transparent'
                                          }`}
                                        >
                                          <span
                                            className={`opacity-0 ${checkedState.subscriptionEmail && '!opacity-100'}`}
                                          >
                                            <svg
                                              width="11"
                                              height="8"
                                              viewBox="0 0 11 8"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M10.0915 0.951972L10.0867 0.946075L10.0813 0.940568C9.90076 0.753564 9.61034 0.753146 9.42927 0.939309L4.16201 6.22962L1.58507 3.63469C1.40401 3.44841 1.11351 3.44879 0.932892 3.63584C0.755703 3.81933 0.755703 4.10875 0.932892 4.29224L0.932878 4.29225L0.934851 4.29424L3.58046 6.95832C3.73676 7.11955 3.94983 7.2 4.1473 7.2C4.36196 7.2 4.55963 7.11773 4.71406 6.9584L10.0468 1.60234C10.2436 1.4199 10.2421 1.1339 10.0915 0.951972ZM4.2327 6.30081L4.2317 6.2998C4.23206 6.30015 4.23237 6.30049 4.23269 6.30082L4.2327 6.30081Z"
                                                fill="#ffff"
                                                stroke="#ffff"
                                                strokeWidth="0.4"
                                              ></path>
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                      <p className="text-black font-medium">
                                        Email
                                      </p>
                                    </label>
                                    <label
                                      htmlFor="subscriptionInApp"
                                      className="cursor-pointer flex gap-2"
                                    >
                                      <div className="relative">
                                        <input
                                          type="checkbox"
                                          id="subscriptionInApp"
                                          className="sr-only"
                                          checked={
                                            checkedState.subscriptionInApp
                                          }
                                          onChange={() =>
                                            toggleCheckbox('subscriptionInApp')
                                          }
                                        />
                                        <div
                                          className={`flex h-4 mt-1 w-4 items-center justify-center rounded-sm border border-[#DFC749] ${
                                            checkedState.subscriptionInApp &&
                                            'border-[#DFC749] bg-[#DFC749] dark:bg-transparent'
                                          }`}
                                        >
                                          <span
                                            className={`opacity-0 ${checkedState.subscriptionInApp && '!opacity-100'}`}
                                          >
                                            <svg
                                              width="11"
                                              height="8"
                                              viewBox="0 0 11 8"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M10.0915 0.951972L10.0867 0.946075L10.0813 0.940568C9.90076 0.753564 9.61034 0.753146 9.42927 0.939309L4.16201 6.22962L1.58507 3.63469C1.40401 3.44841 1.11351 3.44879 0.932892 3.63584C0.755703 3.81933 0.755703 4.10875 0.932892 4.29224L0.932878 4.29225L0.934851 4.29424L3.58046 6.95832C3.73676 7.11955 3.94983 7.2 4.1473 7.2C4.36196 7.2 4.55963 7.11773 4.71406 6.9584L10.0468 1.60234C10.2436 1.4199 10.2421 1.1339 10.0915 0.951972ZM4.2327 6.30081L4.2317 6.2998C4.23206 6.30015 4.23237 6.30049 4.23269 6.30082L4.2327 6.30081Z"
                                                fill="#ffff"
                                                stroke="#ffff"
                                                strokeWidth="0.4"
                                              ></path>
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                      <p className="text-black font-medium">
                                        In-app
                                      </p>
                                    </label>
                                  </div>
                                </div>

                                <hr className="border-stroke  mt-6" />

                                <div className="grid grid-cols-1 sm:grid-cols-2 mt-6 gap-3">
                                  <div className="">
                                    <p className="text-black font-semibold">
                                      Digital Cards
                                    </p>
                                    <p className="text-black text-sm break-words">
                                      These are notifications for when user
                                      create or update their digital business
                                      cards.
                                    </p>
                                  </div>
                                  <div>
                                    <label
                                      htmlFor="digitalCardEmail"
                                      className="cursor-pointer flex gap-2"
                                    >
                                      <div className="relative">
                                        <input
                                          type="checkbox"
                                          id="digitalCardEmail"
                                          className="sr-only"
                                          checked={
                                            checkedState.digitalCardEmail
                                          }
                                          onChange={() =>
                                            toggleCheckbox('digitalCardEmail')
                                          }
                                        />
                                        <div
                                          className={`flex h-4 mt-1 w-4 items-center justify-center rounded-sm border border-[#DFC749] ${
                                            checkedState.digitalCardEmail &&
                                            'border-[#DFC749] bg-[#DFC749] dark:bg-transparent'
                                          }`}
                                        >
                                          <span
                                            className={`opacity-0 ${checkedState.digitalCardEmail && '!opacity-100'}`}
                                          >
                                            <svg
                                              width="11"
                                              height="8"
                                              viewBox="0 0 11 8"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M10.0915 0.951972L10.0867 0.946075L10.0813 0.940568C9.90076 0.753564 9.61034 0.753146 9.42927 0.939309L4.16201 6.22962L1.58507 3.63469C1.40401 3.44841 1.11351 3.44879 0.932892 3.63584C0.755703 3.81933 0.755703 4.10875 0.932892 4.29224L0.932878 4.29225L0.934851 4.29424L3.58046 6.95832C3.73676 7.11955 3.94983 7.2 4.1473 7.2C4.36196 7.2 4.55963 7.11773 4.71406 6.9584L10.0468 1.60234C10.2436 1.4199 10.2421 1.1339 10.0915 0.951972ZM4.2327 6.30081L4.2317 6.2998C4.23206 6.30015 4.23237 6.30049 4.23269 6.30082L4.2327 6.30081Z"
                                                fill="#ffff"
                                                stroke="#ffff"
                                                strokeWidth="0.4"
                                              ></path>
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                      <p className="text-black font-medium">
                                        Email
                                      </p>
                                    </label>
                                    <label
                                      htmlFor="digitalCardInApp"
                                      className="cursor-pointer flex gap-2"
                                    >
                                      <div className="relative">
                                        <input
                                          type="checkbox"
                                          id="digitalCardInApp"
                                          className="sr-only"
                                          checked={
                                            checkedState.digitalCardInApp
                                          }
                                          onChange={() =>
                                            toggleCheckbox('digitalCardInApp')
                                          }
                                        />
                                        <div
                                          className={`flex h-4 mt-1 w-4 items-center justify-center rounded-sm border border-[#DFC749] ${
                                            checkedState.digitalCardInApp &&
                                            'border-[#DFC749] bg-[#DFC749] dark:bg-transparent'
                                          }`}
                                        >
                                          <span
                                            className={`opacity-0 ${checkedState.digitalCardInApp && '!opacity-100'}`}
                                          >
                                            <svg
                                              width="11"
                                              height="8"
                                              viewBox="0 0 11 8"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M10.0915 0.951972L10.0867 0.946075L10.0813 0.940568C9.90076 0.753564 9.61034 0.753146 9.42927 0.939309L4.16201 6.22962L1.58507 3.63469C1.40401 3.44841 1.11351 3.44879 0.932892 3.63584C0.755703 3.81933 0.755703 4.10875 0.932892 4.29224L0.932878 4.29225L0.934851 4.29424L3.58046 6.95832C3.73676 7.11955 3.94983 7.2 4.1473 7.2C4.36196 7.2 4.55963 7.11773 4.71406 6.9584L10.0468 1.60234C10.2436 1.4199 10.2421 1.1339 10.0915 0.951972ZM4.2327 6.30081L4.2317 6.2998C4.23206 6.30015 4.23237 6.30049 4.23269 6.30082L4.2327 6.30081Z"
                                                fill="#ffff"
                                                stroke="#ffff"
                                                strokeWidth="0.4"
                                              ></path>
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                      <p className="text-black font-medium">
                                        In-app
                                      </p>
                                    </label>
                                  </div>
                                </div>
                                <div className="flex flex-col sm:flex-row justify-center sm:justify-end items-center mt-5">
                                  <div className="w-full sm:w-auto">
                                    <button
                                      className="bg-yellowtacx text-sm md:text-base font-[500] rounded-lg text-[#000000] w-full sm:w-[165px] h-[40px] hover:bg-[#594255] hover:text-[#ffffff] transition-all duration-200"
                                      onClick={handleNotificationSettings}
                                      disabled={notificationSaveLoading}
                                    >
                                      {notificationSaveLoading ? (
                                        <div
                                          role="status"
                                          className="flex items-center justify-center"
                                        >
                                          <div className="inline-block h-4 w-4 mr-2 animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"></div>
                                        </div>
                                      ) : (
                                        <span>Save Settings</span>
                                      )}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ))}
                          {activeNotificationTab === 'security' && (
                            <div className="w-full">
                              <p className="text-black md:text-lg font-bold">
                                Change Password
                              </p>
                              <div className="w-full relative">
                                <input
                                  type={
                                    showPassword.currentPassword
                                      ? 'text'
                                      : 'password'
                                  }
                                  name="companyname"
                                  placeholder="Enter current password"
                                  className=" mt-3 rounded-lg w-full border border-stroke bg-white py-2 pl-4 pr-4 text-[#000000] outline-none focus:border-yellowtacx focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-[#D4D4D4]"
                                  value={currentPassword}
                                  onChange={(e) =>
                                    setCurrentPassword(e.target.value)
                                  }
                                />
                                <span
                                  className="absolute top-1.5 right-3 h-full flex items-center cursor-pointer"
                                  onClick={() => {
                                    togglePasswordVisibility('currentPassword');
                                  }}
                                >
                                  {showPassword.currentPassword ? (
                                    <MyIcon
                                      iconName="closeEye"
                                      color="#595959"
                                    />
                                  ) : (
                                    <MyIcon
                                      iconName="PreviewIcon"
                                      color="#595959"
                                    />
                                  )}
                                </span>
                              </div>
                              <div className="w-full relative">
                                <input
                                  type={
                                    showPassword.newPassword
                                      ? 'text'
                                      : 'password'
                                  }
                                  name="companyname"
                                  placeholder="Enter new password"
                                  className=" mt-3 rounded-lg w-full border border-stroke bg-white py-2 pl-4 pr-4 text-[#000000] outline-none focus:border-yellowtacx focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-[#D4D4D4]"
                                  value={newPassword}
                                  // onChange={(e) => setNewPassword(e.target.value)}
                                  onChange={handlePasswordChangeInput}
                                />
                                <span
                                  className="absolute top-1.5 right-3 h-full flex items-center cursor-pointer"
                                  onClick={() => {
                                    togglePasswordVisibility('newPassword');
                                  }}
                                >
                                  {showPassword.newPassword ? (
                                    <MyIcon
                                      iconName="closeEye"
                                      color="#595959"
                                    />
                                  ) : (
                                    <MyIcon
                                      iconName="PreviewIcon"
                                      color="#595959"
                                    />
                                  )}
                                </span>
                              </div>
                              {newPassword &&
                                (passwordErrors.length ||
                                  passwordErrors.specialChar ||
                                  passwordErrors.capital ||
                                  passwordErrors.number) && (
                                  <div className="text-sm mt-4">
                                    <p
                                      className={` ${passwordErrors.length ? 'text-red-500' : 'text-emerald-500'} `}
                                    >
                                      Password must be 8 characters:{' '}
                                      <span>
                                        {passwordErrors.length ? 'x' : '✓'}
                                      </span>
                                    </p>
                                    <p
                                      className={` ${passwordErrors.specialChar ? 'text-red-500' : 'text-emerald-500'} `}
                                    >
                                      Include at least one special character
                                      (!@#$%^&*()):{' '}
                                      {passwordErrors.specialChar ? 'x' : '✓'}
                                    </p>
                                    <p
                                      className={` ${passwordErrors.number ? 'text-red-500' : 'text-emerald-500'} `}
                                    >
                                      Include at least one number:{' '}
                                      {passwordErrors.number ? 'x' : '✓'}
                                    </p>
                                    <p
                                      className={` ${passwordErrors.capital ? 'text-red-500' : 'text-emerald-500'} `}
                                    >
                                      Include at least one capital letter:{' '}
                                      {passwordErrors.capital ? 'x' : '✓'}
                                    </p>
                                  </div>
                                )}
                              <div className="w-full relative">
                                <input
                                  type={
                                    showPassword.confirmPassword
                                      ? 'text'
                                      : 'password'
                                  }
                                  name="companyname"
                                  placeholder="Confirm new password"
                                  className=" mt-3 rounded-lg w-full border border-stroke bg-white py-2 pl-4 pr-4 text-[#000000] outline-none focus:border-yellowtacx focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-[#D4D4D4]"
                                  value={confirmPassword}
                                  onChange={(e) =>
                                    setConfirmPassword(e.target.value)
                                  }
                                />
                                <span
                                  className="absolute top-1.5 right-3 h-full flex items-center cursor-pointer"
                                  onClick={() => {
                                    togglePasswordVisibility('confirmPassword');
                                  }}
                                >
                                  {showPassword.confirmPassword ? (
                                    <MyIcon
                                      iconName="closeEye"
                                      color="#595959"
                                    />
                                  ) : (
                                    <MyIcon
                                      iconName="PreviewIcon"
                                      color="#595959"
                                    />
                                  )}
                                </span>
                              </div>
                              {changePassError && (
                                <p className="text-red-500">
                                  {changePassError}
                                </p>
                              )}
                              {changePassSuccess && (
                                <p className="text-green-500">
                                  {changePassSuccess}
                                </p>
                              )}
                              <div className="flex flex-col sm:flex-row justify-center sm:justify-end items-center gap-5 mt-5">
                                <Link
                                  to={'/admin/forgot-password'}
                                  className="text-yellowtacx text-sm md:text-base font-[500]"
                                >
                                  Forgot your password?
                                </Link>
                                <div className="w-full sm:w-auto">
                                  <button
                                    className="bg-yellowtacx text-sm md:text-base font-[500] rounded-lg text-[#000000] w-full sm:w-[165px] h-[40px] hover:bg-[#594255] hover:text-[#ffffff] transition-all duration-200"
                                    onClick={handlePasswordChange}
                                    disabled={changePasswordLoading}
                                  >
                                    {changePasswordLoading ? (
                                      <div
                                        role="status"
                                        className="flex items-center justify-center"
                                      >
                                        <div className="inline-block h-4 w-4 mr-2 animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"></div>
                                      </div>
                                    ) : (
                                      <span>Change password</span>
                                    )}
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {isSaveInfoSuccess && (
        <>
          <div className="fixed grid content-center top-0 bottom-0 left-0 z-[999999] w-screen h:screen bg-[#59425580] bg-opacity-70 fade-in-animate">
            <div className="bg-purpletacx flex m-auto justify-center rounded-lg relative w-[90%] md:w-1/2 xl:w-1/3 slideInFromTop-animate">
              <div className="p-5 sm:p-10 py-15 w-full flex flex-col items-center">
                <MyIcon iconName="success" />
                <div className="mt-8 text-center text-white">
                  <p className="font-extrabold text-2xl">Successful</p>
                  <p className="text-md">
                    Your profile details have been successfully saved.
                  </p>
                </div>
                <div
                  className="mt-4 px-25 sm:px-35 py-3 rounded-full bg-[#DFC749] text-black font-bold cursor-pointer"
                  onClick={() => {
                    setIsSaveInfoSuccess(false);
                    navigate('/admin/my-profile');
                  }}
                >
                  Okay!
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <MessageModel
        onClose={closeImageValidationModal}
        mainMessage={imageValidationMainMessage}
        message={imageValidationMessage}
        iconName="crossLarge"
        isOpen={isUploadErrorModalOpen}
        buttonName="Okay"
      />
    </DefaultLayout>
  );
};
export default ProfileEdit;
