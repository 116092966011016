import React from 'react';
import { Link } from 'react-router-dom';
import { selectUser } from '../store/slice/Userslice';
import { useSelector } from 'react-redux';


const NotFound = () => {
  const userDetails = useSelector(selectUser);
  const isAdmin = userDetails?.user?.user_role === 'Admin';
  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <div className="text-center">
        <h1 className="text-9xl">😮</h1>
        <h2 className="text-4xl font-semibold mt-6">Oops! Page Not Be Found</h2>
        <p className="mt-4 text-lg">
          Sorry but the page you are looking for does not exist.
        </p>
    {
      isAdmin ? (
        <Link
          to="/admin/dashboard"
          className="animate-bounce mt-6 inline-block bg-yellowtacx text-white py-2 px-4 rounded hover:bg-purpletacx"
        >
          Go to Admin Dashboard
        </Link>
      ):(
        <Link
          to="/"
          className="animate-bounce mt-6 inline-block bg-purpletacx text-white py-2 px-4 rounded hover:bg-[#433841]"
        >
          Back to TACX Homepage
        </Link>
      )
    }
       
      </div>
    </div>
  );
};

export default NotFound;
