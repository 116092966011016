import React, { useEffect, useState } from 'react';
import DefaultLayout from '../../../layout/Defaultlayout';
import { GetAllUsers, GetTeamMate } from '../../../api/Adminapi';
import { FaSearch } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import TeamMateUserView from '../Components/TeamMateUserView';
import MyIcon from '../../../components/cardeditor/icons/Myicon';
import CardDataStats from '../../../components/Carddatastats';
import Loader from '../../../common/loader/Index';
import Select from 'react-select';
import Pagination from '../../../components/pagination/Pagination';
import { LuView } from 'react-icons/lu';

// Define the user type
interface User {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  company_name?: string;
  amount_total?: number;
  plan_name?: string;
  created_at: string;
  is_active: boolean;
  expiration_date?: string;
  profile_picture?: string;
  phone_number?: string;
}

interface UserImage {
  profile_picture: string;
  id: any;
}
/* Dropdown filter  */
const filterOptions = [
  { value: 'All', label: 'All' },
  { value: 'Today', label: 'Today' },
  { value: 'This Month', label: 'This Month' },
  { value: 'Last Month', label: 'Last Month' },
  { value: 'This Year', label: 'This Year' },
];

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    borderRadius: '12px',
    padding: '0',
    borderColor: '#ffff',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#ffff',
    },
    fontSize: '14px',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#DFC749' : '#fff',
    color: state.isDisabled ? '#ccc' : state.isSelected ? '#fff' : '#000',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'transperant',
      // color: '#000',
    },
    fontSize: '14px',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: '#000',
  }),
  menu: (provided: any) => ({
    ...provided,
    borderRadius: '10px',
    overflow: 'hidden',
    fontSize: '14px',
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
};

const AdminDashboard: React.FC = () => {
  const URL = import.meta.env.VITE_API_URL;
  const [users, setUsers] = useState<User[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [buttonLoader, setButtonLoader] = useState<{ [key: number]: boolean }>(
    {},
  );
  const [filter, setFilter] = useState('All');
  const [isOpen, setIsOpen] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [teamMateModal, setTeamMateModal] = useState(false);
  const [isImageInmodal, setImageInmodal] = useState<UserImage | null>(null);
  const [userImageSrc, setUserImageSrc] = useState('profile.jpg');
  const [teamMateDetails, setTeamMateDetails] = useState<[]>([]);
  const navigate = useNavigate();
  useEffect(() => {
    const loadImages = async () => {
      const newImageSrcs: any = {};
      for (const user of users) {
        const profilePictureUrl = `${URL}/public/cards/images/${user.profile_picture}`;
        const exists = await checkImageExists(profilePictureUrl);
        newImageSrcs[user.id] = exists ? user.profile_picture : 'profile.jpg';
      }
      setUserImageSrc(newImageSrcs);
    };

    if (users?.length > 0) {
      loadImages();
    }
  }, [users]);

  const checkImageExists = async (url: any) => {
    try {
      const response = await fetch(url);
      return response.ok;
    } catch (error) {
      return false;
    }
  };

  const encodeId = (id: string) => {
    return btoa(id);
  };

  const CalculateTotals = (): {
    totalAmount: number;
    mostPreferredPlan: string | null;
    usersJoinedThisMonth: number;
  } => {
    if (!Array.isArray(users)) {
      console.error('Expected users to be an array');
      return {
        totalAmount: 0,
        mostPreferredPlan: null,
        usersJoinedThisMonth: 0,
      };
    }

    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    return users.reduce(
      (acc, user) => {
        const totalFieldValue = user.amount_total || 0;
        acc.totalAmount += totalFieldValue;

        const plan = user.plan_name;
        if (plan) {
          acc.planCounts[plan] = (acc.planCounts[plan] || 0) + 1;
          if (acc.planCounts[plan] > acc.maxCount) {
            acc.maxCount = acc.planCounts[plan];
            acc.mostPreferredPlan = plan;
          }
        }

        const createAt = new Date(user.created_at);
        if (
          createAt.getMonth() === currentMonth &&
          createAt.getFullYear() === currentYear
        ) {
          acc.usersJoinedThisMonth += 1;
        }

        return acc;
      },
      {
        totalAmount: 0,
        planCounts: {} as Record<string, number>,
        maxCount: 0,
        mostPreferredPlan: null as string | null,
        usersJoinedThisMonth: 0,
      },
    );
  };

  const { totalAmount, mostPreferredPlan, usersJoinedThisMonth } =
    CalculateTotals();

  useEffect(() => {
    fetchUserAndSubscriptions();
  }, []);

  const fetchUserAndSubscriptions = async () => {
    setLoading(true);
    try {
      const response = await GetAllUsers();
      const data = response?.data.user;
      setUsers(data);
      setFilteredUsers(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    const filtered = users.filter((user) =>
      `${user.first_name} ${user.last_name}`
        .toLowerCase()
        .includes(e.target.value.toLowerCase()),
    );
    setFilteredUsers(filtered);
    setCurrentPage(1);
  };

  const handleToggleFilter = (filterType: string) => {
    setFilter(filterType);
    const filtered = users.filter((user) => {
      const createAt = new Date(user.created_at);
      const now = new Date();
      if (filterType === 'This Month') {
        return (
          createAt.getMonth() === now.getMonth() &&
          createAt.getFullYear() === now.getFullYear()
        );
      }
      if (filterType === 'Last Month') {
        return (
          createAt.getMonth() === now.getMonth() - 1 &&
          createAt.getFullYear() === now.getFullYear()
        );
      }
      if (filterType === 'Today') {
        return createAt.toDateString() === now.toDateString();
      }
      if (filterType === 'This Year') {
        return createAt.getFullYear() === now.getFullYear();
      }
      return true; // 'All' filter returns all users
    });
    setFilteredUsers(filtered);
    setCurrentPage(1);
  };

  /* Pagination start */
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4; // Adjust this number as needed

  const totalItems = filteredUsers.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const paginatedUsers = filteredUsers.slice(startIndex, endIndex);

  // For Modal Fucntion
  const handleViewDetails = (user: any) => {
    const token = encodeId(user);
    navigate(`/admin/dashboard/user-details/${token}`);
  };

  // open the image
  const handleOpenImage = (user: any) => {
    setImageInmodal(user);
    setOpenImageModal(true);
  };

  const closeModal = () => {
    setOpenImageModal(false);
  };

  // team mate modal and handler
  const handleViewTeamMate = async (id: any) => {
    // Set loading for the specific button
    setButtonLoader((prevState) => ({ ...prevState, [id]: true }));
    try {
      const response = await GetTeamMate(id);
      const teamdata = response?.data.user;
      setTeamMateDetails(teamdata);
      setTeamMateModal(true);
    } catch (error) {
      console.error(error);
    } finally {
      // Reset loading for the specific button
      setButtonLoader((prevState) => ({ ...prevState, [id]: false }));
    }
  };
  const teamMateModalCose = () => {
    setTeamMateModal(false);
  };

  return (
    <DefaultLayout title="Admin Dashboard">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5">
            <CardDataStats
              title="Total Users"
              total={users.length}
              rate="0.43%"
              levelUp
            >
              <MyIcon iconName="about" color="#fff" />
            </CardDataStats>
            <CardDataStats
              title="Total Subscriptions"
              total={`$${totalAmount}`}
              rate="4.35%"
              levelUp
            >
              <MyIcon iconName="cancelSubscription" />
            </CardDataStats>
            <CardDataStats
              title="Most Preferred Plan"
              total={mostPreferredPlan || 'N/A'}
              rate="2.59%"
              levelUp
            >
              <MyIcon iconName="activeSubscription" />
            </CardDataStats>
            <CardDataStats
              title="New Clients This Month"
              total={usersJoinedThisMonth}
              rate="0.95%"
              levelDown
            >
              <MyIcon iconName="users" />
            </CardDataStats>
          </div>

          <div className="bg-white shadow-lg rounded-lg mt-10">
            <div className="flex flex-col sm:flex-row justify-between items-center mb-4 mt-2 bg-[#F7F7F7] p-4 rounded-lg ">
              <p className="font-medium text-md text-black animate-fade-in">
                Showing {Math.min(endIndex, totalItems)} of {totalItems} users
              </p>

              <div className="flex flex-col sm:justify-end sm:flex-row sm:items-center w-full sm:w-[70%] sm:space-x-4">
                {/* Search Bar */}
                <div className="relative w-full xl:w-[50%] sm:w-full mb-4 sm:mb-0">
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={handleSearch}
                    className="p-2 border border-stroke rounded-xl pr-20 w-full bg-white text-gray-800"
                  />
                  <FaSearch className="absolute right-5 top-1/2 transform -translate-y-1/2 text-gray-500" />
                </div>

                <div className="relative w-full sm:w-1/3 xl:w-[20%]">
                  <Select
                    options={filterOptions}
                    styles={customStyles}
                    placeholder="Actions"
                    className="border border-stroke focus:outline-none rounded-xl"
                    onMenuOpen={() => setIsOpen(true)}
                    onMenuClose={() => setIsOpen(false)}
                    value={filterOptions.find(
                      (option) => option.value === filter,
                    )}
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        handleToggleFilter(selectedOption.value);
                      }
                    }}
                  />
                  <span
                    className={`absolute top-0 right-3 h-full flex items-center pointer-events-none transition-transform duration-200 ease-in-out ${
                      isOpen ? 'rotate-180' : 'rotate-0'
                    } `}
                  >
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M7 10L12 15L17 10H7Z" fill="#000" />
                    </svg>
                  </span>
                </div>
              </div>
            </div>

            <div className="overflow-x-auto animate-fade-in scrollbar-custom">
              <table className="min-w-full rounded-lg overflow-hidden">
                <thead>
                  <tr className="hover:bg-gray-50 transition duration-200 ">
                    <th className="py-3 px-6 text-left border-b border-gray whitespace-nowrap">
                      No
                    </th>
                    <th className="py-3 px-6 text-left border-b border-gray whitespace-nowrap">
                      User Name
                    </th>
                    <th className="py-3 px-6 text-left border-b border-gray whitespace-nowrap">
                      Email
                    </th>
                    <th className="py-3 px-6 text-left border-b border-gray whitespace-nowrap">
                      Contact No
                    </th>
                    <th className="py-3 px-6 text-left border-b border-gray whitespace-nowrap">
                      Plan Name
                    </th>
                    <th className="py-3 px-6 text-left border-b border-gray whitespace-nowrap">
                      View
                    </th>
                    <th className="py-3 px-6 text-left border-b border-gray whitespace-nowrap">
                      Team Mate
                    </th>{' '}
                  </tr>
                </thead>
                <tbody>
                  {paginatedUsers?.length > 0 ? (
                    paginatedUsers?.map((user, index) => (
                      <tr
                        key={user.id}
                        className="hover:bg-gray-50 transition duration-200"
                      >
                        <td className="py-4 px-6 border-b border-gray">
                          {index + 1}
                        </td>

                        <td className="px-6 py-4 flex items-center whitespace-nowrap border-b border-gray ">
                          <div className="flex-shrink-0 h-12 w-12">
                            <img
                              src={`${URL}/public/cards/images/${userImageSrc[user.id] ?? 'profile.jpg'}`}
                              alt="user"
                              className="w-full h-full rounded-full"
                              onClick={() => handleOpenImage(user)}
                            />
                          </div>
                          <span className="ml-4 text-gray-800 font-medium">{`${user.first_name} ${user.last_name}`}</span>
                        </td>
                        <td className="py-4 px-6 border-b border-gray whitespace-nowrap">
                          {' '}
                          {user.email}
                        </td>
                        <td className="py-4 px-6 border-b border-gray whitespace-nowrap">
                          {user.phone_number || 'N/A'}
                        </td>
                        <td className="py-4 px-6 border-b border-gray whitespace-nowrap">
                          {user.plan_name || 'N/A'}
                        </td>
                        <td className="py-4 px-6 border-b border-gray text-center">
                          <button
                            className="flex  justify-center items-center w-10 h-10 rounded-full bg-purpletacx cursor-pointer hover:bg-yellowtacx transition duration-200"
                            onClick={() => handleViewDetails(user.id)} // Click handler for view functionality
                          >
                            <LuView
                              className="w-6 h-6 text-white transition duration-200 hover:text-purpletacx" // Change text color on hover
                            />
                          </button>
                        </td>

                        <td className="py-4 px-6 border-b border-gray text-center">
                          <button
                            className={`flex justify-center items-center w-10 h-10 rounded-full bg-purpletacx cursor-pointer hover:bg-yellowtacx transition duration-200 ${buttonLoader[user.id] ? 'opacity-50 cursor-not-allowed' : ''}`}
                            onClick={() => handleViewTeamMate(user.id)} // Click handler for teammate functionality
                            disabled={buttonLoader[user.id]} // Disable button if loading
                          >
                            <LuView
                              className="w-6 h-6 text-white transition duration-200 hover:text-purpletacx" // Change text color on hover
                            />
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={7} className="py-4 text-center">
                        No users found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            {/* Pagination */}
            {users.length > 4 && (
              <div className="py-1">
                <Pagination
                  totalPages={totalPages}
                  setCurrentPage={setCurrentPage}
                  startIndex={startIndex}
                  endIndex={endIndex}
                  totalItems={totalItems}
                />
              </div>
            )}
          </div>
        </>
      )}

      {openImageModal && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
          onClick={closeModal}
        >
          <div className="bg-white p-4 rounded-lg">
            <img
              // src={`${URL}/public/cards/images/${isImageInmodal?.profile_picture || 'profile.jpg'}`}
              src={`${URL}/public/cards/images/${userImageSrc[isImageInmodal?.id] ?? 'profile.jpg'}`}
              alt="Zoomed User Profile"
              className="w-96 h-96 object-cover" // Zoomed-in size
            />
          </div>
        </div>
      )}
      <TeamMateUserView
        isOpen={teamMateModal}
        teamMateDetails={teamMateDetails}
        onClose={teamMateModalCose}
      />
    </DefaultLayout>
  );
};

export default AdminDashboard;
