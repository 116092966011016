import MyIcon from '../cardeditor/icons/Myicon';
const Index = () => {
  return (
    <>
      <footer className="bg-[#594255] text-white w-full shadow dark:bg-gray-900 ">
        <div className="w-full px-11 mx-auto p-4 md:py-8">
          <div className="flex items-center justify-between">
            <div className="hidden sm:block">
              <a
                href=""
                className="flex items-center mb-2 sm:mb-0 space-x-3 rtl:space-x-reverse"
              >
                <MyIcon iconName="tacx" />
              </a>
            </div>
            <div className="block sm:hidden">
              <a
                href=""
                className="flex items-center mb-2 sm:mb-0 space-x-3 rtl:space-x-reverse"
              >
                <MyIcon iconName="tacxSmall" />
              </a>
            </div>
            <ul className="flex flex-wrap items-center mb-2 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
              <li>
                <a href="#" className="hover:underline me-4 md:me-6">
                  About
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline me-4 md:me-6">
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>
          <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
          <span className="block text-sm text-gray-500 text-center dark:text-gray-400">
            © 2024{' '}
            <a href="" className="hover:underline">
              TACX
            </a>
            . All Rights Reserved.
          </span>
        </div>
      </footer>
    </>
  );
};
export default Index;
