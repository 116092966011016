import { useEffect, useState } from 'react';
const Button = ({
  bgcolor = '#1d4ed8',
  name = 'Button',
  fontColor = '#fff',
  alignItem = 'center',
  full_width = false,
  buttonPadding = [10, 10, 10, 10],
  buttonMargin = [0, 0, 0, 0],
  buttonRadius = [0, 0, 0, 0],
  url = 'http://example.com/1',
  buttonTypography = {
    type: 'editor',
    family: 'poppins, font-poppins',
    nameFontSize: 'text-xl',
    typographyWeight: 'font-[600]',
    typographyStyle: 'normal',
  },
  globalStyling = {
    fontColor: '#fff',
    linkColor: '#000000',
    fontSize: 'text-xl',
    groundColor: '#000000',
    status: false,
    ignoreFieldArray: [] as string[],
    globalChangesList: [] as string[],
    buttonPadding: [] as number[],
    buttonMargin: [] as number[],
    buttonRadius: [] as number[],
  },
}) => {
  let handleGlobalStyling = () => {
    let data = {
      fontColor: globalStyling.fontColor,
      nameFontSize: '',
      bgcolor: globalStyling.groundColor,
      buttonPadding: globalStyling.buttonPadding,
      buttonMargin: globalStyling.buttonMargin,
      buttonRadius: globalStyling.buttonRadius,
    };

    if (globalStyling.status) {
      if (globalStyling?.ignoreFieldArray?.length === 0) {
        if (globalStyling?.globalChangesList.includes('fontColor')) {
          data.fontColor = globalStyling.fontColor;
        }
      } else {
        if (globalStyling?.ignoreFieldArray?.includes('fontColor')) {
          data.fontColor = fontColor;
        } else {
          data.fontColor = globalStyling.fontColor;
        }
      }
      if (globalStyling?.ignoreFieldArray?.length === 0) {
        if (globalStyling?.globalChangesList.includes('fontSize')) {
          data.nameFontSize = globalStyling.fontSize;
        }
      } else {
        if (
          globalStyling?.ignoreFieldArray?.includes(
            'buttonTypography.nameFontSize',
          )
        ) {
          data.nameFontSize = buttonTypography.nameFontSize;
        } else {
          data.nameFontSize = globalStyling.fontSize;
        }
      }
      if (globalStyling?.ignoreFieldArray?.length === 0) {
        if (globalStyling?.globalChangesList.includes('groundColor')) {
          data.bgcolor = globalStyling.groundColor;
        }
      } else {
        if (globalStyling?.ignoreFieldArray?.includes('backgroundColor')) {
          data.bgcolor = bgcolor;
        } else {
          data.bgcolor = globalStyling.groundColor;
        }
      }
      if (globalStyling?.ignoreFieldArray?.length === 0) {
        if (globalStyling?.globalChangesList.includes('buttonPadding')) {
          data.buttonPadding = globalStyling.buttonPadding;
        }
      } else {
        if (globalStyling?.ignoreFieldArray?.includes('buttonPadding')) {
          data.buttonPadding = buttonPadding;
        } else {
          data.buttonPadding = globalStyling.buttonPadding;
        }
      }
      if (globalStyling?.ignoreFieldArray?.length === 0) {
        if (globalStyling?.globalChangesList.includes('buttonRadius')) {
          data.buttonRadius = globalStyling.buttonRadius;
        }
      } else {
        if (globalStyling?.ignoreFieldArray?.includes('buttonRadius')) {
          data.buttonRadius = buttonRadius;
        } else {
          data.buttonRadius = globalStyling.buttonRadius;
        }
      }
      if (globalStyling?.ignoreFieldArray?.length === 0) {
        if (globalStyling?.globalChangesList.includes('buttonMargin')) {
          data.buttonMargin = globalStyling.buttonMargin;
        }
      } else {
        if (globalStyling?.ignoreFieldArray?.includes('buttonMargin')) {
          data.buttonMargin = buttonMargin;
        } else {
          data.buttonMargin = globalStyling.buttonMargin;
        }
      }
    } else {
      if (globalStyling?.ignoreFieldArray?.includes('fontColor')) {
        data.fontColor = fontColor;
      }
      if (
        globalStyling?.ignoreFieldArray?.includes(
          'buttonTypography.nameFontSize',
        )
      ) {
        data.nameFontSize = buttonTypography.nameFontSize;
      }
      if (globalStyling?.ignoreFieldArray?.includes('backgroundColor')) {
        data.bgcolor = bgcolor;
      }
      if (globalStyling?.ignoreFieldArray?.includes('buttonPadding')) {
        data.buttonPadding = buttonPadding;
      }
      if (globalStyling?.ignoreFieldArray?.includes('buttonMargin')) {
        data.buttonMargin = buttonMargin;
      }
      if (globalStyling?.ignoreFieldArray?.includes('buttonRadius')) {
        data.buttonRadius = buttonRadius;
      }
    }
    return data;
  };
  const [globalStylingData, setGlobalStylingData] = useState(() =>
    handleGlobalStyling(),
  );
  useEffect(() => {
    let data = handleGlobalStyling();
    setGlobalStylingData(data);
  }, [globalStyling]);
  return (
    <div
      className={`my-2 flex justify-${alignItem}`}
      style={{
        margin: `${globalStylingData.buttonMargin?.[0] ?? 0}px ${globalStylingData.buttonMargin?.[1] ?? 0}px ${globalStylingData.buttonMargin?.[2] ?? 0}px ${globalStylingData.buttonMargin?.[3] ?? 0}px`,
      }}
    >
      <a
        href={url}
        target="_blank"
        className={`focus:ring-4 focus:ring-blue-300 text-center mb-2 ${buttonTypography.typographyWeight} ${
          full_width ? 'w-auto' : 'w-full'
        } mx-2`}
        style={{
          backgroundColor: globalStylingData.bgcolor,
          color: globalStylingData?.fontColor,
          padding: `${globalStylingData.buttonPadding?.[0] || buttonPadding?.[0]}px ${globalStylingData.buttonPadding?.[1] || buttonPadding?.[1]}px ${globalStylingData.buttonPadding?.[2] || buttonPadding?.[2]}px ${globalStylingData.buttonPadding?.[3] || buttonPadding?.[3]}px`,
          borderRadius: `${globalStylingData.buttonRadius?.[0] ?? 10}px ${globalStylingData.buttonRadius?.[1] ?? 10}px ${globalStylingData.buttonRadius?.[2] ?? 10}px ${globalStylingData.buttonRadius?.[3] ?? 10}px`,
          fontFamily: buttonTypography.family,
          fontStyle: buttonTypography.typographyStyle,
          fontSize: globalStylingData.nameFontSize,
        }}
      >
        {name}
      </a>
    </div>
  );
};
export default Button;
