import React, { useState } from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  FacebookIcon,
  WhatsappIcon,
  TwitterIcon,
  LinkedinIcon,
  TelegramIcon,
} from 'react-share';
import { FiShare2 } from 'react-icons/fi'; // Floating Share icon

interface SharingMediaProps {
  url: string;
  title: string;
}

const SharingMedia: React.FC<SharingMediaProps> = ({ url, title }) => {
  const [isOpen, setIsOpen] = useState(false);

  // Toggle the display of social media icons
  const toggleShareMenu = () => {
    setIsOpen((prev) => !prev);
  };

  // WhatsApp sharing function

  return (
    // <div className="fixed bottom-5 right-5 z-50">
    <div className="">
      {/* Floating share button */}
      {/* <button
        onClick={toggleShareMenu}
        className="text-purpletacx  transition duration-300 ease-in-out "
        aria-label="Share"
      >
        <FiShare2 size={24} />
      </button> */}

      {/* {isOpen && ( */}
        <div className="flex flex-row bg-white justify-center rounded-b-lg absolute right-0 -bottom-10 w-full  items-center gap-3 mt-3 py-3 transition-all duration-300 ease-in-out ">
          <WhatsappShareButton url={url} title={title}>
            <WhatsappIcon size={35} round />
          </WhatsappShareButton>

          <TwitterShareButton
            url={url}
            title={title}
            className="hover:scale-110 transition-transform duration-200 ease-in-out"
          >
            <TwitterIcon size={35} round />
          </TwitterShareButton>

          <FacebookShareButton
            url={url}
            quote={title}
            className="hover:scale-110 transition-transform duration-200 ease-in-out"
          >
            <FacebookIcon size={35} round />
          </FacebookShareButton>

          <LinkedinShareButton
            url={url}
            title={title}
            className="hover:scale-110 transition-transform duration-200 ease-in-out"
          >
            <LinkedinIcon size={35} round />
          </LinkedinShareButton>

          <TelegramShareButton
            url={url}
            title={title}
            className="hover:scale-110 transition-transform duration-200 ease-in-out"
          >
            <TelegramIcon size={35} round />
          </TelegramShareButton>
        </div>
      {/* )} */}
    </div>
  );
};

export default SharingMedia;
