import styled, { keyframes } from "styled-components";
import Logo from "../../images/logo/TACX Brown Logo.svg";

// Define keyframe animations
const bounce = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const rotate = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
`;

// Styled components
const LoaderWrapper = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;

  .logo {
    animation: ${bounce} 1s infinite; /* Bounce animation */
    position: relative; /* Position relative for spinner positioning */
    z-index: 10; /* Ensure logo is above spinner */
  }

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 150px; /* Increased width of the spinner */
    height: 150px; /* Increased height of the spinner */
    margin-left: -75px; /* Center spinner horizontally */
    margin-top: -75px; /* Center spinner vertically */
    border: 10px solid transparent; /* Outer spinner color */
    border-top: 10px solid #5A4355; /* Color for the top */
    border-radius: 50%;
    animation: ${spin} 1s linear infinite; /* Spinner animation */
  }

  .logo-animation {
    animation: ${rotate} 1s infinite; /* Rotate animation */
  }
`;

const Loader = () => (
  <LoaderWrapper>
    <div className="logo logo-animation">
      <img src={Logo} alt="logo" height={130} width={130} /> {/* Increased logo size */}
      <div className="spinner" />
    </div>
  </LoaderWrapper>
);

export default Loader;
