import React, { useEffect, useState } from 'react';
import DefaultLayout from '../../layout/Defaultlayout';
import { useParams } from 'react-router-dom';
import { GetSubscriptionUserDetails } from '../../api/Adminapi';
import MyIcon from '../cardeditor/icons/Myicon';
import Loader from '../../common/loader/Index';
const URL = import.meta.env.VITE_API_URL;

interface UserDetails {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  user_role: string;
  address?: string;
  city?: string;
  state?: string;
  country?: string;
  postal_code?: string;
  company_name?: string;
  industry?: string;
  job_title?: string;
  website?: string;
  office_number?: string;
  teamSize?: string;
  profile_picture?: string; // Added to display profile picture
  subscription_type?: string;
  status?: string;
  plan_name?: string;
  shipping_cost?: string;
  stripe_payment_id?: string;
  subscription_period?: string;
  user_limit?: number;
  created_at: any;
  expire_date: any;
  created_date: any;
  amount_total: string;
}

type ComponentKeys = 'userInfo' | 'address' | 'businessInfo' | 'subscription';

const userSubscription: React.FC = () => {
  const { userId } = useParams();
  const [loading, setLoading] = useState<boolean>(true);

  const [userDetails, setUserDetails] = useState<UserDetails | null>(null);
  const [selectedComponent, setSelectedComponent] = useState({
    userInfo: false,
    address: false,
    businessInfo: false,
    subscription: false,
  });

  const decodeToken = (token: string) => {
    return atob(token);
  };

  useEffect(() => {
    fetchUserDetail();
  }, [userId]);

  const fetchUserDetail = async (): Promise<void> => {
    if (userId) {
      const decodedUserId = decodeToken(userId);
      setLoading(true);
      try {
        const response = await GetSubscriptionUserDetails(decodedUserId);
        if (response) {
          setUserDetails(response.data.user[0]);
        }
      } catch (error) {
        console.error('Error fetching user detail:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleToggleAccordion = (component: ComponentKeys) => {
    setSelectedComponent((prev) => ({
      ...prev,
      [component]: !prev[component],
    }));
  };

  return (
    <DefaultLayout title="User Information">
      {loading ? (
        <Loader />
      ) : (
        <div className="max-w-full mx-auto p-4">
          {/* Subscription Section */}
          <div className="bg-white shadow-md w-full rounded-lg mb-4">
            <div
              className="px-6 py-4 border-b border-[#EDEDED] cursor-pointer flex justify-between items-center"
              onClick={() => handleToggleAccordion('subscription')}
            >
              <h4 className="text-[#594255] font-semibold text-lg md:text-xl">
                Subscription Information
              </h4>
              <span
                className={`pointer-events-none transition-transform duration-200 ease-in-out ${selectedComponent.subscription ? 'rotate-180' : 'rotate-0'}`}
              >
                <MyIcon iconName="dropdown" />
              </span>
            </div>
            {selectedComponent.subscription && (
              <div className="p-6 grid grid-cols-1 md:grid-cols-2 gap-4">
                {/* Existing Subscription Fields */}
                <div className="flex flex-col">
                  <label className="font-bold text-gray-800">
                    Subscription Type:
                  </label>
                  <input
                    type="text"
                    value={userDetails?.plan_name || 'N/A'}
                    readOnly
                    className="mt-1 border border-gray-300 rounded p-2 text-base md:text-lg text-gray-800"
                  />
                </div>
                <div className="flex flex-col">
                  <label className="font-bold text-gray-800">
                    Subscription Status:
                  </label>
                  <input
                    type="text"
                    value={userDetails?.status || 'N/A'}
                    readOnly
                    className="mt-1 border border-gray-300 rounded p-2 text-base md:text-lg text-gray-800"
                  />
                </div>
                <div className="flex flex-col">
                  <label className="font-bold text-gray-800">
                    Subscription Amount:
                  </label>
                  <input
                    type="text"
                    value={`$${userDetails?.amount_total} ` || 'N/A'}
                    readOnly
                    className="mt-1 border border-gray-300 rounded p-2 text-base md:text-lg text-gray-800"
                  />
                </div>
                <div className="flex flex-col">
                  <label className="font-bold text-gray-800">
                    Postal Code:
                  </label>
                  <input
                    type="text"
                    value={userDetails?.postal_code || 'N/A'}
                    readOnly
                    className="mt-1 border border-gray-300 rounded p-2 text-base md:text-lg text-gray-800"
                  />
                </div>
                <div className="flex flex-col">
                  <label className="font-bold text-gray-800">State:</label>
                  <input
                    type="text"
                    value={
                      userDetails?.state
                        ? JSON.parse(userDetails.state).label
                        : 'N/A'
                    }
                    readOnly
                    className="mt-1 border border-gray-300 rounded p-2 text-base md:text-lg text-gray-800"
                  />
                </div>
                <div className="flex flex-col">
                  <label className="font-bold text-gray-800">
                    Subscription Period:
                  </label>
                  <input
                    type="text"
                    value={userDetails?.subscription_period || 'N/A'}
                    readOnly
                    className="mt-1 border border-gray-300 rounded p-2 text-base md:text-lg text-gray-800"
                  />
                </div>
                <div className="flex flex-col">
                  <label className="font-bold text-gray-800">Team Size:</label>
                  <input
                    type="text"
                    value={userDetails?.teamSize || 'N/A'}
                    readOnly
                    className="mt-1 border border-gray-300 rounded p-2 text-base md:text-lg text-gray-800"
                  />
                </div>
                <div className="flex flex-col">
                  <label className="font-bold text-gray-800">User Limit:</label>
                  <input
                    type="text"
                    value={userDetails?.user_limit || 'N/A'}
                    readOnly
                    className="mt-1 border border-gray-300 rounded p-2 text-base md:text-lg text-gray-800"
                  />
                </div>
                <div className="flex flex-col">
                  <label className="font-bold text-gray-800">User Role:</label>
                  <input
                    type="text"
                    value={userDetails?.user_role || 'N/A'}
                    readOnly
                    className="mt-1 border border-gray-300 rounded p-2 text-base md:text-lg text-gray-800"
                  />
                </div>
                <div className="flex flex-col">
                  <label className="font-bold text-gray-800">Website:</label>
                  <input
                    type="text"
                    value={userDetails?.website || 'N/A'}
                    readOnly
                    className="mt-1 border border-gray-300 rounded p-2 text-base md:text-lg text-gray-800"
                  />
                </div>
                {/* New Subscription Date Fields */}
                <div className="flex flex-col">
                  <label className="font-bold text-gray-800">
                    Start Subscription Date:
                  </label>
                  <input
                    type="text"
                    value={
                      userDetails?.created_date
                        ? new Date(userDetails.created_date).toLocaleString(
                            'en-GB',
                            {
                              year: 'numeric',
                              month: 'short',
                              day: 'numeric',
                              hour: '2-digit',
                              minute: '2-digit',
                              hour12: true, // Change to true if you prefer 12-hour format
                            },
                          )
                        : 'N/A'
                    }
                    readOnly
                    className="mt-1 border border-gray-300 rounded p-2 text-base md:text-lg text-gray-800"
                  />
                </div>
                <div className="flex flex-col">
                  <label className="font-bold text-gray-800">
                    End Subscription Date:
                  </label>
                  <input
                    type="text"
                    value={
                      userDetails?.expire_date
                        ? new Date(userDetails.expire_date).toLocaleString(
                            'en-GB',
                            {
                              year: 'numeric',
                              month: 'short',
                              day: 'numeric',
                              hour: '2-digit',
                              minute: '2-digit',
                              hour12: true, // Change to true if you prefer 12-hour format
                            },
                          )
                        : 'N/A'
                    }
                    readOnly
                    className="mt-1 border border-gray-300 rounded p-2 text-base md:text-lg text-gray-800"
                  />
                </div>
              </div>
            )}
          </div>

          {/* User Information Section */}
          <div className="bg-white shadow-md w-full rounded-lg mb-4">
            <div
              className="px-6 py-4 border-b border-[#EDEDED] cursor-pointer flex justify-between items-center"
              onClick={() => handleToggleAccordion('userInfo')}
            >
              <h4 className="text-[#594255] font-semibold text-lg md:text-xl">
                User Information
              </h4>
              <span
                className={`pointer-events-none transition-transform duration-200 ease-in-out ${selectedComponent.userInfo ? 'rotate-180' : 'rotate-0'}`}
              >
                <MyIcon iconName="dropdown" />
              </span>
            </div>
            {selectedComponent.userInfo && (
              <div className="p-6 grid grid-cols-1 md:grid-cols-2 gap-4">
                {/* Merged Profile Info */}
                <div className="flex items-center text-center md:text-left mb-4">
                  <div className="w-32 h-32 md:w-40 md:h-40 rounded-full overflow-hidden border-2 border-gray-200 mx-auto md:mx-0">
                    <img
                      src={`${URL}/public/cards/images/${userDetails?.profile_picture || 'profile.jpg'}`}
                      alt="User Profile"
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="flex flex-col justify-center ml-4">
                    <p className="text-2xl md:text-3xl font-bold">{`${userDetails?.first_name} ${userDetails?.last_name}`}</p>
                    <p className="text-base md:text-lg text-gray-600">
                      {userDetails?.user_role}
                    </p>
                  </div>
                </div>

                {/* User Information Fields */}
                <div className="flex flex-col md:col-span-2 mb-4">
                  {/* Email and Full Name Fields Side by Side */}
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="flex flex-col">
                      <label className="font-bold text-gray-800">Email:</label>
                      <input
                        type="email"
                        value={userDetails?.email || ''}
                        readOnly
                        className="mt-1 border border-gray-300 rounded p-2 text-base md:text-lg text-gray-800"
                      />
                    </div>
                    <div className="flex flex-col">
                      <label className="font-bold text-gray-800">
                        Full Name:
                      </label>
                      <input
                        type="text"
                        value={`${userDetails?.first_name} ${userDetails?.last_name}`}
                        readOnly
                        className="mt-1 border border-gray-300 rounded p-2 text-base md:text-lg text-gray-800"
                      />
                    </div>
                  </div>

                  {/* User Role and Account Creation Date Fields Side by Side */}
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                    <div className="flex flex-col">
                      <label className="font-bold text-gray-800">
                        User Role:
                      </label>
                      <input
                        type="text"
                        value={userDetails?.user_role || ''}
                        readOnly
                        className="mt-1 border border-gray-300 rounded p-2 text-base md:text-lg text-gray-800"
                      />
                    </div>
                    <div className="flex flex-col">
                      <label className="font-bold text-gray-800">
                        Account Creation Date:
                      </label>
                      <input
                        type="text"
                        value={
                          userDetails?.created_at
                            ? new Date(
                                userDetails.created_at,
                              ).toLocaleDateString()
                            : 'N/A'
                        }
                        readOnly
                        className="mt-1 border border-gray-300 rounded p-2 text-base md:text-lg text-gray-800"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Address Section */}
          <div className="bg-white shadow-md w-full rounded-lg mb-4">
            <div
              className="px-6 py-4 border-b border-[#EDEDED] cursor-pointer flex justify-between items-center"
              onClick={() => handleToggleAccordion('address')}
            >
              <h4 className="text-[#594255] font-semibold text-lg md:text-xl">
                Address
              </h4>
              <span
                className={`pointer-events-none transition-transform duration-200 ease-in-out ${selectedComponent.address ? 'rotate-180' : 'rotate-0'}`}
              >
                <MyIcon iconName="dropdown" />
              </span>
            </div>
            {selectedComponent.address && (
              <div className="p-4 md:p-6 grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="flex flex-col">
                  <label className="font-bold text-gray-800">
                    Street Address:
                  </label>
                  <input
                    type="text"
                    value={userDetails?.address || 'N/A'}
                    readOnly
                    className="mt-1 border border-gray-300 rounded p-2 text-base md:text-lg text-gray-800"
                  />
                </div>
                <div className="flex flex-col">
                  <label className="font-bold text-gray-800">City:</label>
                  <input
                    type="text"
                    value={userDetails?.city || 'N/A'}
                    readOnly
                    className="mt-1 border border-gray-300 rounded p-2 text-base md:text-lg text-gray-800"
                  />
                </div>
                <div className="flex flex-col">
                  <label className="font-bold text-gray-800">
                    Postal Code:
                  </label>
                  <input
                    type="text"
                    value={userDetails?.postal_code || 'N/A'}
                    readOnly
                    className="mt-1 border border-gray-300 rounded p-2 text-base md:text-lg text-gray-800"
                  />
                </div>
                <div className="flex flex-col">
                  <label className="font-bold text-gray-800">State:</label>
                  <input
                    type="text"
                    value={
                      userDetails?.state
                        ? JSON.parse(userDetails.state).label
                        : 'N/A'
                    }
                    readOnly
                    className="mt-1 border border-gray-300 rounded p-2 text-base md:text-lg text-gray-800"
                  />
                </div>
                <div className="flex flex-col md:col-span-2">
                  <label className="font-bold text-gray-800">Country:</label>
                  <input
                    type="text"
                    value={
                      userDetails?.country
                        ? JSON.parse(userDetails.country).label
                        : 'N/A'
                    }
                    readOnly
                    className="mt-1 border border-gray-300 rounded p-2 text-base md:text-lg text-gray-800"
                  />
                </div>
              </div>
            )}
          </div>

          {/* Business Info Section */}
          <div className="bg-white shadow-md w-full rounded-lg mb-4">
            <div
              className="px-6 py-4 border-b border-[#EDEDED] cursor-pointer flex justify-between items-center"
              onClick={() => handleToggleAccordion('businessInfo')}
            >
              <h4 className="text-[#594255] font-[600] text-lg md:text-xl">
                Business Information
              </h4>
              <span
                className={`pointer-events-none transition-transform duration-200 ease-in-out ${selectedComponent.businessInfo ? 'rotate-180' : 'rotate-0'}`}
              >
                <MyIcon iconName="dropdown" />
              </span>
            </div>
            {selectedComponent.businessInfo && (
              <div className="p-6 grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="flex flex-col">
                  <label className="font-medium">Company Name:</label>
                  <input
                    type="text"
                    value={userDetails?.company_name || 'N/A'}
                    readOnly
                    className="mt-1 border border-gray-300 rounded p-2"
                  />
                </div>
                <div className="flex flex-col">
                  <label className="font-medium">Industry:</label>
                  <input
                    type="text"
                    value={userDetails?.industry || 'N/A'}
                    readOnly
                    className="mt-1 border border-gray-300 rounded p-2"
                  />
                </div>
                <div className="flex flex-col">
                  <label className="font-medium">Job Title:</label>
                  <input
                    type="text"
                    value={
                      userDetails?.job_title
                        ? JSON.parse(userDetails.job_title).join(', ')
                        : 'N/A'
                    }
                    readOnly
                    className="mt-1 border border-gray-300 rounded p-2"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </DefaultLayout>
  );
};

export default userSubscription;
