import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../store/slice/Userslice';
import DefaultLayout from '../../../layout/Defaultlayout';
import { getIndustryList } from '../../../api/Api';
import Select, { StylesConfig } from 'react-select';
import { FiEdit } from 'react-icons/fi'; // Importing the edit icon

const customStyles: StylesConfig<any, false> = {
  control: (provided: any) => ({
    ...provided,
    borderRadius: '8px',
    border: 'none',
    background: '#EFEDEE',
    padding: '2px',
    boxShadow: 'none',
    fontSize: '16px',
    '&:hover': {
      borderColor: '#DFC749',
    },
  }),
  option: (provided: any, state: { isFocused: any }) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#DFC749' : '#fff',
    color: state.isFocused ? '#fff' : '#000',
    '&:hover': {
      backgroundColor: '#DFC749',
      color: '#fff',
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: '#000',
  }),
  menu: (provided: any) => ({
    ...provided,
    borderRadius: '10px',
    overflow: 'hidden',
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
};

const ProfilePage: React.FC = () => {
  const URL = import.meta.env.VITE_API_URL;
  const userDetails = useSelector(selectUser);
  const [userData, setUserData] = useState(userDetails?.user);
  const [industryOption, setIndustryOption] = useState<
    { value: string; label: string }[]
  >([{ value: '', label: 'Select' }]);
  const [imageSrc, setImageSrc] = useState('profile.jpg');

  useEffect(() => {
    setUserData(userDetails?.user);
  }, [userDetails]);

  useEffect(() => {
    const loadImage = async () => {
      if (userData?.profile_picture) {
        const profilePictureUrl = `${URL}/public/cards/images/${userData.profile_picture}`;
        const exists = await checkImageExists(profilePictureUrl);
        if (exists) {
          setImageSrc(userData.profile_picture);
        }
      }
    };
    loadImage();
  }, [userData]);

  const checkImageExists = async (url: any) => {
    try {
      const response = await fetch(url);
      return response.ok;
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    const fetchAllIndustryList = async () => {
      const List = await getIndustryList();
      if (List?.data.content) {
        const industryList = List?.data.content;
        const formattedOptions = industryList.map((industry: any) => ({
          value: industry.id,
          label: industry.label,
        }));
        setIndustryOption([{ value: '', label: 'N/A' }, ...formattedOptions]);
      }
    };
    fetchAllIndustryList();
  }, []);



  const customFilter = (
    option: any,
    inputValue: string
  ): boolean => {
    return option?.label?.toLowerCase().startsWith(inputValue?.toLowerCase());
  };

  return (
    <DefaultLayout title="My Profile">
      <div className="flex flex-col md:flex-row gap-6 p-4">
        {/* Left Section */}
        <div className="bg-white shadow-lg rounded-lg p-6 w-full md:w-1/3 relative">
          <div className="flex flex-col items-center">
          <div className="flex">
            <div className="rounded-full overflow-hidden shadow-lg  w-32 h-32">
              <img
                src={`${URL}/public/cards/images/${imageSrc}`}
                alt="user"
                className="w-full h-full object-cover"
              />
            </div>
            {/* Edit Icon positioned at the top-right without overlapping */}
            <div   className="absolute top-8 right-10 bg-white p-1" >
            <Link
              to={`/admin/my-profile/edit-information`}
            
            >
              <FiEdit className="text-purpletacx text-2xl cursor-pointer hover:text-yellowtacx transition-colors duration-300" />
            </Link>
            </div>
           
          </div>
            <div className="mt-4 text-center">
              <p className="text-purpletacx font-semibold text-lg">
                {userData?.first_name} {userData?.last_name}
              </p>
              <p className="text-black font-semibold text-sm">
                {userData?.user_role}
              </p>
            </div>
          </div>
        </div>

        {/* Right Section */}
        <div className="bg-white shadow-lg rounded-lg p-6 w-full md:w-2/3">
          {/* Profile Information */}
          <div className="flex flex-col md:flex-row gap-4 mb-6">
            <div className="flex-1">
              <label className="text-black font-bold">Email</label>
              <input
                type="text"
                value={userData?.email || ''}
                readOnly
                className="mt-1 rounded-lg w-full bg-[#EFEDEE] py-2 px-4 text-[#000000] outline-none focus-visible:shadow-none"
              />
            </div>
            {userData?.phone_number && (
              <div className="flex-1">
                <label className="text-black font-bold">Cell Phone</label>
                <input
                  type="text"
                  value={userData?.phone_number}
                  readOnly
                  className="mt-1 rounded-lg w-full bg-[#EFEDEE] py-2 px-4 text-[#000000] outline-none focus-visible:shadow-none"
                />
              </div>
            )}
          </div>

          {/* Job Title and Industry Fields */}
          <div className="flex flex-col md:flex-row gap-4 mb-6">
            <div className="flex-1">
              <label className="text-black font-bold">Company Name</label>
              <input
                type="text"
                value={userData?.company_name}
                readOnly
                placeholder="Job Title"
                className="mt-1 rounded-lg w-full bg-[#EFEDEE] py-2 px-4 text-[#000000] outline-none focus-visible:shadow-none"
              />
            </div>
            <div className="flex-1">
              <label className="text-black font-bold">Industry</label>
              <Select
                options={industryOption}
                styles={customStyles}
                className="mt-1"
                placeholder="Select"
                value={industryOption.find(
                  (option) => option.value === userData?.industry,
                )}
                filterOption={customFilter}
                isDisabled
              />
            </div>
          </div>

          {/* Website */}
          <div className="flex flex-col xl:flex-row justify-center gap-5 mb-6">
            <div className="w-full">
              <label className="text-black font-bold">Website</label>
              <input
                type="text"
                value={userData?.website || ''}
                readOnly
                className="mt-1 rounded-lg w-full bg-[#EFEDEE] py-2 px-4 text-[#000000] outline-none focus-visible:shadow-none"
              />
            </div>
          </div>

          {/* About Business */}
          <div className="w-full">
            <h3 className="font-bold text-black">About Business</h3>
            <textarea
              value={userData?.about_business || ''}
              readOnly
              className="w-full bg-[#EFEDEE] rounded-xl text-[#000000] p-3 focus:outline-none mt-1 min-h-40"
            />
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default ProfilePage;
